import { socioGrpcClient } from "@/setup/socioGrpcClient";
import Mission from "@/models/Mission";
import SubMission from "@/models/SubMission";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";

const state = {};

const getters = {};

const actions = {
  async retrieveMission(_, missionCode) {
    const request =
      new socioGrpcClient.amos_back.projects.MissionRetrieveRequest();
    request.setCode(missionCode);

    const response =
      await socioGrpcClient.amos_back.projects.MissionControllerPromiseClient.retrieve(
        request,
        {}
      );

    const missionData = response.toObject();

    await Mission.insertOrUpdate({
      data: missionData,
    });
    return Mission.find(missionCode);
  },
  async fetchSubMissions(_, metadata = {}) {
    const fetch = async () => {
      const request =
        new socioGrpcClient.amos_back.projects.SubMissionListRequest();

      const response =
        await socioGrpcClient.amos_back.projects.SubMissionControllerPromiseClient.list(
          request,
          {
            filters: JSON.stringify(metadata.filters),
            pagination: JSON.stringify(metadata.pagination),
          }
        );
      return response.toObject().resultsList;
    };

    await useCacheOrNetwork({
      modelClass: SubMission,
      fetchFunc: fetch,
      insertFunc: async (response) => {
        return await SubMission.insertOrUpdate({
          data: response,
        });
      },
      cacheFirst: false,
      skipCache: false,
    });
  },

  async resetState() {
    await Promise.all([Mission.deleteAll(true), SubMission.deleteAll(true)]);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
