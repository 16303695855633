const useAsync = process.env.VUE_APP_RXDB_ENABLE;

/**
 * Represents a class that handles synchronous or asynchronous vuex-orm queries.
 * Async queries are used when we use web-worker in rxdb-worker.js when RxDB is enabled, otherwise, sync queries are used.
 * Vuex-orm async queries are developed in the vuex-orm-rxdb-bridge@1.8.0 (see https://gitlab.socotec.io/socotec.io/vuex-orm-rxdb-bridge/-/blob/master/readme.md?ref_type=heads#async-conditional-query).
 * It's to face the fact that, in some cases, we must wait for web-worker to finish its job before access the result of the vuex-orm query.
 */
export default class SyncOrAsyncQuery {
  result = null;

  /**
   * Creates an instance of SyncOrAsyncQuery.
   * @param {Object} queryBody - The query body object.
   * @param {string} [queryType=""] - The query type.
   * @param {*} [queryPayload=null] - The query payload.
   */
  constructor(queryBody, queryType = "", queryPayload = null) {
    this.queryBody = queryBody;
    this.queryType = queryType;
    this.queryPayload = queryPayload;
  }

  /**
   * Handles the execution of a query asynchronously an return the result.
   * @param {Object} queryBody - The query body.
   * @param {string} [queryType=""] - The query type.
   * @param {any} [queryPayload=null] - The query payload.
   * @returns {Promise<any>} - A promise that resolves to the query result.
   */
  static async handleQuery(queryBody, queryType = "", queryPayload = null) {
    const instance = new SyncOrAsyncQuery(queryBody, queryType, queryPayload);
    await instance.executeQuery();
    return instance.result;
  }

  /**
   * Executes the async query (RxDB enabled) or the sync query (RxDB disabled).
   */
  async executeQuery() {
    if (useAsync === "true") {
      this.result = await this.executeAsyncQuery();
    } else {
      this.result = this.executeSyncQuery();
    }
  }

  /**
   * Executes the query asynchronously.
   * @returns {Promise<*>} The result of the query.
   */
  async executeAsyncQuery() {
    return this.queryBody[`a${this.queryType}`](this.queryPayload);
  }

  /**
   * Executes the query synchronously.
   * @returns {*} The result of the query.
   */
  executeSyncQuery() {
    return this.queryBody[this.queryType](this.queryPayload);
  }
}
