import {
  ObjectTypeStatement,
  ActionType,
  AnalyticalAxis,
  DispositionType,
  TypologyType,
} from "@/models/";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { amosAdminModels, AMOS_ADMIN_ITEMS } from "@/utils/amosAdmin";
import {
  capitalize,
  camelToSnakeCase,
  formatFilters,
  getFieldValueFromFieldsMap,
} from "@/utils/helpers";
import requestFactory from "../factory";
import {
  consoleLogDebug,
  useCacheOrNetwork,
} from "@socotec.io/vuex-orm-rxdb-bridge";
import SyncOrAsyncQuery from "@/utils/vuexOrmAsyncQuery";
import dayjs from "dayjs";
import { downloadBase64Document } from "@/utils/document";

const {
  amos_back: { amos_admin: amosAdminApi },
} = socioGrpcClient;

const state = {
  costScaleCount: 0,
  costScaleGroupCount: 0,
  differabilityCount: 0,
  differabilityGroupCount: 0,
  priorityCount: 0,
  priorityGroupCount: 0,
  projectionCount: 0,
  projectionGroupCount: 0,
  riskAssessmentCount: 0,
  ROICount: 0,
  ROIGroupCount: 0,
  sourcePricingCount: 0,
  sourcePricingGroupCount: 0,
  ventilationCount: 0,
  ventilationGroupCount: 0,
  actionStatusCount: 0,
  actionTypeCount: 0,
  analyticalAxisCount: 0,
  quotationCount: 0,
  quotationAnalyticalAxisCount: 0,
  quotationGroupCount: 0,
  quotationGroupTypeCount: 0,
  quotationValueCount: 0,
  statementTypeCount: 0,
  glossaryCount: 0,
  surveyRuleCount: 0,
  reportTextCount: 0,
  riskAssessmentGroupCount: 0,
  reportTemplateTypeCount: 0,
  objectTypeStatementCount: 0,
  functionalRequirementCount: 0,
};

const getters = {
  getActionTypes: () => ActionType.query().orderBy("uniqueCode").all(),

  getTableItems: () => (adminTableName, sortFunction) =>
    requestFactory.getters.paginateGetter(
      AMOS_ADMIN_ITEMS[adminTableName].modelClass
        .query()
        .withAll()
        .orderBy("ordering"),
      sortFunction
    ),

  getFilteredTableItems: () => (adminTableName, sortFunction) => () =>
    AMOS_ADMIN_ITEMS[adminTableName].modelClass
      .query()
      .withAll()
      .where("displayed", true)
      .orderBy("ordering")
      .get()
      .sort(sortFunction),

  countTableItems: () => (adminTableName) => {
    return state[`${adminTableName}Count`];
  },

  getOrderedAnalyticalAxes: () =>
    AnalyticalAxis.query().orderBy("ordering").all(),
};

const actions = {
  async fetchAmosAdmin({ dispatch }) {
    // Fetch all amos-back referencials node data for all versions
    // from RxDB cache or from API
    await Promise.all([
      dispatch("fetchAll", { modelClass: DispositionType }),
      dispatch("fetchAll", { modelClass: TypologyType }),
    ]);

    // Fetch all the amos admin models, from RxDB cache or from API
    await Promise.allSettled(
      amosAdminModels().map((modelClass) => {
        dispatch("fetchAll", { modelClass });
      })
    );

    consoleLogDebug("Models count", amosAdminModels().length);
  },

  async fetchAdminTableData(
    { commit },
    { adminTableName, page = 1, pageSize = 1000, filters = {} }
  ) {
    const metadata = {
      filters: formatFilters({
        all_objects: true,
        ...filters,
      }),
      pagination: JSON.stringify({
        page: page,
        page_size: pageSize,
      }),
    };
    const request = new amosAdminApi[
      `${capitalize(adminTableName)}ListRequest`
    ]();

    const response = await amosAdminApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].list(request, metadata);

    const { resultsList, count } = response.toObject();
    commit("SET_ADMIN_TABLE_COUNT", {
      count: count,
      adminTableName: adminTableName,
    });
    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.insertOrUpdate({
      data: resultsList.map((data) => {
        return { ...data, displayed: true };
      }),
    });
  },

  async fetchAll(
    _,
    { modelClass, filters = {}, cacheFirst = true, skipCache = false }
  ) {
    // Fetch the data from the RxDB cache, and if there is no data, fetch them
    // from the API and store them in cache
    const metadata = {
      filters: formatFilters({
        ...filters,
      }),
    };
    const className = modelClass.name;
    const request = new amosAdminApi[`${className}FetchAllRequest`]();

    const data = await useCacheOrNetwork({
      modelClass: modelClass,
      fetchFunc: async () => {
        const response = await amosAdminApi[
          `${className}ControllerPromiseClient`
        ].fetchAll(request, metadata);
        return response.toObject().resultsList;
      },
      insertFunc: async (data) => {
        return await modelClass.insertOrUpdate({
          data: data.map((data) => {
            return { ...data, displayed: true };
          }),
        });
      },
      cacheFirst,
      skipCache,
    });
    consoleLogDebug("Insertion in VuexORM", modelClass.name, {
      ...data,
      displayed: true,
    });
    consoleLogDebug("Fetch all items", modelClass.query().withAll().get());
  },

  async createAdminTableObject({ commit, getters }, { adminTableName, data }) {
    const excludedFields = ["$id", "createdAt", "updatedAt"];

    const request = socioGrpcClient.javascriptToRequest(
      amosAdminApi[`${capitalize(adminTableName)}Request`],
      { ...data },
      excludedFields
    );
    const metadata = {
      filters: JSON.stringify({
        all_objects: true,
      }),
    };
    const response = await amosAdminApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].create(request, metadata);

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.insert({
      data: response.toObject(),
    });

    commit("SET_ADMIN_TABLE_COUNT", {
      count: getters.countTableItems(adminTableName) + 1,
      adminTableName: adminTableName,
    });
  },

  async updateAdminTableObject(_, { adminTableName, data }) {
    const excludedFields = [
      "$id",
      "createdAt",
      "updatedAt",
      "statementTypeData",
      "quotationValueData",
      "analyticalAxes",
      "analyticalAxisData",
      "displayed",
    ];
    const request = socioGrpcClient.javascriptToRequest(
      amosAdminApi[`${capitalize(adminTableName)}Request`],
      { ...data },
      excludedFields
    );

    const metadata = {
      filters: JSON.stringify({
        all_objects: true,
      }),
    };

    const response = await amosAdminApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].update(request, metadata);

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.update({
      where: data.uuid,
      data: { ...response.toObject() },
    });
  },

  async partialUpdateAdminTableObject(_, { adminTableName, data }) {
    const excludedFields = ["$id", "createdAt", "updatedAt"];
    const metadata = {
      filters: JSON.stringify({
        all_objects: true,
      }),
    };
    const request = socioGrpcClient.javascriptToRequest(
      amosAdminApi[`${capitalize(adminTableName)}PartialUpdateRequest`],
      { ...data },
      excludedFields
    );

    request.setPartialUpdateFieldsList(
      Object.keys(data).map((key) => {
        const toSnake = camelToSnakeCase(key);
        return toSnake.endsWith("_list")
          ? toSnake.replace("_list", "")
          : toSnake;
      })
    );

    const response = await amosAdminApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].partialUpdate(request, metadata);

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.update({
      where: data.uuid,
      data: { ...response.toObject() },
    });
  },

  async retrieveAdminTableObject(_, { adminTableName, uuid }) {
    const request = new amosAdminApi[
      `${capitalize(adminTableName)}RetrieveRequest`
    ]();
    request.setUuid(uuid);

    const response = await amosAdminApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].retrieve(request, {});

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.insertOrUpdate({
      data: response.toObject(),
    });
    const result = await SyncOrAsyncQuery.handleQuery(
      AMOS_ADMIN_ITEMS[adminTableName].modelClass
        .query()
        .where("uuid", uuid)
        .withAll(),
      "first"
    );
    return result;
  },

  async fetchObjectTypeStatements(_, { metadata: { filters = {} } }) {
    const metadata = {
      filters,
      pagination: JSON.stringify({
        page_size: 500,
      }),
    };
    const request = new amosAdminApi.ObjectTypeStatementListRequest();
    const response =
      await amosAdminApi.ObjectTypeStatementControllerPromiseClient.list(
        request,
        metadata
      );
    const { resultsList } = response.toObject();
    return ObjectTypeStatement.query()
      .withAllRecursive(2)
      .whereIdIn(resultsList.map((o) => o.uuid))
      .get();
  },

  async fetchAnalyticalAxes(_, filters = {}) {
    const metadata = { filters: JSON.stringify(filters) };

    const request = new amosAdminApi.AnalyticalAxisListRequest();
    const response =
      await amosAdminApi.AnalyticalAxisControllerPromiseClient.list(
        request,
        metadata
      );

    await AnalyticalAxis.insertOrUpdate({
      data: response.toObject().resultsList,
    });
  },

  async fetchAllDispositionTypes(_, filters = {}) {
    const metadata = {
      filters: JSON.stringify(filters),
      pagination: JSON.stringify({
        page_size: 1000,
      }),
    };

    const result = await useCacheOrNetwork({
      modelClass: DispositionType,
      fetchFunc: async () => {
        const request = new amosAdminApi.DispositionTypeListRequest();
        return await amosAdminApi.DispositionTypeControllerPromiseClient.utils.listAllObjects(
          request,
          metadata
        );
      },
      insertFunc: async (response) => {
        return await DispositionType.insertOrUpdate({
          data: response,
        });
      },
      cacheFirst: false,
      skipCache: true,
    });
    return result;
  },

  async fetchAllTypologyTypes(_, filters = {}) {
    const metadata = {
      filters: JSON.stringify(filters),
      pagination: JSON.stringify({
        page_size: 1000,
      }),
    };
    const result = await useCacheOrNetwork({
      modelClass: TypologyType,
      fetchFunc: async () => {
        const request = new amosAdminApi.TypologyTypeListRequest();
        return await amosAdminApi.TypologyTypeControllerPromiseClient.utils.listAllObjects(
          request,
          metadata
        );
      },
      insertFunc: async (response) => {
        return await TypologyType.insertOrUpdate({
          data: response,
        });
      },
      cacheFirst: false,
      skipCache: true,
    });
    return result;
  },

  async retrieveActionTypesByAnalyticalAxis(_, analyticalAxisUuid) {
    const request =
      new amosAdminApi.ObjectTypeStatementRetrieveActionTypesByAnalyticalAxisRequest();
    request.setAnalyticalAxis(analyticalAxisUuid);

    const response =
      await amosAdminApi.ObjectTypeStatementControllerPromiseClient.retrieveActionTypesByAnalyticalAxis(
        request,
        {}
      );

    const { actionTypesList } = response.toObject();

    return actionTypesList;
  },

  exportAdminTable: ({ dispatch }, adminTableName) =>
    requestFactory.genericActions.streamedTaskFactory({
      client:
        amosAdminApi[`${capitalize(adminTableName)}ControllerPromiseClient`],
      grpcRequest: amosAdminApi.ExportRequest,
      requestMethod: "export",
      requestParameters: {
        fileName: "",
      },
      modelName: adminTableName,
      onSuccess: {
        message: "export.success",
        action: {
          label: "download",
          handler: (data) => {
            const content = getFieldValueFromFieldsMap(
              data.result.fieldsMap,
              "content",
              "stringValue"
            );
            downloadBase64Document(
              content,
              `quotations_export_${dayjs().format(
                "DD_MM_YYYY_HH_mm_ss"
              )}.csv`.replaceAll(" ", "_")
            );
          },
        },
      },
      onFailure: {
        message: "export.error",
      },
      onPending: {
        message: "export.pending",
      },
      onProgress: {
        message: "export.inProgress",
      },
    })({ dispatch }),
};

const mutations = {
  SET_ADMIN_TABLE_COUNT: (state, { count, adminTableName }) =>
    (state[`${adminTableName}Count`] = count),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
