import { DocumentReview, TypologyType, TypologyTypeNode } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import referencial from "./referencial";
import { GRPC_REFERENCIAL_PARAMS } from "@/utils/referencial";
import { utils } from "@socotec.io/socio-vue-components";
import { maxBy } from "lodash";
import {
  filterCurrentTypologyNodes,
  filterDraftTypologyNodes,
} from "@/utils/referencialFilter";
import { setRequestFilters } from "@/utils/request";
import requestFactory from "@/store/factory";
import SyncOrAsyncQuery from "@/utils/vuexOrmAsyncQuery";

const { sortNodesByPath } = utils.referencialUtils;

const {
  amos_back: { document_review: documentReviewApi },
} = socioGrpcClient;

const state = {
  // referencial Nodes data state
  currentTypologyTypeNodes: [],
  draftTypologyTypeNodes: [],

  // root path data
  docReviewReferencialRootPath: null,
  draftRootNodePath: null,

  // utils
  treeDepthAsArray: [],
};

const getters = {
  getCurrentTypologyTypeNodes: (state) => state.currentTypologyTypeNodes,
  getOrderedDraftNodes: (state) => state.draftTypologyTypeNodes,
  getDraftRootNodePath: (state) => state.draftRootNodePath,
  getCurrentRootPath: (state) => state.docReviewReferencialRootPath,
};

const actions = {
  async fetchSmallDocreviewInfo(
    _,
    { filters = {}, handleResponse = (res) => res }
  ) {
    const request = setRequestFilters({
      request: new documentReviewApi.DocumentReviewListRequest(),
      filters,
    });
    const response =
      await documentReviewApi.DocumentReviewControllerPromiseClient.utils.listAllObjects(
        request,
        {},
        "smallList"
      );
    await handleResponse(response);
  },

  async fetchDocReviewReferencial(
    { rootState, commit },
    {
      filters = {},
      extraParams = {},
      requestParams = GRPC_REFERENCIAL_PARAMS.AMOS_DOC_REVIEW_CURRENT_PARAMS,
    }
  ) {
    const cacheValidationStrategy = (results) => !!results.length;
    // INFO - MS - async query to await TypologyType insertion finished
    await SyncOrAsyncQuery.handleQuery(TypologyType.query(), "all");
    await referencial.actions.referencialFactory({
      requestParams,
      ModelClass: TypologyTypeNode,
      handleResponse: (commit, nodes) => {
        commit("SET_DOC_REVIEW_ROOT", nodes[0].rootNodePath);
      },
      cacheValidationStrategy,
    })({ commit }, { filters, extraParams, requestParams });

    commit("SET_CURRENT_TYPOLOGY_NODES", rootState);
  },

  async fetchDraftNodes({ commit, rootState }, { filters }) {
    const fetchDraftNodes = referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DOC_REVIEW_DRAFT_PARAMS,
      ModelClass: TypologyTypeNode,
      handleResponse: (commit, nodes) => {
        commit("SET_DOC_REVIEW_DRAFT_ROOT_NODE_PATH", nodes[0].rootNodePath);
        nodes.sort(sortNodesByPath);
      },
      grpcAction: "flatListFullData",
      skipCache: true,
    })({ commit }, { filters });
    await fetchDraftNodes;

    commit("SET_DRAFT_TYPOLOGY_NODES", rootState);
  },

  simpleFetchDraftNodes: referencial.actions.referencialFactory({
    requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DOC_REVIEW_DRAFT_PARAMS,
    ModelClass: TypologyTypeNode,
    skipCache: true,
  }),

  async resetState({ commit }) {
    state.currentTypologyTypeNodes = [];
    commit("SET_DOC_REVIEW_ROOT", null);
    TypologyTypeNode.clearAggregateState();
    DocumentReview.deleteAll();
  },

  async destroyDocumentReview(_, documentReview) {
    const request = new documentReviewApi.DocumentReviewDestroyRequest();
    request.setUuid(documentReview.uuid);
    await documentReviewApi.DocumentReviewControllerPromiseClient.destroy(
      request,
      {}
    );
  },

  exportDocumentReviews: requestFactory.actions.exportFactory(
    documentReviewApi.DocumentReviewControllerPromiseClient,
    documentReviewApi.ExportRequest,
    "documentReview"
  ),
};

const mutations = {
  SET_CURRENT_TYPOLOGY_NODES: (state, rootState) => {
    state.currentTypologyTypeNodes = filterCurrentTypologyNodes({ rootState });
    if (!state.currentTypologyTypeNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }
    // assumes typologyTypeNodes are already sorted
    const deepestNode = maxBy(state.currentTypologyTypeNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DRAFT_TYPOLOGY_NODES: (state, rootState) => {
    state.draftTypologyTypeNodes = filterDraftTypologyNodes({ rootState });
    if (!state.draftTypologyTypeNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }
    // assumes draftTypologyTypeNodes are already sorted
    const deepestNode = maxBy(state.draftTypologyTypeNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DOC_REVIEW_ROOT: (state, rootPath) =>
    (state.docReviewReferencialRootPath = rootPath),

  SET_DOC_REVIEW_DRAFT_ROOT_NODE_PATH: (state, rootPath) =>
    (state.draftRootNodePath = rootPath),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
