import SubcomponentAsset from "@/models/SubcomponentAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import requestFactory from "../factory";

const getters = {
  projectAssetsCount: () => {
    return SubcomponentAsset.query().where("isProjectAsset", true).get().length;
  },
  getAosSubcomponentsFromProjectAssets: () =>
    requestFactory.getters.paginateGetter(
      AosVuexOrmModel["AosSubcomponent"].query().whereIdIn(
        SubcomponentAsset.query()
          .where("isProjectAsset", true)
          .get()
          .map((asset) => asset.aosItem)
      )
    ),
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
};
