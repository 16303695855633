import { ReportTemplate } from "@socotec.io/socio-vue-components";
import { Report } from "@/models";
import { setRequestFilters } from "@/utils/request";

import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { injectPerimeterRepresentation } from "@/utils/perimeterRepr";

const state = {
  reportsCount: 0,
  formsByPerimeter: {},
};

const getters = {
  getReports() {
    return Report.all();
  },
  getReportsByTemplateId: () => (template_id) => {
    return Report.query().where("template", template_id).all();
  },
  getReportsCount: (state) => {
    return state.reportsCount;
  },
  getTemplateByName: () => (name) => {
    return ReportTemplate.query().where("name", name).first();
  },
  getTemplateByCode: () => (code) => {
    return ReportTemplate.query().where("code", code).first();
  },
  getTemplateByUuid: () => (uuid) => {
    return ReportTemplate.query().where("uuid", uuid).first();
  },
  getTemplates() {
    return ReportTemplate.all();
  },
  sortedTemplates() {
    return ReportTemplate.all().sort(
      (a, b) => a.orderingPosition - b.orderingPosition
    );
  },
};

const actions = {
  async fetchReportList(
    { commit, dispatch },
    {
      filters = {},
      pagination = {},
      onlyReturn = false,
      relatedItemIds = [],
      elementType = null,
    }
  ) {
    let response;
    if (relatedItemIds.length > 0) {
      // Apply filter only if relatedItemIds otherwise it returns all reports
      const relationsReportIds = await dispatch(
        "relations/findEntitiesByTargetIds",
        {
          appServiceId: "report_generator",
          entityName: "report",
          targetIds: relatedItemIds,
          targetEntityName: elementType,
          targetService: "aos",
        },
        { root: true }
      );

      if (relationsReportIds.length === 0) {
        commit("UPDATE_REPORTS_COUNT", 0);
        return onlyReturn ? {} : [];
      }

      const _filters = { uuid__in: relationsReportIds };
      response = await dispatch("listReports", {
        pagination,
        filters: { ...filters, ..._filters },
      });
    } else {
      response = await dispatch("listReports", { filters, pagination });
    }

    if (onlyReturn) {
      return response.toObject();
    }

    commit("UPDATE_REPORTS_COUNT", response.getCount());
    const responseObject = response.getResultsList();
    const reportsList = responseObject.map((element) => element.toObject());
    await Report.insert({
      data: reportsList,
    });
    const reportIds = reportsList.map((d) => d.uuid);
    await dispatch(
      "relations/fetchEntityRelations",
      {
        filters: {
          app_service_id: "report_generator",
          app_entity_name: "report",
          app_entity_id__in: reportIds.join(","),
        },
        populateORM: true,
      },
      { root: true }
    );
    const reports = Report.query().with("relations").findIn(reportIds);
    await injectPerimeterRepresentation(reports, Report);
    return reports;
  },

  async listReports(_, { filters = {}, pagination = {} }) {
    const metadata = {
      pagination: JSON.stringify(pagination),
    };

    const request = setRequestFilters({
      request: new socioGrpcClient.report_generator.reports.ReportListRequest(),
      filters,
    });

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        metadata
      );
    return response;
  },

  async simpleListReports(_, { filters = {}, pagination = {} }) {
    const metadata = {
      filters: JSON.stringify(filters),
      pagination: JSON.stringify(pagination),
    };
    const request =
      new socioGrpcClient.report_generator.reports.ReportListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        metadata
      );

    return response.toObject().resultsList;
  },

  async fetchReport({ dispatch }, { reportUuid }) {
    const request = new socioGrpcClient.report_generator.reports.Report();

    request.setUuid(reportUuid);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.retrieve(
        request,
        {}
      );
    const reportData = response.toObject();

    reportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    await Report.insert({
      data: reportData,
    });
    await dispatch(
      "relations/fetchEntityRelations",
      {
        filters: {
          app_service_id: "report_generator",
          app_entity_name: "report",
          app_entity_id__in: `${reportUuid}`,
        },
        populateORM: true,
      },
      { root: true }
    );
    const report = Report.query().with("relations").find(reportUuid);
    await injectPerimeterRepresentation([report], Report);
  },

  async fetchReportTemplateList() {
    const metadata = {
      filters: JSON.stringify({
        service_id: process.env.VUE_APP_AMOS_SERVICE_ID,
      }),
    };
    const request =
      new socioGrpcClient.report_generator.reports.ReportTemplateListRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportTemplateControllerPromiseClient.list(
        request,
        metadata
      );

    return await ReportTemplate.insert({
      data: response.toObject().resultsList,
    });
  },

  async updateReportStatus(_, { report, status }) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportUpdateStatusRequest();
    request.setUuid(report.uuid);
    request.setStatus(status);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.updateStatus(
        request,
        {}
      );

    const result = response.toObject();

    await Report.update({
      where: report.uuid,
      data: result.data,
    });
  },

  async deleteReport(_, report) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportDestroyRequest();
    request.setUuid(report.uuid);

    await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.destroy(
      request,
      {}
    );
    await Report.delete(report.uuid);
  },

  async checkExists(_, { filters }) {
    const metadata = {
      filters: filters,
    };
    const request =
      new socioGrpcClient.report_generator.reports.ReportCheckExistsRequest();
    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.checkExists(
        request,
        metadata
      );
    return response.toObject();
  },

  updateFormByPerimeter({ commit }, { perimeterAsKey, form }) {
    commit("UPDATE_FORM_BY_PERIMETER", { perimeterAsKey, form });
  },
};

const mutations = {
  UPDATE_REPORTS_COUNT: function (state, newTotal) {
    state.reportsCount = newTotal;
  },
  UPDATE_FORM_BY_PERIMETER: function (state, data) {
    state.formsByPerimeter[data.perimeterAsKey] = data.form;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
