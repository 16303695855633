import SpaceAsset from "@/models/SpaceAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";
import requestFactory from "../factory";

const client = socioGrpcClient.amos_back.aos.SpaceControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;

const getters = {
  projectAssetsCount: () => {
    return SpaceAsset.query().where("isProjectAsset", true).get().length;
  },
  getAosSpacesFromProjectAssets: () =>
    requestFactory.getters.paginateGetter(
      AosVuexOrmModel["AosSpace"].query().whereIdIn(
        SpaceAsset.query()
          .where("isProjectAsset", true)
          .get()
          .map((asset) => asset.aosItem)
      )
    ),
};

const actions = {
  fetchSpaces: async (
    _,
    { metadata = {}, listAll = false, cacheFirst = false, skipCache = false }
  ) => {
    const request = new socioGrpcClient.amos_back.aos.SpaceListRequest();

    const data = await useCacheOrNetwork({
      modelClass: SpaceAsset,
      fetchFunc: async () => {
        const response = listAll
          ? await client.utils.listAllObjects(request, metadata)
          : await client.list(request, metadata);
        return listAll ? response : response.toObject().resultsList;
      },
      insertFunc: async (data) => {
        return await SpaceAsset.insert({ data });
      },
      cacheFirst,
      skipCache,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.SpaceRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    SpaceAsset,
    socioGrpcClient.amos_back.aos.SpaceCharacteristicsPartialUpdateRequest,
    client,
    ["code"]
  ),
  updateCode: async (_, { uuid, code }) => {
    const request = new socioGrpcClient.amos_back.aos.SpaceUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    SpaceAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "SpaceAsset"
  ),
  move: requestFactory.actions.moveFactory(
    client,
    socioGrpcClient.amos_back.aos.MoveRequest,
    "SpaceAsset"
  ),
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
};
