import { Action, ObservationStatement, Observation } from "@/models";
import { values } from "lodash";

export const getters = {
  draftContainsWIP: (state) => {
    const statementsContainWIP = values(state.statements).some((s) => {
      if (!state._cached_statements) return false;
      if (!(s.uuid in state._cached_statements)) return false;
      return ObservationStatement.hasDiffs(s, state._cached_statements[s.uuid]);
    });

    return (
      Observation.query().where("isDraft", true).exists() ||
      ObservationStatement.query().where("isDraft", true).exists() ||
      Action.query().where("isDraft", true).exists() ||
      Observation.hasDiffs(state.observation, state._cached_observation) ||
      statementsContainWIP
    );
  },

  isInCreateMode: (state) => state.observation?.isDraft,

  containsAtLeastOneStatement: (state) => {
    if (!state.observation) return false;
    const containsExistingStatements = ObservationStatement.query()
      .where((os) =>
        [state.observation.uuid, state.draftCtObservationUuid].includes(
          os.observation
        )
      )
      .exists();
    return containsExistingStatements;
  },

  isRiskValid: (state, getters, rootState, rootGetters) => {
    if (!rootGetters["projectConfig/getCurrentProjectConfig"].hasRisk) {
      return true;
    }
    return state.observation.riskAssessment;
  },

  isDraftValid: (state, getters) => {
    if (!getters.containsAtLeastOneStatement) return false;

    return getters.isRiskValid && getters.draftContainsWIP;
  },
};
