import store from "@/store";
import { computed } from "vue";

const user = computed(() => store.getters["user/getCurrentUser"]);

const buildPerm = (permission) =>
  computed(
    () =>
      user.value?.isPrivileged() || (permission && permission.call(user.value))
  );

export const amosRootPermissions = {
  steering: {
    read: () => buildPerm(user.value?.isAllowedRead).value,
  },
  management: {
    read: () => user.value?.isPrivileged(),
  },
  cases: {
    read: () => buildPerm(user.value?.isAllowedRead).value,
  },
  visits: {
    read: () => buildPerm(user.value?.isAllowedRead).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  admin: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
  },
  intranet: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
  },
  support: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
  },
  profile: {
    read: () => buildPerm(user.value?.isAllowedRead).value,
  },
  referencials: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
  },
  "project-contributors": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  observations: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  diagnostic: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  ged: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  dispositions: {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "document-review": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "aos-item-selection": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-information": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-photos": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-characteristic": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-actions": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-observations": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-ged": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-disposition": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-document-review": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "identification-viewer": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
  "report-generation": {
    read: () => buildPerm(user.value?.isAtLeastContributor).value,
    edit: () =>
      user.value?.isPrivileged() || !buildPerm(user.value?.isCT).value,
  },
};
