import { Disposition, DispositionNode, DispositionType } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import referencial from "./referencial";
import { GRPC_REFERENCIAL_PARAMS } from "@/utils/referencial";
import { utils } from "@socotec.io/socio-vue-components";
import { forIn, maxBy } from "lodash";
import {
  filterCurrentDispositionNodes,
  filterDraftDispositionNodes,
} from "@/utils/referencialFilter";
import requestFactory from "@/store/factory";
import SyncOrAsyncQuery from "@/utils/vuexOrmAsyncQuery";
import { ANALYTICAL_AXES_TO_DISPOSITION_PATHES } from "@/utils/const";

const { sortNodesByPath } = utils.referencialUtils;

const {
  amos_back: { disposition: dispositionApi },
} = socioGrpcClient;

const state = {
  // referencial data state
  currentDispositionNodes: [],
  draftDispositionNodes: [],
  missionDispositionNodes: [],

  // root path data state
  dispositionReferencialRootPath: null,
  draftRootNodePath: null,

  // helpers and others
  treeDepthAsArray: [],
};

const getters = {
  getCurrentDispositionNodes: (state) => state.currentDispositionNodes,
  getMissionDispositionNodes: (state) => state.missionDispositionNodes,
  getOrderedDraftNodes: (state) => state.draftDispositionNodes,
  getDraftRootNodePath: (state) => state.draftRootNodePath,
  getCurrentRootPath: (state) => state.dispositionReferencialRootPath,
};

const actions = {
  async fetchDispositionReferencial(
    { rootState, rootGetters, commit },
    {
      filters = {},
      extraParams = {},
      requestParams = GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_CURRENT_PARAMS,
      cacheValidationStrategy = () => false,
    }
  ) {
    // INFO - MS - async query to await DispotionType insertion finished
    await SyncOrAsyncQuery.handleQuery(DispositionType.query(), "all");
    await referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_CURRENT_PARAMS,
      ModelClass: DispositionNode,
      handleResponse: (commit, nodes) => {
        commit("SET_DISPOSITION_ROOT", nodes[0].rootNodePath);
      },
      cacheValidationStrategy,
    })({ commit }, { filters, extraParams, requestParams });
    commit("SET_CURRENT_DISPOSITION_NODES", rootState);
    commit("SET_MISSION_DISPOSITION_NODES", rootGetters);
  },

  async fetchDraftNodes({ commit, rootState }, { filters }) {
    const fetchDraftNodes = referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_DRAFT_PARAMS,
      ModelClass: DispositionNode,
      handleResponse: (commit, nodes) => {
        commit("SET_DISPOSITION_DRAFT_ROOT_NODE_PATH", nodes[0].rootNodePath);
        nodes.sort(sortNodesByPath);
      },
      grpcAction: "flatListFullData",
      skipCache: true,
    })({ commit }, { filters });
    await fetchDraftNodes;
    commit("SET_DRAFT_DISPOSITION_NODES", rootState);
  },

  simpleFetchDraftNodes: referencial.actions.referencialFactory({
    requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_DRAFT_PARAMS,
    ModelClass: DispositionNode,
    skipCache: true,
  }),

  async resetState({ commit }) {
    commit("RESET_MISSION_DISPOSITION_NODE");
    commit("SET_DISPOSITION_ROOT", null);
    DispositionNode.clearAggregateState();
    Disposition.deleteAll();
  },

  async destroyDisposition(_, disposition) {
    const request = new dispositionApi.DispositionDestroyRequest();
    request.setUuid(disposition.uuid);
    await dispositionApi.DispositionControllerPromiseClient.destroy(
      request,
      {}
    );
  },

  exportDispositions: requestFactory.actions.exportFactory(
    dispositionApi.DispositionControllerPromiseClient,
    dispositionApi.ExportRequest,
    "disposition"
  ),
};

const mutations = {
  SET_DISPOSITION_ROOT: (state, rootPath) => {
    state.dispositionReferencialRootPath = rootPath;
  },

  SET_CURRENT_DISPOSITION_NODES: (state, rootState) => {
    state.currentDispositionNodes = filterCurrentDispositionNodes({
      rootState,
    });
    if (!state.currentDispositionNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }
    // assumes currentDispositionNodes are already sorted
    const deepestNode = maxBy(state.currentDispositionNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_MISSION_DISPOSITION_NODES: (state, rootGetters) => {
    const projectAxesCodes = rootGetters["project/getProjectAxesCodes"];
    const axesCodesToDispositionPath = [];
    forIn(ANALYTICAL_AXES_TO_DISPOSITION_PATHES, (path, axisCode) => {
      if (projectAxesCodes.includes(axisCode)) {
        axesCodesToDispositionPath.push(path);
      }
    });

    state.missionDispositionNodes = state.currentDispositionNodes.filter(
      (node) => {
        const [basePath] = node.path.split(".");
        return axesCodesToDispositionPath.includes(basePath);
      }
    );

    if (!state.missionDispositionNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }
    // assumes currentDispositionNodes are already sorted
    const deepestNode = maxBy(state.missionDispositionNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DRAFT_DISPOSITION_NODES: (state, rootState) => {
    state.draftDispositionNodes = filterDraftDispositionNodes({ rootState });
    if (!state.draftDispositionNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }
    // assumes currentDispositionNodes are already sorted
    const deepestNode = maxBy(state.draftDispositionNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DISPOSITION_DRAFT_ROOT_NODE_PATH: (state, rootPath) =>
    (state.draftRootNodePath = rootPath),

  RESET_MISSION_DISPOSITION_NODE: (state) => {
    state.missionDispositionNodes = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
