import { ReferencialCtNode, CtNodeObservation } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import referencial from "./referencial";

import { GRPC_REFERENCIAL_PARAMS } from "@/utils/referencial";

import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import BuildingAsset from "@/models/BuildingAsset";
import { maxBy, values } from "lodash";
import { RapsotecNodeAmos } from "@/models/RapsotecNode";
import { filterCtNodes, transformResponse } from "@/utils/referencialFilter";

const {
  amos_back: { diag: diagApi },
} = socioGrpcClient;

const state = {
  // referncial nodes data state
  missionCtNodes: [],
  allCtNodes: [],

  // root path data
  ctReferencialRootPath: null,

  // filters and utils
  treeDepthAsArray: [],
  dataForFilters: {
    currentProject: {},
    currentProjectConfig: {},
    buildings: [],
    buildingAssets: [],
  },
  dataForFiltersFetched: false,
};

const getters = {
  getCurrentRootPath: (state) => state.ctReferencialRootPath,
  getOrderedDraftNodes: (state) => state.allCtNodes,
};

const actions = {
  async fetchRapsotecCtReferencial(
    { state, commit, rootState },
    {
      filters = {},
      extraParams = {},
      requestParams = GRPC_REFERENCIAL_PARAMS.RAPSOTEC_CT_CURRENT_PARAMS,
      cacheValidationStrategy = () => false,
    }
  ) {
    if (state.ctReferencialRootPath) {
      commit("SET_FILTERED_CT_REFERNCIAL_NODES", rootState);
      return;
    }

    const fetchNodes = referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.RAPSOTEC_CT_CURRENT_PARAMS,
      ModelClass: ReferencialCtNode,
      grpcAction: "smallFlatList",
      transformResponse: (res) => transformResponse(res),
      handleResponse: (commit, res) => {
        commit("SET_RAPSO_CT_ROOT", res[0].rootNodePath);
      },
      cacheValidationStrategy,
    })({ commit }, { filters, extraParams, requestParams });

    await fetchNodes;
    commit("SET_FILTERED_CT_REFERNCIAL_NODES", rootState);
  },

  async fetchRapsotecMissionNodes(
    { commit, rootState },
    {
      filters = {},
      extraParams = {},
      requestParams = GRPC_REFERENCIAL_PARAMS.RAPSOTEC_CT_CURRENT_PARAMS,
    }
  ) {
    const cacheValidationStrategy = (results) => {
      const currentCtNodes = rootState.entities.referencialCtNodes.data;
      if (!Object.keys(currentCtNodes).length || !results.length) return false;

      const rapsoNodeIds = results.map((item) => item.uuid);
      return Object.values(currentCtNodes).some((node) => {
        return rapsoNodeIds.includes(node.objectId);
      });
    };

    const fetchRapsotecNodes = referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.RAPSOTEC_CT_CURRENT_PARAMS,
      ModelClass: RapsotecNodeAmos,
      grpcAction: "smallFlatListRapsotecNodes",
      cacheValidationStrategy,
    })({ commit }, { filters, extraParams, requestParams });
    await fetchRapsotecNodes;
  },

  // Not really draft, but fetch both chapter 20 and 33 for referencial page vs only one path inside a visit
  async fetchDraftNodes({ rootState, commit }, { filters }) {
    const fetchDraftNodes = referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.RAPSOTEC_CT_CURRENT_PARAMS,
      ModelClass: ReferencialCtNode,
      grpcAction: "smallFlatList",
      handleResponse: (commit, res) => {
        commit("SET_RAPSO_CT_ROOT", res[0].rootNodePath);
      },
      skipCache: true,
    })({ commit }, { filters });
    await fetchDraftNodes;
    commit("SET_ALL_REFRENCIAL_CT_NODES", rootState);
  },

  async resetState({ commit, state }) {
    state.missionCtNodes = [];
    state.allCtNodes = [];
    commit("SET_RAPSO_CT_ROOT", null);
    ReferencialCtNode.clearAggregateState();
    CtNodeObservation.deleteAll();
  },

  async destroyCtNodeObservation(_, ctNodeObservation) {
    const request = new diagApi.CtNodeObservationDestroyRequest();
    request.setUuid(ctNodeObservation.uuid);
    await diagApi.CtNodeObservationControllerPromiseClient.destroy(request, {});
  },

  async prefetchDataForFilters({ dispatch, commit, rootGetters }) {
    const projectBuildingAssets = BuildingAsset.query().all();
    let buildingAssetsIds = projectBuildingAssets.map((b) => b.uuid);
    let buildingAosIds = projectBuildingAssets.map((b) => b.aosItem);
    const buildingAssetsCharacteristics = buildingAssetsIds.map((uuid) =>
      dispatch("building/retrieveCharacteristics", uuid, { root: true })
    );
    await Promise.all(buildingAssetsCharacteristics);

    const buildingAssets = BuildingAsset.query()
      .with("characteristics")
      .findIn(buildingAssetsIds);

    const buildings = AosVuexOrmModel.AosBuilding.query()
      .with("locations")
      .findIn(buildingAosIds);

    const currentProject = rootGetters["project/getCurrentProject"];
    const currentProjectConfig =
      rootGetters["projectConfig/getCurrentProjectConfig"];

    commit("SET_DATA_FOR_FILTERS", {
      buildings: buildings,
      currentProject,
      currentProjectConfig,
      buildingAssets,
    });
  },
};

const mutations = {
  SET_RAPSO_CT_ROOT: (state, path) => (state.ctReferencialRootPath = path),
  SET_FILTERED_CT_REFERNCIAL_NODES: (state, rootState) => {
    setTimeout(() => {
      state.missionCtNodes = filterCtNodes({ state, rootState });
      if (!state.missionCtNodes.length) {
        state.treeDepthAsArray = [];
        return;
      }
      // assumes missionCtNodes are already sorted
      const deepestNode = maxBy(state.missionCtNodes, "level");
      state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(
        1
      );
    }, 0.1);
  },

  SET_ALL_REFRENCIAL_CT_NODES: (state, rootState) => {
    state.allCtNodes = values(rootState.entities.referencialCtNodes.data);
    if (!state.allCtNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }
    // assumes allCtNodes are already sorted
    const deepestNode = maxBy(state.allCtNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DATA_FOR_FILTERS: (state, data) => {
    state.dataForFilters = data;
    state.dataForFiltersFetched = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
