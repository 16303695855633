import { ReferencialMappingConfig } from "@/setup/referencialFiltersConfig";
import {
  ReferencialConditionalFilter,
  ReferencialStaticFilter,
} from "./referencialFilterRules";
import { utils } from "@socotec.io/socio-vue-components";
import { values } from "lodash";
import { GRPC_REFERENCIAL_PARAMS as PARAMS } from "./referencial";

const sortNodesByPath = (a, b) => {
  return a.path.localeCompare(b.path, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

export const filterCurrentDispositionNodes = ({ rootState }) => {
  const allNodes = values(rootState.entities.dispositionNodes.data);
  const currentName = PARAMS.AMOS_DISPOSITION_CURRENT_PARAMS.versionName;
  return allNodes
    .filter((node) => node.referencialVersion === currentName)
    .sort(sortNodesByPath);
};

export const filterDraftDispositionNodes = ({ rootState }) => {
  const allNodes = values(rootState.entities.dispositionNodes.data);
  const draftName = PARAMS.AMOS_DISPOSITION_DRAFT_PARAMS.versionName;
  return allNodes
    .filter((node) => node.referencialVersion === draftName)
    .sort(sortNodesByPath);
};

export const filterCurrentTypologyNodes = ({ rootState }) => {
  const nodes = values(rootState.entities.typologyTypeNodes.data);
  const currentName = PARAMS.AMOS_DOC_REVIEW_CURRENT_PARAMS.versionName;
  return nodes
    .filter((node) => node.referencialVersion === currentName)
    .sort(sortNodesByPath);
};

export const filterDraftTypologyNodes = ({ rootState }) => {
  const nodes = values(rootState.entities.typologyTypeNodes.data);
  const draftName = PARAMS.AMOS_DOC_REVIEW_DRAFT_PARAMS.versionName;
  return nodes
    .filter((node) => node.referencialVersion === draftName)
    .sort(sortNodesByPath);
};

export const transformResponse = (res) => {
  const { renameKeys } = utils.grpc;
  const { grpcPropertiesToRename } = utils.referencialUtils;
  const resultsList = res.getResultsList();
  return resultsList.map((item) => {
    let el = item.toObject();
    el = renameKeys(grpcPropertiesToRename, el);
    el.conditionalDisplay = item
      .getConditionalDisplayList()
      .map((item) => item.toJavaScript());
    el.conditionalTitles = item
      .getConditionalTitlesList()
      .map((item) => item.toJavaScript());
    return el;
  });
};

export const filterCtNodes = ({ state, rootState }) => {
  const rulesConfig = ReferencialMappingConfig;
  const excludedNodes = new Set();

  const referencialStaticFilter = new ReferencialStaticFilter(
    rulesConfig.staticRules,
    state.dataForFilters
  );
  const referencialConditionalFilter = new ReferencialConditionalFilter(
    rulesConfig.conditionalDisplay,
    state.dataForFilters
  );
  const excludeNode = (nodePath) => {
    excludedNodes.add(nodePath);
    return false;
  };

  const allNodes = values(rootState.entities.referencialCtNodes.data)
    .filter((node) => {
      const { path, objectId } = node;
      const rapsotecNode =
        rootState.entities.rapsotecNodeAmos.rapsotecNodeMap[objectId];
      // If parent node is excluded, exclude child nodes immediately without applying filters
      if (
        !rapsotecNode ||
        (path.includes(".") &&
          excludedNodes.has(path.split(".").slice(0, -1).join(".")))
      ) {
        excludedNodes.add(path);
        return false;
      }

      // Apply static rules
      const matchStaticFilters = referencialStaticFilter.apply(rapsotecNode);
      if (!matchStaticFilters) return excludeNode(path);
      // Apply conditions display rules
      const matchDynamicFilters = referencialConditionalFilter.apply(node);
      if (!matchDynamicFilters) return excludeNode(path);
      return true;
    })
    .map((node) => {
      const {
        label,
        level,
        uuid,
        isDisplay,
        isOpen,
        path,
        numchild,
        objectId,
      } = node;
      return Object.freeze({
        label,
        level,
        uuid,
        isDisplay,
        isOpen,
        path,
        numchild,
        objectId,
      });
    })
    .sort((a, b) => {
      return a.path.localeCompare(b.path, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  return allNodes;
};
