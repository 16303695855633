import ZoneAsset from "@/models/ZoneAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { setNestedRequest } from "@/utils/request";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";
import requestFactory from "../factory";

const client = socioGrpcClient.amos_back.aos.ZoneControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;

const getters = {
  projectAssetsCount: () => {
    return ZoneAsset.query().where("isProjectAsset", true).get().length;
  },
  getAosZonesFromProjectAssets: () =>
    requestFactory.getters.paginateGetter(
      AosVuexOrmModel["AosZone"].query().whereIdIn(
        ZoneAsset.query()
          .where("isProjectAsset", true)
          .get()
          .map((asset) => asset.aosItem)
      )
    ),
};

const actions = {
  fetchZones: async (
    _,
    { metadata = {}, listAll = false, cacheFirst = false, skipCache = false }
  ) => {
    const request = new socioGrpcClient.amos_back.aos.ZoneListRequest();

    const data = await useCacheOrNetwork({
      modelClass: ZoneAsset,
      fetchFunc: async () => {
        const response = listAll
          ? await client.utils.listAllObjects(request, metadata)
          : await client.list(request, metadata);

        return listAll ? response : response.toObject().resultsList;
      },
      insertFunc: async (data) => {
        await ZoneAsset.insert({ data });
        return data;
      },
      cacheFirst,
      skipCache,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.ZoneRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    ZoneAsset,
    socioGrpcClient.amos_back.aos.ZoneCharacteristicsPartialUpdateRequest,
    client,
    ["code"],
    (data) =>
      setNestedRequest(
        data,
        "surfaceList",
        socioGrpcClient.amos_back.aos.SurfaceRequest,
        ["code"]
      )
  ),
  updateCode: async (_, { uuid, code }) => {
    const request = new socioGrpcClient.amos_back.aos.ZoneUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    ZoneAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "ZoneAsset"
  ),
  move: requestFactory.actions.moveFactory(
    client,
    socioGrpcClient.amos_back.aos.MoveRequest,
    "ZoneAsset"
  ),
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
};
