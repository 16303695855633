import { Action, Periodicity, AosObservable } from "@/models/";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  insertNestedObservations,
  buildActionRequest,
} from "@/utils/observation";
import { insertNestedPeriodicity } from "@/utils/periodicity";
import requestFactory from "../factory";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const client = observationApi.ActionControllerPromiseClient;

const state = {
  actionsCount: 0,
  filterIds: [],
};
const getters = {
  getActionsCount: (state) => state.actionsCount,
  getActions: () => {
    return Action.query().orderBy("createdAt", "desc").orderBy("date").get();
  },
  getObservableWithAssetData: () => (observableUuid) => {
    return AosObservable.query()
      .withAllRecursive(2)
      .whereId(observableUuid)
      .first();
  },
  getObservationsActions: () => (observationUuids) => {
    return Action.query()
      .withAll()
      .where((action) => {
        return action.observationsList.some((observation) => {
          return observationUuids.includes(observation);
        });
      })
      .get();
  },
  observationsActionsCount: () => (observationUuids) => {
    return Action.query()
      .withAll()
      .where((action) => {
        return action.observationsList.some((observation) => {
          return observationUuids.includes(observation);
        });
      })
      .count();
  },
  getBudgetedActions: (state) => {
    return Action.query()
      .has("expensesOverTimeList", ">", 0)
      .whereIdIn(state.filterIds)
      .withAllRecursive()
      .get();
  },

  getInheritableActions: () => (observableIds) => {
    return Action.query()
      .where((action) => {
        return action.observableUuids.every((observableUuid) => {
          return observableIds.includes(observableUuid);
        });
      })
      .where("isArchived", false)
      .get();
  },
};
const actions = {
  async fetchActionsWithAggregates(
    { commit },
    { filters = {}, page, pageSize }
  ) {
    const fetchActions = requestFactory.actions.tableListerFactory({
      ModelClass: Action,
      grpcListRequest: observationApi.ActionListRequest,
      grpcListMethod: client.listWithAggregates,
      onItemsInserted: (commit, count, results) => {
        commit("SET_ACTIONS_COUNT", count);
        commit(
          "SET_FILTER_IDS",
          results.map((result) => result.uuid)
        );
      },
      transformResponseData: async (results) => {
        // Work on different keys of actions so independant
        await Promise.all([
          insertNestedObservations(results),
          insertNestedPeriodicity(results),
        ]);
      },
    })({ commit }, { filters, page, pageSize });

    await fetchActions;
  },

  async updateAction(_, payload) {
    const request = buildActionRequest(payload);

    const response = await client.update(request, {});

    const formatedResponse = response.toObject();
    await Action.update({
      data: {
        ...formatedResponse,
        periodicity: formatedResponse.periodicity?.uuid,
      },
    });
  },

  partialUpdateAction: requestFactory.actions.partialUpdateFactory(
    Action,
    observationApi.ActionPartialUpdateRequest,
    client,
    []
  ),

  async archiveAction({ commit }, actionUuid) {
    await Action.delete(actionUuid);
    commit("SET_ACTIONS_COUNT", getters.getActionsCount - 1);
    commit("draftObservation/REMOVE_DRAFT_ACTION", actionUuid, { root: true });
    const request = new observationApi.ActionSetArchiveValueRequest();
    request.setUuid(actionUuid);
    request.setIsArchived(true);
    await client.setArchiveValue(request, {});
  },

  async destroyAction({ commit, getters }, actionUuid) {
    // INFO - NL - 30/06/2022 - Delete the action if no scenario archived (sent in report), otherwise archived it
    if (!actionUuid) return;
    await Action.delete(actionUuid);
    const request = new observationApi.ActionDestroyRequest();
    request.setUuid(actionUuid);
    await client.destroy(request, {});
    commit("SET_ACTIONS_COUNT", getters.getActionsCount - 1);
  },

  async retrievePeriodicity(_, uuid) {
    const request = new observationApi.PeriodicityRetrieveRequest();
    request.setUuid(uuid);
    const response =
      await observationApi.PeriodicityControllerPromiseClient.retrieve(
        request,
        {}
      );
    await Periodicity.insertOrUpdate({ data: response.toObject() });
  },

  exportActions: requestFactory.actions.exportFactory(
    client,
    observationApi.ExportRequest,
    "action"
  ),

  exportActionsArbitration: requestFactory.actions.exportFactory(
    client,
    observationApi.ExportArbitrationRequest,
    "arbitration",
    "exportArbitration"
  ),
};
const mutations = {
  SET_ACTIONS_COUNT: (state, count) => (state.actionsCount = count),
  SET_FILTER_IDS: (state, ids) => {
    state.filterIds = ids;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
