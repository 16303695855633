import { getResultsFromCache } from "@socotec.io/vuex-orm-rxdb-bridge";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import google_protobuf_empty_pb from "google-protobuf/google/protobuf/empty_pb.js";

export const pingHealthCheck = async () => {
  const response =
    await socioGrpcClient.amos_back.healthcheck.HealthControllerPromiseClient.check(
      new google_protobuf_empty_pb.Empty(),
      {}
    );
  return response.toObject();
};

export const insertOrUpdateDataFromCacheToVuex = async (models) => {
  await Promise.all(
    models.map(async (modelClass) => {
      const cacheResult = await getResultsFromCache(modelClass);
      const response = cacheResult[modelClass.name];
      try {
        await modelClass.insert({
          data: response.map((data) => {
            return { ...data, displayed: true };
          }),
        });
      } catch (e) {
        // this is an error that can be ignored, should eventually be handled
        // in cache.ts file at vuex orm rxdb bridge library
        return;
      }
    })
  );
};

const baseAosArgs = {
  metadata: {},
  requestData: { uuids: [] },
  listAll: true,
  cacheFirst: false,
  projectUuid: "",
};

export const actionCallGroups = [
  [
    {
      action: "asset/fetchAssets",
      args: {
        projectUuid: "",
        caseUuid: "",
        setPerimeterUuid: true,
        cacheFirst: false,
      },
    },
  ],
  [
    {
      action: "asset/fetchAssetTags",
      args: { projectUuid: "", caseUuid: "", listAll: true, cacheFirst: false },
    },
  ],
  [
    {
      action: "aos/site/fetchSitesWithLocations",
      args: baseAosArgs,
    },
    {
      action: "aos/building/fetchBuildingsWithLocations",
      args: baseAosArgs,
    },
    {
      action: "aos/storey/fetchStoreys",
      args: baseAosArgs,
    },
    {
      action: "aos/zone/fetchZones",
      args: baseAosArgs,
    },
    {
      action: "aos/space/fetchSpaces",
      args: baseAosArgs,
    },
    {
      action: "aos/component/fetchComponents",
      args: baseAosArgs,
    },
    {
      action: "project/retrieveProjectData",
      args: {
        projectUuid: "",
        cacheFirst: false,
      },
    },
  ],
  [
    {
      action: "amosAos/fetchBimDataOriginIdsAccessToken",
    },
    {
      action: "document/fetchDocuments",
      args: {
        filters: {
          meta_datas_null_values: { submission: 1 },
        },
        pagination: {
          page_size: 1000,
          page: 1,
        },
        listAll: true,
        cacheFirst: false,
        projectUuid: "",
        relatedItemsInCache: true,
      },
    },
    {
      action: "bimdata/createOrGetModelIdsDocuments",
      args: {
        projectUuid: "",
        listAll: true,
        cacheFirst: false,
      },
      relatedPrevResponse: [
        { "amosAos/fetchBimDataOriginIdsAccessToken": "tokensOriginIds" },
        { "document/fetchDocuments": "documents" },
      ],
    },
    {
      action: "bimdata/fetchProjectArchivesBlob",
      args: {
        projectUuid: "",
        listAll: true,
        cacheFirst: false,
      },
      relatedPrevResponse: [
        { "bimdata/createOrGetModelIdsDocuments": "originModelIdMapping" },
      ],
    },
    {
      action: "bimdata/fetchDrawings",
      args: {
        projectUuid: "",
        listAll: true,
        cacheFirst: false,
      },
      relatedPrevResponse: [
        { "bimdata/createOrGetModelIdsDocuments": "originModelIdMapping" },
      ],
    },
    {
      action: "observation/fetchObservations",
      args: {
        projectUuid: "",
        listAll: true,
        cacheFirst: false,
        relatedItemsInCache: true,
        pagination: { page: 1, pageSize: 1000 },
      },
    },
    {
      action: "pin/fetchPins",
      args: {
        listAll: true,
        cacheFirst: false,
        pagination: { page: 1, pageSize: 1000 },
      },
      relatedPrevResponse: [
        {
          "bimdata/createOrGetModelIdsDocuments": "filters",
          transformPreviousResponseData: (models = {}) => ({
            model_id_in:
              Object.values(models)
                ?.flatMap((model) => model.modelIds)
                ?.map(String) || [],
          }),
        },
      ],
    },
    { action: "document/fetchDocumentTypes" },
    { action: "document/fetchDocumentImageTypes" },
    { action: "documentReview/fetchDocReviewReferencial" },
    { action: "amosAdmin/fetchAnalyticalAxes" },
  ],
];
