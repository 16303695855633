import { socioGrpcClient } from "@/setup/socioGrpcClient";
import i18n from "@/setup/i18n";
import { AosVuexOrmModel, modelUtils } from "@socotec.io/socio-aos-component";
import dayjs from "dayjs";
import {
  Disposition,
  DispositionNode,
  DocumentReview,
  Observation,
  TypologyTypeNode,
  Periodicity,
  CtNodeObservation,
  ReferencialCtNode,
  ObservationStatement,
  Action,
  AnalyticalAxis,
  QuotationValue,
  RiskAssessment,
} from "@/models";
import { orderBy, uniqBy, uniqWith } from "lodash";
import { baseExcludedFields } from "@/store/factory";
import store from "@/store";
import { ANALYTICAL_AXIS_CODES as AXES } from "@/models/AnalyticalAxis";

export const observationOriginEnum = {
  OBSERVATION: "OBSERVATION",
  DISPOSITION: "DISPOSITION",
  DOCUMENT_REVIEW: "DOCUMENT_REVIEW",
  RAPSOTEC_CT: "RAPSOTEC_CT",
};
import { insertNestedPeriodicity } from "@/utils/periodicity";
import { computed } from "vue";

const statementFilterFactory = ({
  objectTypeStatement,
  analyticalAxis,
  contentType,
  extraFilterCallable = () => true,
}) => {
  return (
    objectTypeStatement.analyticalAxis === analyticalAxis &&
    objectTypeStatement.contentType === contentType &&
    extraFilterCallable(objectTypeStatement)
  );
};

const nodesGetterFactory = ({
  observationUuid,
  Model,
  contentType,
  objectIdAttr = null,
}) => {
  const instance = Model.query().where("observation", observationUuid).first();
  if (!instance) return;
  return {
    objectId: objectIdAttr
      ? instance[objectIdAttr]
      : instance.referencialNodeUuid,
    contentType,
  };
};

export const subObservationMap = {
  [observationOriginEnum.OBSERVATION]: {
    NodeModel: (observationUuid) => {
      if (store.getters["project/getIsPicassoVisit"]) return DispositionNode;
      const observation = Observation.find(observationUuid);
      const { assetType } = observation.relatedAosItems[0];
      if (assetType === "component") return AosVuexOrmModel["AosComponentType"];
      return null;
    },

    statementFilterFn: ({ objectTypeStatement, observationUuid, nodeInfo }) => {
      const observation = Observation.find(observationUuid);
      const { assetType } = observation.relatedAosItems[0];
      const { analyticalAxis } = observation;

      if (store.getters["project/getIsPicassoVisit"]) {
        return statementFilterFactory({
          objectTypeStatement,
          analyticalAxis,
          contentType: "dispositiontype",
          extraFilterCallable: (ots) => {
            if (!nodeInfo.objectIds) return true;
            return nodeInfo.objectIds.includes(ots.objectId);
          },
        });
      }

      if (assetType === "component") {
        return statementFilterFactory({
          objectTypeStatement,
          analyticalAxis,
          contentType: "aoscomponenttype",
        });
      }

      return statementFilterFactory({
        objectTypeStatement,
        analyticalAxis,
        contentType: "aosobjecttype",
      });
    },
    nodeInfoGetter: (observationUuid) => {
      if (store.getters["project/getIsPicassoVisit"]) {
        return nodesGetterFactory({
          observationUuid,
          Model: Disposition,
          contentType: "dispositiontype",
        });
      }
      return null;
    },
  },
  [observationOriginEnum.DISPOSITION]: {
    Model: () => Disposition,
    NodeModel: () => DispositionNode,
    referencialTypeName: (i18n) => i18n.t("disposition"),
    statementFilterFn: ({ objectTypeStatement, analyticalAxis }) => {
      return statementFilterFactory({
        objectTypeStatement,
        analyticalAxis,
        contentType: "dispositiontype",
      });
    },
    nodeInfoGetter: (observationUuid) => {
      return nodesGetterFactory({
        observationUuid,
        Model: Disposition,
        contentType: "dispositiontype",
      });
    },
    itemName: "disposition",
    nodeState: "missionDispositionNodes",
    rootPathGetters: "disposition/getCurrentRootPath",
    actions: {
      setDraftItemFromInstance: "setDraftdispositionFromInstance",
      destroy: "disposition/destroyDisposition",
      export: "disposition/exportDispositions",
    },
  },

  [observationOriginEnum.DOCUMENT_REVIEW]: {
    Model: () => DocumentReview,
    NodeModel: () => TypologyTypeNode,
    referencialTypeName: (i18n) => i18n.t("documentReview"),
    statementFilterFn: ({ objectTypeStatement }) => {
      const analyticalAxis = AnalyticalAxis.query()
        .where("code", AXES.DOC_REVIEW)
        .first().uuid;

      return statementFilterFactory({
        objectTypeStatement,
        analyticalAxis,
        contentType: "typologytype",
      });
    },
    nodeInfoGetter: (observationUuid) => {
      return nodesGetterFactory({
        observationUuid,
        Model: DocumentReview,
        contentType: "typologytype",
      });
    },
    itemName: "documentReview",
    nodeState: "currentTypologyTypeNodes",
    rootPathGetters: "documentReview/getCurrentRootPath",
    actions: {
      setDraftItemFromInstance: "setDraftdocumentReviewFromInstance",
      destroy: "documentReview/destroyDocumentReview",
      export: "documentReview/exportDocumentReviews",
    },
  },

  [observationOriginEnum.RAPSOTEC_CT]: {
    Model: () => CtNodeObservation,
    NodeModel: () => ReferencialCtNode,
    referencialTypeName: (i18n) => i18n.t("technicalInspection"),
    statementFilterFn: ({ objectTypeStatement, observationUuid }) => {
      const { analyticalAxis } = Observation.find(observationUuid);
      return statementFilterFactory({
        objectTypeStatement,
        analyticalAxis,
        contentType: "ctnode",
      });
    },
    nodeInfoGetter: (observationUuid) => {
      return nodesGetterFactory({
        observationUuid,
        Model: CtNodeObservation,
        contentType: "ctnode",
      });
    },
    itemName: "ctNodeObservation",
    nodeState: "missionCtNodes",
    rootPathGetters: "ctNodeObservation/getCurrentRootPath",
    actions: {
      setDraftItemFromInstance: "setDraftctNodeObservationFromInstance",
      destroy: "ctNodeObservation/destroyCtNodeObservation",
      export: "",
    },
  },
};

export const buildStatementRequest = (statement) => {
  return socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.ObservationStatementRequest,
    {
      uuid: statement.uuid,
      observation: statement.observation,
      justification: statement.justification,
      documentsList: statement.documentsList,
      objectTypeStatement: statement.objectTypeStatement,
    }
  );
};

export const buildBIMDataPinRequest = (pin) => {
  return socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.BIMDataPinRequest,
    {
      uuid: pin?.uuid,
      observation: pin?.observation,
      pageNumber: pin.pageNumber,
      modelId: pin.modelId,
      submission: pin.submission,
      guid: pin.guid,
      x: pin.x,
      y: pin.y,
      documentOriginId: pin.documentOriginId,
    }
  );
};

export const buildMetadataRequest = (metadata) => {
  return socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.ObservationMetadataRequest,
    metadata
  );
};

export const buildActionRequest = (payload) => {
  const { periodicity = null, contributors = [], ...action } = payload;
  const modelFields = Object.keys(action).filter((f) => f.endsWith("Data"));
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.ActionInputRequest,
    action,
    [
      ...baseExcludedFields,
      ...modelFields,
      "companies",
      "contributors",
      "contributorsList",
      "expensesOverTimeList",
      "expense",
      "priorityType",
      "isSelectable",
      "isSelected",
      "checkboxActivated",
      "checkDisabled",
      "aosObj",
      "designation",
      "building",
      "site",
      "isDraft",
      "periodicityString",
      "importantFieldsUpdatedAt",
      "displayed",
      "createdByEmail",
      "modifiedByEmail",
      "createdByFirstname",
      "modifiedByFirstname",
      "createdByLastname",
      "modifiedByLastname",
    ]
  );

  request.setUuid(action.uuid);
  if (periodicity) {
    const periodicityUuid = periodicity?.uuid ?? periodicity;
    const periodicityData = Periodicity.find(periodicityUuid);
    if (periodicityUuid.startsWith("$")) {
      periodicityData.uuid = null;
    }
    request.setPeriodicity(
      socioGrpcClient.javascriptToRequest(
        socioGrpcClient.amos_back.observation.PeriodicityRequest,
        periodicityData,
        [...baseExcludedFields]
      )
    );
  }

  if (contributors?.length) {
    request.setContributorsList(
      contributors.map((c) =>
        socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.observation.ContributorRequest,
          c,
          [...baseExcludedFields]
        )
      )
    );
  } else {
    request.setContributorsList([]);
  }
  return request;
};

export const riskIcons = {
  LOWEST: "mdi-circle-slice-1",
  LOW: "mdi-circle-slice-2",
  MODERATE: "mdi-circle-slice-4",
  HIGH: "mdi-circle-slice-7",
  UNDEFINED: "mdi-cicle-outline",
  // TODO: replace by mdi-circle-slice-1 when the icon will be available
  DANGER_SECURITY: "mdi-circle-slice-1",
  DANGER_STRUCTURE: "mdi-circle-slice-1",
  EVOLUTION_AVANCEE: "mdi-circle-slice-1",
  DEBUT_EVOLUTION: "mdi-circle-slice-1",
  SANS_GRAVITE: "mdi-circle-slice-1",
};

export const riskColors = {
  Elevé: "#D80F0C",
  Modéré: "#FF6533",
  Faible: "#FCB902",
  "Très faible": "#00833F",
  "Danger pour la sécurité": "#FF0000",
  "Danger pour la structure": "#F49304",
  "Evolution avancée": "#FFFF00",
  "Début d'évolution": "#AECB09",
};

export const riskAssessment = (observationUuid) => {
  const formatRiskLevels = computed(() => {
    const observation = Observation.find(observationUuid);
    const riskData = RiskAssessment.find(observation?.riskAssessment);
    return {
      label: riskData ? i18n.t(`risks.${riskData.code}`) : "",
      icon: riskData ? getIcons(riskData.code) : "",
    };
  });

  const changeColor = computed(
    () => riskColors[formatRiskLevels.value.label] || "05843A"
  );

  const getIcons = (riskLevel) => riskIcons[riskLevel];

  return { getIcons, formatRiskLevels, changeColor };
};

export const PICASSO_STATUS_ENUM = {
  OLD: "OLD",
  OLD_UPDATED: "OLD_UPDATED",
  NEW: "NEW",
};

export const getPicassoObsStatusColor = (status) => {
  const mapping = {
    [PICASSO_STATUS_ENUM.OLD]: "#333333",
    [PICASSO_STATUS_ENUM.OLD_UPDATED]: "#0082DE",
    [PICASSO_STATUS_ENUM.NEW]: "#A9C409",
  };
  return mapping[status];
};

export const durationTypes = () => {
  const fieldArray = (data) => [
    data.number,
    `${i18n.tc(`durationTypes.${data.durationType}`, data.number)} -`,
    i18n.t("durationTypes.starting"),
    dayjs(data.start).format("DD/MM/YYYY"),
  ];

  const getPeriodicityString = (data) => {
    return fieldArray(data).reduce((curr, rest) =>
      modelUtils.smartConcat(curr, rest, " ")
    );
  };

  return { getPeriodicityString };
};

export const insertNestedObservations = async (actionResultsList) => {
  const observations = actionResultsList.flatMap((r) => r.observationsList);
  await Observation.insertOrUpdate({
    data: uniqBy(observations, (o) => o.uuid),
  });
  return actionResultsList.forEach((action) => {
    action.observationsList = action.observationsList.map((o) => o.uuid);
  });
};

export const insertNestedDispositions = async (observations, pathes) => {
  if (!pathes.length) return;
  await store.dispatch("disposition/fetchDispositionReferencial", {
    filters: {
      ancestors_and_descendants: true,
      object_ids_or_pathes: { pathes },
    },
    cacheValidationStrategy: (items) => {
      return items.some((item) => pathes.includes(item.path));
    },
  });
  const dispositions = observations.flatMap((r) => r.dispositionsList);
  if (!dispositions.length) return;

  await Disposition.insert({ data: dispositions });
};

export const insertNestedDocumentReviews = async (observations) => {
  await store.dispatch("documentReview/fetchDocReviewReferencial", {});
  const documentReviews = observations.flatMap((r) => r.documentReviewsList);
  if (!documentReviews.length) return;
  await DocumentReview.insert({ data: documentReviews });
};

export const insertNestedCtNodeObservations = async (observations, pathes) => {
  const filters = {
    ancestors_and_descendants: true,
    object_ids_or_pathes: { pathes },
  };
  await store.dispatch("ctNodeObservation/prefetchDataForFilters");
  await store.dispatch("ctNodeObservation/fetchRapsotecMissionNodes", {
    filters,
  });
  await store.dispatch("ctNodeObservation/fetchRapsotecCtReferencial", {
    filters,
    cacheValidationStrategy: (items) => {
      return items.some((item) => pathes.includes(item.path));
    },
  });

  const ctNodeObservations = observations.flatMap(
    (r) => r.ctnodeobservationsList
  );
  if (!ctNodeObservations.length) return;

  await CtNodeObservation.insert({ data: ctNodeObservations });
};

export const insertNestedObservationStatements = async (observations) => {
  const statements = observations.flatMap((r) => r.observationStatementsList);
  await ObservationStatement.insert({ data: statements });

  // A.S - 2024-06-25 - This is a temporary workaround for Picasso CT observations
  // where the statement is not retrieved in base ListVisitObservations response
  const ctObservationIds = observations
    .map((o) => o.ctObservation?.uuid)
    .filter(Boolean);
  store.dispatch(
    "observationStatement/fetchObservationStatements",
    ctObservationIds
  );
};

export const handleNestedDocuments = async (observations) => {
  const statements = observations.flatMap((r) => r.observationStatementsList);
  const documents = uniqBy(
    [...statements, ...observations].flatMap((r) => r.amosDocumentsUrlsList),
    (d) => d.uuid
  );

  if (!documents.length) return;

  await store.dispatch("document/simpleFetchDocuments", {
    filters: {
      uuids: documents.map((d) => d.uuid),
    },
  });
};

export const insertNestedActions = async (observations) => {
  const actions = observations.flatMap((r) => r.actionsList);
  await insertNestedPeriodicity(actions);
  await Action.insert({ data: actions });
};

export const hydrateDocumentIdsToUrls = (model) => {
  const documents = model.amosDocumentsUrlsList;
  if (documents?.length) {
    documents.forEach((doc) => {
      store.state.observation.documentIdsToUrls[doc.uuid] = doc.documentUrl;
    });
  }
};

export const getSortedDistinctStatements = ({ statements }) => {
  const distinct = uniqWith(statements, (itemA, itemB) => {
    return (
      itemA.objectTypeStatement === itemB.objectTypeStatement &&
      itemA.observation === itemB.observation
    );
  });
  return orderBy(
    distinct,
    [(item) => QuotationValue.find(item.quotationValue)?.value],
    ["asc"]
  );
};

export const getMinQuotedStatement = ({
  observationUuids,
  filterClause = () => true,
}) => {
  const uniqueStatements = uniqBy(
    ObservationStatement.query()
      .where((o) => observationUuids.includes(o.observation) && filterClause(o))
      .get(),
    (item) => item.statementType
  );

  const sortedStatements = orderBy(
    uniqueStatements,
    [(item) => QuotationValue.find(item.quotationValue)?.value],
    ["desc"]
  );

  if (!sortedStatements.length) {
    return undefined;
  }

  const smallestStatement = sortedStatements.at(-1);
  return QuotationValue.find(smallestStatement.quotationValue)?.value;
};

export const checkStatementUniqueConstraint = (
  observationUuid,
  objectTypeStatement
) => {
  return ObservationStatement.query()
    .where(
      (os) =>
        os.observation === observationUuid &&
        os.objectTypeStatement === objectTypeStatement
    )
    .exists();
};

export const picassoStatementFilterFn = ({
  objectTypeStatement,
  analyticalAxis,
  objectIds,
}) =>
  statementFilterFactory({
    objectTypeStatement,
    analyticalAxis,
    contentType: "dispositiontype",
    extraFilterCallable: (ots) => {
      if (!objectIds) return true;
      return objectIds.includes(ots.objectId);
    },
  });
