import Vue from "vue";
import db from "@/rxdb/utils";
import { CacheVisitSettings } from "@/models";
import { TASK_STATUS } from "@/utils/const";
import { actionCallGroups } from "@/utils/cache";

const state = {
  // TODO - MS - This "offlineVisits" state can be removed and all the logic can be handled by query the CacheVisitSettings model
  offlineVisits: {},
  offlineLoadingStep: 0,
  offline: false,
};

const getters = {
  offlineVisitsCount: (state) => {
    return Object.keys(state.offlineVisits).length;
  },
  getOfflineStatus: (state) => {
    return state.offline;
  },
};

const actions = {
  async executeActionCall({ rootGetters, dispatch }, { action, args = {} }) {
    // Set args for AOS call
    const [module, assetType] = action.split("/");
    if (module === "aos") {
      const aosItems = rootGetters["asset/getAosItemsUuidsFromAssets"];
      args.requestData.uuids = aosItems[assetType];
    }
    return await dispatch(action, args, { root: true });
  },
  async cacheProject(
    { dispatch, commit },
    { projectUuid, caseUuid, userUuid, cacheFirst = false }
  ) {
    commit("SET_VISIT_OFFLINE", {
      projectUuid,
      status: TASK_STATUS.PENDING,
    });

    try {
      let previousResponse = {};
      for (let i = 0; i < actionCallGroups.length; i++) {
        for (let { action, args, relatedPrevResponse } of actionCallGroups[i]) {
          const relatedResponse =
            relatedPrevResponse?.map((response) => {
              const [
                [key, value],
                [, transformPreviousResponseData = (x) => x] = [],
              ] = Object.entries(response);
              return {
                [value]: transformPreviousResponseData(previousResponse[key]),
              };
            }) || [];
          const argsUpdated = { ...args, projectUuid, caseUuid, cacheFirst };

          if (relatedResponse.length) {
            relatedResponse.forEach((item) => {
              Object.assign(argsUpdated, item);
            });
          }
          const response = await dispatch("executeActionCall", {
            action,
            args: argsUpdated,
          });
          previousResponse[action] = response;
        }
        commit(
          "SET_VISIT_OFFLINE_SYNC_PROGRESS",
          parseInt(((i + 1) / actionCallGroups.length) * 100)
        );
        await new Promise((resolve) => setTimeout(resolve, 200));
      }
      commit("SET_VISIT_OFFLINE", {
        projectUuid,
        status: TASK_STATUS.SUCCESS,
      });
      await CacheVisitSettings.insert({
        data: {
          project: projectUuid,
          user: userUuid,
          status: TASK_STATUS.SUCCESS,
          updatedAt: new Date().toISOString(),
        },
      });
    } catch (error) {
      console.error(error);
    }
  },
  async loadCachedProject({ commit, rootGetters }) {
    const user = rootGetters["user/getCurrentUser"];
    const data = await db.value.cache_visit_settings
      .find()
      .where("user")
      .eq(user.uuid)
      .exec();

    if (!data.length) return;

    await CacheVisitSettings.insertOrUpdate({
      data: JSON.parse(JSON.stringify(data)),
    });
    data.forEach((item) => {
      commit("SET_VISIT_OFFLINE", {
        projectUuid: item.project,
        status: item.status,
      });
    });
  },
  async deleteProjectFromCache({ commit }, projectUuid) {
    commit("REMOVE_VISIT_OFFLINE", projectUuid);
  },
};

const mutations = {
  SET_OFFLINE_MODE: (state, bool) => {
    state.offline = bool;
  },
  SET_VISIT_OFFLINE_SYNC_PROGRESS: (state, step) => {
    state.offlineLoadingStep = step;
  },
  SET_VISIT_OFFLINE: (state, payload) => {
    state.offlineLoadingStep =
      payload.status === TASK_STATUS.SUCCESS ? 0 : state.offlineLoadingStep;
    Vue.set(state.offlineVisits, payload.projectUuid, payload.status);
  },
  REMOVE_VISIT_OFFLINE: (state, projectUuid) => {
    Vue.delete(state.offlineVisits, projectUuid);
    CacheVisitSettings.delete(projectUuid);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
