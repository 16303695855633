import {
  LabelCertificationLifecycle,
  LabelCertificationType,
  LabelCertificationLabel,
  LabelCertificationVersion,
  LabelCertificationPanel,
  LabelCertificationLevel,
} from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import requestFactory from "../factory";
import { AMOS_ADMIN_ITEMS } from "@/utils/amosAdmin";
import { capitalize, camelToSnakeCase, formatFilters } from "@/utils/helpers";
import SyncOrAsyncQuery from "@/utils/vuexOrmAsyncQuery";

const {
  amos_back: { identification: identificationApi },
} = socioGrpcClient;

const state = {
  identificationDataFetched: false,
  labelCertificationLifecycleCount: 0,
  labelCertificationTypeCount: 0,
  labelCertificationLabelCount: 0,
  labelCertificationVersionCount: 0,
  labelCertificationPanelCount: 0,
  labelCertificationLevelCount: 0,
};

const getters = {
  isIdentificationDataFetched: (state) => state.identificationDataFetched,
  getLabelCertificationLifecycles: () => {
    return LabelCertificationLifecycle.all();
  },
  getLabelCertificationTypes: () => {
    return LabelCertificationType.all();
  },
  getTableItems: () => (adminTableName, sortFunction) =>
    requestFactory.getters.paginateGetter(
      AMOS_ADMIN_ITEMS[adminTableName].modelClass.query().orderBy("ordering"),
      sortFunction
    ),

  getFilteredTableItems: () => (adminTableName, sortFunction) => () =>
    AMOS_ADMIN_ITEMS[adminTableName].modelClass
      .query()
      .where("displayed", true)
      .orderBy("ordering")
      .get()
      .sort(sortFunction),

  countTableItems: () => (adminTableName) => {
    return state[`${adminTableName}Count`];
  },
};
const actions = {
  fetchLabelCertificationLifecycles: requestFactory.actions.listFactory({
    ModelClass: LabelCertificationLifecycle,
    client:
      identificationApi.LabelCertificationLifecycleControllerPromiseClient,
    grpcListRequest: identificationApi.LabelCertificationLifecycleListRequest,
  }),
  fetchLabelCertificationTypes: requestFactory.actions.listFactory({
    ModelClass: LabelCertificationType,
    client: identificationApi.LabelCertificationTypeControllerPromiseClient,
    grpcListRequest: identificationApi.LabelCertificationTypeListRequest,
  }),
  async fetchLabelCertificationLabels() {
    const request =
      new socioGrpcClient.amos_back.identification.LabelCertificationLabelListRequest();

    const response =
      await socioGrpcClient.amos_back.identification.LabelCertificationLabelControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );
    await LabelCertificationLabel.insertOrUpdate({ data: response });
  },
  async fetchLabelCertificationVersions() {
    const request =
      new socioGrpcClient.amos_back.identification.LabelCertificationVersionListRequest();

    const response =
      await socioGrpcClient.amos_back.identification.LabelCertificationVersionControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );

    await LabelCertificationVersion.insertOrUpdate({ data: response });
  },
  async fetchLabelCertificationPanels() {
    const request =
      new socioGrpcClient.amos_back.identification.LabelCertificationPanelListRequest();

    const response =
      await socioGrpcClient.amos_back.identification.LabelCertificationPanelControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );

    await LabelCertificationPanel.insertOrUpdate({ data: response });
  },
  async fetchLabelCertificationLevels() {
    const request =
      new socioGrpcClient.amos_back.identification.LabelCertificationLevelListRequest();

    const response =
      await socioGrpcClient.amos_back.identification.LabelCertificationLevelControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );

    await LabelCertificationLevel.insertOrUpdate({ data: response });
  },

  // Admin part
  async fetchLabelCertificationData({ commit, getters, dispatch }) {
    if (!getters.isIdentificationDataFetched) {
      await Promise.all([
        dispatch("fetchLabelCertificationLifecycles", {}),
        dispatch("fetchLabelCertificationTypes", {}),
        dispatch("fetchLabelCertificationLabels"),
        dispatch("fetchLabelCertificationVersions"),
        dispatch("fetchLabelCertificationPanels"),
        dispatch("fetchLabelCertificationLevels"),
      ]);
      commit("SET_IDENTIFICATION_DATA_AS_FETCHED");
    }
  },

  async fetchAdminTableData(
    { commit },
    { adminTableName, page = 1, pageSize = 1000, filters = {} }
  ) {
    const metadata = {
      filters: formatFilters({
        all_objects: true,
        ...filters,
      }),
      pagination: JSON.stringify({
        page: page,
        page_size: pageSize,
      }),
    };
    const request = new identificationApi[
      `${capitalize(adminTableName)}ListRequest`
    ]();

    const response = await identificationApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].list(request, metadata);

    const { resultsList, count } = response.toObject();
    commit("SET_ADMIN_TABLE_COUNT", {
      count: count,
      adminTableName: adminTableName,
    });
    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.insertOrUpdate({
      data: resultsList.map((data) => {
        return { ...data, displayed: true };
      }),
    });
  },

  async createAdminTableObject({ commit }, { adminTableName, data }) {
    const excludedFields = ["$id", "createdAt", "updatedAt"];

    const request = socioGrpcClient.javascriptToRequest(
      identificationApi[`${capitalize(adminTableName)}Request`],
      { ...data },
      excludedFields
    );

    const metadata = {
      filters: JSON.stringify({
        all_objects: true,
      }),
    };

    const response = await identificationApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].create(request, metadata);

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.insert({
      data: response.toObject(),
    });

    commit("SET_ADMIN_TABLE_COUNT", {
      count: getters.countTableItems(adminTableName) + 1,
      adminTableName: adminTableName,
    });
  },

  async updateAdminTableObject(_, { adminTableName, data }) {
    const excludedFields = ["$id", "createdAt", "updatedAt"];

    const request = socioGrpcClient.javascriptToRequest(
      identificationApi[`${capitalize(adminTableName)}Request`],
      { ...data },
      excludedFields
    );

    const metadata = {
      filters: JSON.stringify({
        all_objects: true,
      }),
    };

    const response = await identificationApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].update(request, metadata);

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.update({
      where: data.uuid,
      data: { ...response.toObject() },
    });
  },

  async partialUpdateAdminTableObject(_, { adminTableName, data }) {
    const excludedFields = ["$id", "createdAt", "updatedAt"];
    const metadata = {
      filters: JSON.stringify({
        all_objects: true,
      }),
    };
    const request = socioGrpcClient.javascriptToRequest(
      identificationApi[`${capitalize(adminTableName)}PartialUpdateRequest`],
      { ...data },
      excludedFields
    );

    request.setPartialUpdateFieldsList(
      Object.keys(data).map((key) => {
        const toSnake = camelToSnakeCase(key);
        return toSnake.endsWith("_list")
          ? toSnake.replace("_list", "")
          : toSnake;
      })
    );

    const response = await identificationApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].partialUpdate(request, metadata);

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.update({
      where: data.uuid,
      data: { ...response.toObject() },
    });
  },

  async retrieveAdminTableObject(_, { adminTableName, uuid }) {
    const request = new identificationApi[
      `${capitalize(adminTableName)}RetrieveRequest`
    ]();
    request.setUuid(uuid);

    const response = await identificationApi[
      `${capitalize(adminTableName)}ControllerPromiseClient`
    ].retrieve(request, {});

    await AMOS_ADMIN_ITEMS[adminTableName].modelClass.insertOrUpdate({
      data: response.toObject(),
    });
    const result = await SyncOrAsyncQuery.handleQuery(
      AMOS_ADMIN_ITEMS[adminTableName].modelClass.query(),
      "find",
      uuid
    );
    return result;
  },
  async fetchLabelsWithAggregate(
    { commit },
    { page = 1, pageSize = 1000, filters = {} }
  ) {
    const metadata = {
      filters: formatFilters({
        all_objects: true,
        ...filters,
      }),
      pagination: JSON.stringify({
        page: page,
        page_size: pageSize,
      }),
    };
    const request =
      new identificationApi.LabelCertificationLabelAggregateDataListRequest();

    const response =
      await identificationApi.LabelCertificationLabelControllerPromiseClient.listWithAggregates(
        request,
        metadata
      );

    const { resultsList, count } = response.toObject();
    commit("SET_ADMIN_TABLE_COUNT", {
      count: count,
      adminTableName: "labelCertificationLabel",
    });
    await LabelCertificationLabel.insertOrUpdate({
      data: resultsList.map((data) => {
        return { ...data, displayed: true };
      }),
    });
  },
};
const mutations = {
  SET_ADMIN_TABLE_COUNT: (state, { count, adminTableName }) =>
    (state[`${adminTableName}Count`] = count),
  SET_IDENTIFICATION_DATA_AS_FETCHED: (state) =>
    (state.identificationDataFetched = true),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
