import { Periodicity } from "@/models";

export const insertNestedPeriodicity = async (actionResultsList) => {
  const periodicities = actionResultsList
    .map((r) => r.periodicity)
    .filter((p) => p);
  await Periodicity.insertOrUpdate({
    data: periodicities,
  });
  return actionResultsList.forEach((action) => {
    action.periodicity = action.periodicity?.uuid;
  });
};
