import { AosObservable } from "@/models";
import { forIn, groupBy } from "lodash";

export const formatAndInsertObservables = async (observables) => {
  await AosObservable.insert({
    data: observables.map(buildObservableObj),
  });
};

export const groupAndMapAssetsBy = (data, mapFn) => {
  const groupedAssets = groupBy(data, "assetType");
  const mappedAssets = {};
  forIn(groupedAssets, (assetItems, assetType) => {
    mappedAssets[assetType] = assetItems.map(mapFn);
  });
  return mappedAssets;
};

export const resetAndInsertAsset = async (
  assetType,
  assetUuidsByType,
  assetReprByType
) => {
  // reset isProjectAsset bool in case of concurrent modifications (could have changed)

  await assetType.modelClass().update({
    where: (asset) => {
      return (
        asset.isProjectAsset === true &&
        assetUuidsByType[assetType].includes(asset.uuid)
      );
    },
    data: { isProjectAsset: false },
  });
  await assetType.modelClass().insertOrUpdate({
    data: assetReprByType[assetType] ?? [],
  });
};

const buildObservableObj = (item) => {
  return {
    ...item,
    assetModel: `${item.assetType.toLowerCase()}Assets`,
  };
};
