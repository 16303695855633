import { socioGrpcClient } from "@/setup/socioGrpcClient";
import BIMDataPin from "@/models/BIMDataPin";
import { formatFilters } from "@/utils/helpers";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const state = {
  draftPin: null,
};

const getters = {
  getDraftPin: (state) => state.draftPin,
  getPins: () => BIMDataPin.all(),
};

const actions = {
  async fetchPins(_, { filters = {} }) {
    const metadata = {
      filters: formatFilters(filters),
    };

    const request = new observationApi.BIMDataPinListRequest();

    return await useCacheOrNetwork({
      modelClass: BIMDataPin,
      fetchFunc: async () => {
        const response =
          await observationApi.BIMDataPinControllerPromiseClient.utils.listAllObjects(
            request,
            metadata
          );
        return response;
      },
      insertFunc: async (data) => {
        console.log("data", data);
        await BIMDataPin.insertOrUpdate({ data });
        return data;
      },

      cacheFirst: true,
      skipCache: false,
    });
  },

  async createPin(_, payload) {
    console.log("payload", payload);
    if (!payload) return;
    try {
      const request = socioGrpcClient.javascriptToRequest(
        observationApi.BIMDataPinRequest,
        {
          ...payload,
          pageNumber: parseInt(payload.pageNumber),
          isArchived: false,
        }
      );
      const response =
        await observationApi.BIMDataPinControllerPromiseClient.create(
          request,
          {}
        );
      return response;
    } catch (error) {
      console.error("Error creating pin", error);
    }
  },

  async deletePin(_, { pinUuid }) {
    if (!pinUuid) throw new Error("BIMDataPin UUID is required");
    try {
      const request = new observationApi.BIMDataPinDestroyRequest();
      request.setUuid(pinUuid);
      await observationApi.BIMDataPinControllerPromiseClient.destroy(
        request,
        {}
      );
      await BIMDataPin.delete(pinUuid);
    } catch (error) {
      console.error("Error deleting pin", error);
    }
  },
};

const mutations = {
  SET_DRAFT_PIN: (state, draftPin) => (state.draftPin = draftPin),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
