import _ from "lodash";

export const referencialVersionNames = {
  CURRENT: "CURRENT",
  OLD: "OLD",
  DRAFT: "DRAFT",
};

export const GRPC_REFERENCIAL_PARAMS = {
  AMOS_DISPOSITION_CURRENT_PARAMS: {
    versionName: "CURRENT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DISPOSITION",
    projectUuid: "",
  },
  AMOS_DISPOSITION_OLD_PARAMS: {
    versionName: "OLD",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DISPOSITION",
    projectUuid: "",
  },
  AMOS_DISPOSITION_DRAFT_PARAMS: {
    versionName: "DRAFT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DISPOSITION",
    projectUuid: "",
  },
  AMOS_DOC_REVIEW_CURRENT_PARAMS: {
    versionName: "CURRENT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DOC_REVIEW",
    projectUuid: "",
  },
  AMOS_DOC_REVIEW_OLD_PARAMS: {
    versionName: "OLD",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DOC_REVIEW",
    projectUuid: "",
  },
  AMOS_DOC_REVIEW_DRAFT_PARAMS: {
    versionName: "DRAFT",
    versionNumber: 0,
    applicationName: "AMOS",
    referencialType: "NATIONAL",
    referencialName: "AMOS_DOC_REVIEW",
    projectUuid: "",
  },
  RAPSOTEC_CT_CURRENT_PARAMS: {
    versionName: "CURRENT",
    versionNumber: 0,
    applicationName: "CT_RAPSOTEC_FRANCE",
    referencialType: "NATIONAL",
    referencialName: "DEFAULT",
    projectUuid: "",
  },
};

export const levels = {
  // TODO - NL - 12/12/22 - Ask if dmax depth
  amosDisposition: _.range(1, 7),
  aosComponents: _.range(1, 7),
};

export const dispositionNodeExcludedFields = [
  "dispositionType",
  "typologyType",
  "analyticalAxis",
];

export const baseExcludedFields = [
  "$id",
  "level",
  "numchild",
  "referencialName",
  "spsNode",
  "isDisplay",
  "isOpen",
  "isSelected",
  "readablePath",
  "modificationOf",
  "path",
  "createdAt",
  "updatedAt",
  "children",
  "rapsotecNode",
  "rootNodePath",
  "childrenList",
].concat(dispositionNodeExcludedFields);

export const archiveExcludedFields = [
  "label",
  "description",
  "code",
  "conditionalTitles",
  "conditionalDisplay",
  "manuallyCreated",
  "parentNode",
  "objectId",
].concat(baseExcludedFields);
