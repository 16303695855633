import { actions as _actions } from "./actions";
import { getters as _getters } from "./getters";
import { mutations as _mutations } from "./mutations";

const state = {
  _cached_observation: null,
  _cached_statements: null,
  observation: null,
  draftCtObservationUuid: null,
  disposition: null,
  documentReview: null,
  ctNodeObservation: null,
  statements: {},
  actions: {},
  inheritedActions: {},

  // only for Picasso Technical Controller wokrflow
  statementIdsToDestroy: [],
};

const getters = { ..._getters };

const actions = { ..._actions };

const mutations = { ..._mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
