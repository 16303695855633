import { v4 as uuidv4 } from "uuid";
import {
  Action,
  CtNodeObservation,
  Disposition,
  DocumentReview,
  Observation,
  ObservationStatement,
} from "@/models";
import { initInheritance, undoInheritance } from "@/utils/draftObservation";
import { clone, cloneDeep, forIn, keyBy, values } from "lodash";
import { PerimeterFactory } from "@/utils/aos";

export const mutations = {
  INIT_DRAFT_OBSERVATION: (state, payload) => {
    state.observation = new Observation();
    state.observation.isDraft = true;
    // base data
    state.observation.uuid = uuidv4();
    state.observation.origin = payload.origin;
    state.observation.createdAt = new Date().toISOString();
    state.observation.updatedAt = new Date().toISOString();

    // editable data
    state.observation.analyticalAxis = payload.analyticalAxis;
    state.observation.metadata = payload.metadata;
    state.observation.amosDocumentsList = payload.amosDocumentsList;
    state.observation.functionalRequirementsList =
      payload.functionalRequirementsList;
    state.observation.ctComment = payload.ctComment;
    state.observation.refCode = payload.refCode;

    // user data
    state.observation.createdBy = payload.user.uuid;
    state.observation.modifiedBy = payload.user.uuid;
    state.observation.createdByFirstname = payload.user.firstName;
    state.observation.createdByLastname = payload.user.lastName;
    state.observation.createdByEmail = payload.user.email;
    state.observation.modifiedByFirstname = payload.user.firstName;
    state.observation.modifiedByLastname = payload.user.lastName;
    state.observation.modifiedByEmail = payload.user.email;

    state.observation.$save();
    state.statements = {};
    state.actions = {};
  },

  INIT_DRAFT_DISPOSITION: (state, payload) => {
    state.disposition = new Disposition();
    state.disposition.isDraft = true;
    state.disposition.uuid = uuidv4();
    state.disposition.referencialNodeUuid = payload.referencialNodeUuid;
    state.disposition.observation = state.observation.uuid;
    state.disposition.aosObservablesList = payload.aosObservablesList;
    state.disposition.$save();
  },

  INIT_DRAFT_CT_NODE_OBSERVATION: (state, payload) => {
    state.ctNodeObservation = new CtNodeObservation();
    state.ctNodeObservation.isDraft = true;
    state.ctNodeObservation.uuid = uuidv4();
    state.ctNodeObservation.referencialNodeUuid = payload.referencialNodeUuid;
    state.ctNodeObservation.observation = state.observation.uuid;
    state.ctNodeObservation.aosObservablesList = payload.aosObservablesList;
    state.ctNodeObservation.$save();
  },

  INIT_DRAFT_DOCREVIEW: (state, payload) => {
    state.documentReview = new DocumentReview();
    state.documentReview.isDraft = true;
    state.documentReview.uuid = uuidv4();
    state.documentReview.referencialNodeUuid = payload.referencialNodeUuid;
    state.documentReview.observation = state.observation.uuid;
    state.documentReview.aosObservablesList = payload.aosObservablesList;
    state.documentReview.$save();
  },

  SET_DRAFT_OBSERVATION_FROM_INSTANCE: (state, obsId) => {
    const instance = Observation.find(obsId);
    const statements = ObservationStatement.query()
      .where((item) =>
        [obsId, instance.ctObservation?.uuid].includes(item.observation)
      )
      .get();
    const actions = Action.query()
      .where((action) => action.observationsList.includes(obsId))
      .get();

    // cache old values in case user cancels workflow, must be a copy
    // to avoid reference mutation.
    state._cached_observation = cloneDeep(instance);
    state._cached_statements = keyBy(statements, "uuid");

    state.observation = instance;
    state.statements = keyBy(statements, "uuid");
    state.actions = keyBy(actions, "uuid");
  },

  SET_DRAFT_DISPOSITION_FROM_INSTANCE: (state, dispositionUuid) => {
    const instance = Disposition.find(dispositionUuid);
    state.disposition = instance;
  },

  SET_DRAFT_DOCREVIEW_FROM_INSTANCE: (state, docReviewUuid) => {
    const instance = DocumentReview.find(docReviewUuid);
    state.documentReview = instance;
  },

  SET_DRAFT_CT_NODE_OBSERVATION_FROM_INSTANCE: (state, ctObsId) => {
    const instance = CtNodeObservation.find(ctObsId);
    state.ctNodeObservation = instance;
  },

  PARTIAL_UPDATE_DRAFT_OBSERVATION: (state, payload) => {
    if (!state.observation) return;
    forIn(payload, (value, key) => (state.observation[key] = value));
    state.observation.$save();
  },

  SET_MULTI_PERIMETER: (state, perimeter) => {
    const factory = new PerimeterFactory(perimeter);
    const observables = factory.toAmosMulti().getUuids();
    state.observation.aosObservablesList = observables;
    state.observation.$save();
  },

  SET_SINGLE_PERIMETER: (state, perimeter) => {
    const factory = new PerimeterFactory(perimeter);
    const observables = factory.toAmosSingle().getUuids();
    state.observation.aosObservablesList = observables;
    state.observation.$save();
  },

  CREATE_DRAFT_STATEMENT: (state, payload) => {
    const statement = new ObservationStatement();
    statement.isDraft = true;
    statement.uuid = uuidv4();
    statement.createdAt = new Date().toISOString();
    statement.updatedAt = new Date().toISOString();
    statement.observation = state.observation.uuid;
    statement.statementType = payload.statementType;
    statement.quotationValue = payload.quotationValue;
    statement.documentsList = payload.documentsList;
    statement.justification = payload.justification;
    statement.objectTypeStatement = payload.objectTypeStatement;
    statement.createdBy = payload.user.uuid;
    statement.createdByFirstname = payload.user.firstName;
    statement.createdByLastname = payload.user.lastName;
    statement.modifiedBy = payload.user.uuid;
    statement.modifiedByFirstname = payload.user.firstName;
    statement.modifiedByLastname = payload.user.lastName;

    // if user is CT, and added a statement to the observation, set modifiedBy to CT
    if (payload.user.isCT()) {
      if (!state.draftCtObservationUuid) {
        throw new Error(
          "CT user entering an observation must have a CT observation uuid set in store"
        );
      }
      statement.observation = state.draftCtObservationUuid;
    }

    statement.$save();
    state.statements[statement.uuid] = statement;
    state.observation.$save();
  },

  UPDATE_DRAFT_STATEMENT: (state, payload) => {
    const statement = ObservationStatement.find(payload.uuid);
    forIn(payload, (value, key) => (statement[key] = value));
    statement.$save();
    state.statements[statement.uuid] = statement;
  },

  CREATE_DRAFT_ACTION: (state, payload) => {
    // do not create suggested action if same type already exists for current observation
    const existingActionRefs = values(state.actions).map((a) => a.actionRef);
    if (existingActionRefs.includes(payload.actionRef) && payload.isSuggested) {
      return;
    }

    const action = new Action();
    action.isDraft = true;
    action.uuid = uuidv4();
    forIn(payload, (value, key) => (action[key] = value));
    action.$save();

    state.actions[action.uuid] = action;
  },

  REMOVE_DRAFT_ACTION: (state, uuid) => {
    if (!state.actions[uuid]) return;
    delete state.actions[uuid];
  },

  SET_INHERITED_ACTIONS: (state, inheritableActions) => {
    const inheritedActions = inheritableActions.map((action) =>
      initInheritance(action, state.observation.uuid)
    );
    state.inheritedActions = keyBy(inheritedActions, "uuid");
  },

  UPDATE_DRAFT_ACTION: (state, instance) => {
    instance.isDraft = true;
    instance.$save();
    state.actions[instance.uuid] = instance;
  },

  RESET_DRAFT_OBSERVATION: (state, resetData = false) => {
    if (!state.observation?.uuid) return;
    if (state.observation.isDraft) {
      state.observation.$delete();
      state.observation = null;
      return;
    }
    // mutate instance back to original using cached values
    if (resetData && state._cached_observation) {
      for (const field of Observation.editableFields) {
        state.observation[field] = clone(state._cached_observation[field]);
        state.observation.$save();
      }
    }

    state.observation.statementsCount = ObservationStatement.query()
      .where((s) => s.observation === state.observation.uuid)
      .count();
    state.observation.actionsCount = Action.query()
      .where((a) => a.observationsList.includes(state.observation.uuid))
      .count();

    state.observation.$save();
    state.observation = null;
  },

  RESET_DRAFT_DISPOSITION: (state) => {
    if (!state.disposition?.uuid) return;
    const instance = Disposition.find(state.disposition?.uuid);
    if (instance?.isDraft) {
      instance.$delete();
    }
    state.disposition = null;
  },

  RESET_DRAFT_DOCREVIEW: (state) => {
    if (!state.documentReview?.uuid) return;
    const instance = DocumentReview.find(state.documentReview?.uuid);
    if (instance?.isDraft) {
      instance.$delete();
    }
    state.documentReview = null;
  },

  RESET_DRAFT_CT_OBSERVATION: (state) => {
    if (!state.ctNodeObservation?.uuid) return;
    const instance = CtNodeObservation.find(state.ctNodeObservation?.uuid);
    if (instance?.isDraft) {
      instance.$delete();
    }
    state.ctNodeObservation = null;
  },

  RESET_DRAFT_STATEMENTS: (state, resetData = false) => {
    if (!state.observation?.uuid) return;
    ObservationStatement.delete((os) => os.isDraft);
    if (resetData && state._cached_statements) {
      values(state.statements).forEach((s) => {
        ObservationStatement.editableFields.forEach((field) => {
          const cachedStatement = state._cached_statements[s.uuid];
          if (cachedStatement) {
            s[field] = cachedStatement[field];
            s.$save();
          }
        });
      });
    }
    state.statements = {};
  },

  RESET_DRAFT_ACTIONS: (state) => {
    if (!state.observation?.uuid) return;
    Action.delete((a) => a.isDraft);
    state.actions = {};
  },

  RESET_INHERITED_ACTIONS: (state) => {
    if (!state.observation?.uuid) return;
    Object.values(state.inheritedActions).forEach((action) => {
      undoInheritance(action, state.observation.uuid);
    });
    state.inheritedActions = {};
  },

  RESET_CACHED_ITEMS: (state) => {
    state._cached_observation = null;
    state._cached_statements = null;
  },

  RESET_DRAFT_PICASSO_CT_OBSERVATION: (state) => {
    state.draftCtObservationUuid = null;
  },

  POP_INHERITED_ACTION: (state, actionUuid) => {
    const instance = Action.find(actionUuid);
    undoInheritance(instance, state.observation.uuid);
    delete state.inheritedActions[instance.uuid];
  },

  POP_DRAFT_ACTION: (state, actionUuid) => {
    const instance = Action.find(actionUuid);
    delete state.actions[instance.uuid];
    instance.$delete();
  },
};
