import { TechnicalAdviceReferencial } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import requestFactory from "../factory";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";

const {
  amos_back: { amos_admin: amos_admin_api },
} = socioGrpcClient;

const state = {
  amosAdminDataFetched: false,
  technicalAdviceReferencialNatureCount: 0,
  referencialCodeCount: 0,
};

const getters = {
  isAmosAdminDataFetched: (state) => state.amosAdminDataFetched,

  getTechnicalReferencials: () => {
    return TechnicalAdviceReferencial.all();
  },
  getNatureCodes: () => (item) => {
    const { nature } = item;
    return TechnicalAdviceReferencial.query().where("nature", nature).get();
  },
  getCodeLabel: () => (item) => {
    const {
      code: { code },
    } = item;
    if (code) {
      const { label } = TechnicalAdviceReferencial.query()
        .where("code", code)
        .first();
      return label;
    }
    return;
  },
};

const actions = {
  fetchTechnicalReferencials: requestFactory.actions.listFactory({
    ModelClass: TechnicalAdviceReferencial,
    client: amos_admin_api.TechnicalAdviceReferencialControllerPromiseClient,
    grpcListRequest: amos_admin_api.TechnicalAdviceReferencialListRequest,
  }),

  async fetchAllTechnicalReferencialData(
    { commit, getters },
    { metadata = {}, cacheFirst = false, skipCache = false } = {}
  ) {
    if (!getters.isAmosAdminDataFetched) {
      const modelClass = TechnicalAdviceReferencial;
      const request =
        new amos_admin_api.TechnicalAdviceReferencialFetchAllRequest();
      await useCacheOrNetwork({
        modelClass,
        fetchFunc: async () => {
          const response =
            await amos_admin_api.TechnicalAdviceReferencialControllerPromiseClient.fetchAll(
              request,
              metadata
            );
          return response.toObject().resultsList;
        },
        insertFunc: async (data) => {
          await modelClass.insertOrUpdate({ data });
          return data;
        },
        cacheFirst,
        skipCache,
      });
      commit("SET_ADMIN_AMOS_DATA_AS_FETCHED");
    }
  },
};

const mutations = {
  SET_ADMIN_AMOS_DATA_AS_FETCHED: (state) =>
    (state.amosAdminDataFetched = true),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
