import { utils } from "@socotec.io/socio-aos-component";
import store from "@/store";
import { ASSET_ITEMS } from "@/utils/const";
import { v4 as uuidv4 } from "uuid";
import compact from "lodash/compact";
import { AosObservable } from "@/models";

const findParentPaths = (path) => {
  const parentPaths = [];

  const segments = path.split(".");
  for (let i = 1; i < segments.length; i++) {
    parentPaths.push(segments.slice(0, i).join("."));
  }
  return parentPaths;
};

const findStructureName = (instance) => {
  let structureName;

  for (const [key, assetType] of Object.entries(ASSET_ITEMS)) {
    const modelClass = assetType.modelClass();
    if (instance instanceof modelClass) {
      return key;
    }
  }
  return structureName;
};

const buildAosRepresentation = ({ asset, parents = [] }) => {
  const structureName =
    asset.contentType || asset.structureName || findStructureName(asset);
  const assetInstance = ASSET_ITEMS[structureName]
    .modelClass()
    .find(asset.uuid);

  const aosItemInstance = utils.aosConst.AOS_ITEMS[structureName]
    .aosModelClass()
    .find(asset.aosItem);

  let parentBreadcrumbs = null;
  if (parents && parents.length > 0) {
    parentBreadcrumbs = parents.flatMap((item) => [
      utils.aosConst.AOS_ITEMS[structureName].itemIcon,
      item.designation,
    ]);
  }

  return Object.freeze({
    uuid: asset.aosItem,
    path: asset.path,
    designation:
      asset?.designation ||
      assetInstance?.designation ||
      aosItemInstance?.baseNomenclature,
    structureName: structureName,
    parents: parents,
    parentBreadcrumbs: parentBreadcrumbs,
  });
};

export const buildPerimeterRepresentation = ({
  uuid,
  assetGroups,
  pathsMapping,
  allAssets,
  previewLimit = 5,
}) => {
  const representation = {
    uuid: uuid,
    items: [],
    count: assetGroups.length,
  };

  assetGroups = assetGroups.slice(0, previewLimit);

  for (let group of assetGroups) {
    const assets = {
      items: [],
    };

    let groupList = [group];
    if (Object.hasOwn(group, "representationsList")) {
      groupList = group.representationsList;
    }

    for (const asset of groupList) {
      const parentPaths = findParentPaths(asset.path);
      const parentAosIds = parentPaths.map((path) => pathsMapping[path]);
      const parentAssets = compact(
        parentAosIds.map((aosId) => allAssets[aosId])
      );
      const parentRepr = parentAssets.map((asset) =>
        buildAosRepresentation({ asset })
      );
      assets.items.push(
        buildAosRepresentation({ asset: asset, parents: parentRepr })
      );
    }

    if (assets.items.length > 0) {
      representation.items.push({ ...assets, ...assets.items[0] });
    }
  }

  return representation;
};

const sortAssetsByLevel = (assets) => {
  const levelsMap = new Map();
  let maxLevel = 0;

  for (const asset of assets) {
    const level = asset.path.split(".").length;
    maxLevel = Math.max(maxLevel, level);
    if (!levelsMap.has(level)) {
      levelsMap.set(level, []);
    }
    levelsMap.get(level).push(asset);
  }

  const result = [];
  for (let i = maxLevel; i > 0; i--) {
    if (levelsMap.has(i)) {
      result.push(levelsMap.get(i));
    }
  }
  return result;
};

/*
 ** Build perimeter representation using AOS items already loaded in store
 ** And inject data in model instances
 */
export const injectPerimeterRepresentation = (
  objs,
  Model,
  aosItemIds = null
) => {
  const useRelations = !aosItemIds;
  const { assets, pathsMapping } = store.getters["asset/getAllAssets"];
  for (const obj of objs) {
    if (useRelations) {
      const aosRelations = obj.relations.filter(
        (r) => r.targetService === "aos"
      );
      aosItemIds = aosRelations.map((r) => r.targetEntityId);
    }
    const perimeter = makePerimeterRepresentation({
      aosItemIds,
      Model,
      obj,
      assets,
      pathsMapping,
    });
    if (perimeter) {
      const instance = Model.find(obj.uuid);
      instance.perimeter = perimeter;
      instance.$save();
    }
  }
};

export const makePerimeterRepresentation = ({
  aosItemIds,
  assets,
  pathsMapping,
}) => {
  const relatedAssets = compact(aosItemIds.map((aosId) => assets[aosId]));
  const sortedAssets = sortAssetsByLevel(relatedAssets);

  if (sortedAssets.length > 0) {
    const perimeter = buildPerimeterRepresentation({
      uuid: uuidv4(),
      assetGroups: sortedAssets[0],
      pathsMapping,
      allAssets: assets,
    });
    if (perimeter) {
      return perimeter;
    }
  }
};

export const toAosRepresentation = (perimeter) => {
  return perimeter.items.reduce((pre, curr) => {
    if (!pre[curr.structureName]) {
      pre[curr.structureName] = [];
    }
    pre[curr.structureName].push(curr.uuid);
    return pre;
  }, {});
};

export const makePerimeterFromAosObservables = ({
  aosObservablesList,
  assets,
  pathsMapping,
}) => {
  const aosItems = AosObservable.findIn(aosObservablesList).map(
    (obs) => obs.aosItemData?.uuid
  );

  if (aosItems?.length) {
    return makePerimeterRepresentation({
      aosItemIds: aosItems,
      assets,
      pathsMapping,
    });
  }
  return {};
};
