import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  SiteAsset,
  BuildingAsset,
  AssetTag,
  StoreyAsset,
  ZoneAsset,
  SpaceAsset,
  ComponentAsset,
  SubcomponentAsset,
  AosObservable,
} from "@/models";
import { ASSET_ITEMS } from "@/utils/const";
import {
  formatAndInsertObservables,
  groupAndMapAssetsBy,
} from "@/utils/response";
import { useAosEventBus, utils } from "@socotec.io/socio-aos-component";
import {
  useCacheOrNetwork,
  getResultFromLocalDb,
  awaitInitialReplicationDone,
  insertInLocalDb,
} from "@socotec.io/vuex-orm-rxdb-bridge";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { usePerimeterReplication } from "@/composables/replicationCollection/perimeter/usePerimeterReplication.js";

const amosPerimeterApi = socioGrpcClient.amos_back.aos;
const amosPerimeterClient = amosPerimeterApi.PerimeterControllerPromiseClient;

const state = {
  tagsSelected: [],
  projectPerimeterUuid: null,
  aosIdToAssetIdMap: {},
  pathToAssetIdMap: {},
};

const getters = {
  getAllAssets: () => {
    const assetsByAosId = {};
    const pathsMapping = {};

    for (const assetItemKey in ASSET_ITEMS) {
      const items = ASSET_ITEMS[assetItemKey].modelClass().query().all();

      items.forEach((item) => {
        assetsByAosId[item.aosItem] = item;
        pathsMapping[item.path] = item.aosItem;
      });
    }

    return {
      assets: assetsByAosId,
      pathsMapping: pathsMapping,
    };
  },
  retrieveAssetFromAosItem: (state) => (assetType, aosItemUuid) => {
    const assetUuid = state.aosIdToAssetIdMap[aosItemUuid];
    const { modelClass } = ASSET_ITEMS[assetType];
    const assetItem = modelClass().query().with("tagsData").find(assetUuid);
    return assetItem;
  },
  getAosItemsUuidsFromAssets: () => {
    let allAssetUuidsByStructure = {};

    Object.keys(ASSET_ITEMS).forEach((assetItemKey) => {
      allAssetUuidsByStructure[assetItemKey] = ASSET_ITEMS[assetItemKey]
        .modelClass()
        .query()
        .get()
        .map((asset) => asset.aosItem);
    });

    return allAssetUuidsByStructure;
  },
  getProjectPerimeterUuid: (state) => state.projectPerimeterUuid,
};

const actions = {
  async fetchAssets({ commit, dispatch, getters }, params = {}) {
    try {
      await dispatch("fetchProjectPerimeter", params);
      await dispatch("fetchAmosData", {
        projectPerimeter: getters.getProjectPerimeterUuid,
        cacheFirst: params.cacheFirst,
      });
    } catch (error) {
      console.error(error);
    }

    // INFO - MS - 17/11/2O22 - can't use useAosEventBus because
    // AosPanel not mounted to listen on events when fetchAssets is called
    commit(
      "aos/SET_AOS_REQUEST_FILTER",
      { uuids: getters.getAosItemsUuidsFromAssets },
      { root: true }
    );
  },

  async fetchAmosData({ dispatch }, params) {
    const projectPerimeter = params?.projectPerimeter;
    const cacheFirst = params?.cacheFirst;
    if (!projectPerimeter) return;

    const filters = (pageSize) => ({
      metadata: {
        filters: JSON.stringify({
          project_perimeter: projectPerimeter,
        }),
        pagination: JSON.stringify({ page_size: pageSize }),
      },
      listAll: true,
      cacheFirst,
    });

    await dispatch("site/fetchSites", filters(10), { root: true });
    await dispatch("building/fetchBuildings", filters(10), { root: true });
    await dispatch("storey/fetchStoreys", filters(100), { root: true });
    await dispatch("zone/fetchZones", filters(200), { root: true });
    await dispatch("space/fetchSpaces", filters(200), { root: true });
    await dispatch("component/fetchComponents", filters(500), { root: true });
  },

  async fetchProjectPerimeter({ dispatch, commit }, params) {
    let perimeter = await getResultFromLocalDb({
      collectionName: "user_perimeter",
      filters: { projectId: params.projectUuid },
    });
    while (!perimeter.length) {
      await new Promise((resolve) => setTimeout(resolve, 2500));
      perimeter = await getResultFromLocalDb({
        collectionName: "user_perimeter",
        filters: { projectId: params.projectUuid },
      });
    }

    const projectPerimeter = perimeter[0].uuid;
    if (params.setPerimeterUuid) {
      commit("SET_PROJECT_PERIMETER_UUID", projectPerimeter);
    }
    // Note G.P I have to await for the user_perimeter replication cycle to be done before fetching from rxdb the AosObservable.
    // firstly, because we want the data to be as up-to-date with server as possible
    // secondly, because you CANNOT read and write at the same time in a rxdb collection.
    await awaitInitialReplicationDone(params.projectUuid, "user_perimeter");
    const aosObservableList = await getResultFromLocalDb({
      collectionName: "aos_observable",
      uuidFilter: perimeter[0].aosObservableUuids,
    });
    await dispatch("storeProjectAssetsFromResponse", aosObservableList);
    return projectPerimeter;
  },

  async addAssetsToLocalPerimeter({ commit, rootGetters }, assetsData) {
    const { insertVisitPerimeter } = usePerimeterReplication();

    const localPerimeterList = await getResultFromLocalDb({
      collectionName: "user_perimeter",
      filters: { projectId: rootGetters["project/getProjectUuid"] },
    });

    const localPerimeter = localPerimeterList[0];

    if (assetsData.components) {
      const localComponents = assetsData.components;
      const aosObservableWithSameAosItem = await getResultFromLocalDb({
        collectionName: "aos_observable",
        inArrayFilter: {
          column: "aosItem",
          list: localComponents.map(({ uuid }) => uuid),
        },
      });
      const aosItemUuid = aosObservableWithSameAosItem.map(
        ({ aosItem }) => aosItem
      );
      let localAosObservableList = [];
      let localComponentAsset = [];
      for (const component of localComponents.filter(
        (c) => !aosItemUuid.includes(c.uuid)
      )) {
        const localDate = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
        const newAsset = {
          uuid: uuidv4(),
          site: component.site,
          code: component.code,
          observationComment: component.observationComment,
          building: component.building,
          isProjectAsset: true,
          aosItem: component.uuid,
          path: component.path,
          createdOrUpdatedOffline: true,
        };
        const newAosObservable = {
          uuid: uuidv4(),
          updatedAt: localDate,
          createdAt: localDate,
          displayed: true,
          assetType: "component",
          assetModel: "componentAssets",
          assetUuid: newAsset.uuid,
          aosItem: component.uuid,
          createdOrUpdatedOffline: true,
        };

        localAosObservableList.push(newAosObservable);
        localComponentAsset.push(newAsset);
        localPerimeter.aosItemUuids.push(newAsset.uuid);
        localPerimeter.aosObservableUuids.push(newAosObservable.uuid);
        localPerimeter.updatedAt = localDate;
        localPerimeter.createdOrUpdatedOffline = true;
      }

      await AosObservable.insertOrUpdate({ data: localAosObservableList });

      await ComponentAsset.insertOrUpdate({ data: localComponentAsset });
      await insertInLocalDb({
        collectionName: "aos_observable",
        dataToInsert: localAosObservableList,
      });
      await insertInLocalDb({
        collectionName: "component_asset",
        dataToInsert: localComponentAsset,
      });
      await insertVisitPerimeter(localPerimeter);
      commit("SET_PROJECT_PERIMETER_UUID", localPerimeter.uuid);
    }
  },

  async addAssetsToProjectPerimeter({ dispatch, rootGetters }, assetsData) {
    const request = new amosPerimeterApi.AddProjectAssetsRequest();

    request.setProjectUuid(rootGetters["project/getProjectUuid"]);
    request.setSitesList(assetsData.sites ?? []);
    request.setBuildingsList(assetsData.buildings ?? []);
    request.setStoreysList(assetsData.storeys ?? []);
    request.setZonesList(assetsData.zones ?? []);
    request.setSpacesList(assetsData.spaces ?? []);
    request.setComponentsList(assetsData.components ?? []);
    request.setSubcomponentsList(assetsData.subcomponents ?? []);

    try {
      const response = await amosPerimeterClient.addProjectAssets(request, {});
      await dispatch(
        "storeProjectAssetsFromResponse",
        response.toObject().aosObservablesList
      );
    } catch (error) {
      console.error(`Error Adding asset to project Perimeter: ${error}`);
    }
  },

  async storeProjectAssetsFromResponse(_, aosObservablesList) {
    if (!aosObservablesList.length) return;
    const assetUuidsByType = groupAndMapAssetsBy(
      aosObservablesList,
      (asset) => asset.uuid
    );

    const assetReprByType = groupAndMapAssetsBy(
      aosObservablesList,
      (asset) => ({
        uuid: asset.assetUuid,
        aosItem: asset.aosItem,
        isProjectAsset: true,
      })
    );

    await Promise.all(
      Object.keys(ASSET_ITEMS).map(async (assetType) => {
        // reset isProjectAsset bool in case of concurrent modifications (could have changed)
        const Model = ASSET_ITEMS[assetType].modelClass();
        // PERF: this Model.update can took a long time if there is a lot of assets (> 1 sec)
        Model.update({
          where: (asset) => {
            return (
              asset.isProjectAsset === true &&
              assetUuidsByType[assetType]?.includes(asset.uuid)
            );
          },
          data: { isProjectAsset: false },
        });
        Model.insertOrUpdate({
          data: assetReprByType[assetType] ?? [],
        });
      })
    );
    await formatAndInsertObservables(aosObservablesList);
  },

  /**
   * @param selectionUuids - an object with structures as key and array of AOS items uuids as value
   * {
   *  site: [aos_uuid1, aos_uuid2],
   *  building: [aos_uuid3],
   *  storey: [storey_uuid4]
   * }
   */
  async removeAssetsFromProjectPerimeter({ rootGetters }, selection) {
    const request = new amosPerimeterApi.RemoveProjectAssetsRequest();
    request.setProjectUuid(rootGetters["project/getProjectUuid"]);
    request.setAosObservablesList(Object.values(selection).flat() ?? []);
    const response = await amosPerimeterClient.removeProjectAssets(request, {});
    return response.toObject();
  },

  // Removes the {Model}Asset of each selected items in the navAmos, based on his aos item uuid
  async removeProjectAssets({ dispatch, rootState, rootGetters }, selection) {
    const getBreadCrumbs = rootGetters["aos/breadcrumbSelectionsAsUuids"];
    const breadcrumbs = Object.entries(getBreadCrumbs());

    // Filter the breadcrumbSelections based on the structure checkbox selection made in the RemoveFromAmosModal
    const breadcrumbEntriesFiltered = breadcrumbs.filter(([key]) =>
      selection.includes(key)
    );
    const filteredBreadcrumbs = Object.fromEntries(breadcrumbEntriesFiltered);

    const { aosObservablesList: aosItems } = await dispatch(
      "removeAssetsFromProjectPerimeter",
      filteredBreadcrumbs
    );

    const { aosEventBus } = useAosEventBus();

    await Promise.all(
      Object.entries(ASSET_ITEMS).map(async ([moduleName, structure]) => {
        const Model = structure.modelClass();

        const query = Model.query().where(
          "aosItem",
          (aosItem) => !aosItems.includes(aosItem)
        );
        if (!query.count()) return;
        await Model.delete((model) => !aosItems.includes(model.aosItem));
        await AosObservable.delete(
          (observable) => !aosItems.includes(observable.aosItem)
        );
        const currentCount =
          rootState.aos[moduleName][`${moduleName}TotalCount`];
        const newTotalCount = currentCount - query.count();

        aosEventBus.$emit(
          utils.aosEventsConst.AOS_EVENTS_LISTENED[
            "UPDATE_STRUCTURE_TOTAL_ITEMS_COUNT"
          ].eventName,
          moduleName,
          newTotalCount < 0 ? 0 : newTotalCount
        );
      })
    );
  },

  async resetState() {
    await Promise.all([
      SiteAsset.deleteAll(true),
      BuildingAsset.deleteAll(true),
      StoreyAsset.deleteAll(true),
      ZoneAsset.deleteAll(true),
      SpaceAsset.deleteAll(true),
      ComponentAsset.deleteAll(true),
      SubcomponentAsset.deleteAll(true),
      AosObservable.deleteAll(true),
    ]);
  },

  async saveAssetTags(context, { tags, itemUuid, itemClass = "building" }) {
    const request = new socioGrpcClient.amos_back.tags.SetItemTagsRequest();
    request.setItemClass(itemClass);
    request.setItemUuid(itemUuid);
    request.setTagsList(
      tags.map((tag) => {
        const tagRequest =
          new socioGrpcClient.amos_back.tags.SmallTagCreateRequest();
        tagRequest.setLabel(tag.label);
        tagRequest.setEditable(tag.editable);
        tagRequest.setChildrenList(
          tag.children.map((child) => child.label ?? child)
        );
        return tagRequest;
      })
    );
    const response =
      await socioGrpcClient.amos_back.tags.TagControllerPromiseClient.setItemTags(
        request,
        {}
      );
    const tagsData = response.toObject().resultsList.map((tag) => ({
      ...tag,
      tagData: tag.tag,
      tag: tag.tag.uuid,
    }));

    const model = ASSET_ITEMS[itemClass].modelClass();

    await model
      .fields()
      .tagsData.parent.query()
      .where("item", itemUuid)
      .delete();
    await model.fields().tagsData.parent.insertOrUpdate({ data: tagsData });

    await model.update({
      where: itemUuid,
      data: {
        tags: tagsData.map((t) => t.uuid),
      },
    });
  },

  async fetchAssetTags({ rootGetters }, params) {
    let caseUuid = params?.caseUuid;
    const cacheFirst = params?.cacheFirst ?? false;
    const listAll = params?.listAll ?? false;
    const client = socioGrpcClient.amos_back.tags.TagControllerPromiseClient;
    const request = new socioGrpcClient.amos_back.tags.TagListCaseTagsRequest();

    if (!caseUuid) {
      const currentProject = rootGetters["project/getCurrentProject"];
      caseUuid = currentProject.caseUuid;
    }
    request.setCaseUuid(caseUuid);

    await useCacheOrNetwork({
      modelClass: AssetTag,
      fetchFunc: async () => {
        const response = listAll
          ? await client.utils.listAllObjects(request, {})
          : await client.listCaseTags(request, {});
        return listAll
          ? response
          : response.getResultsList().map((tag) => tag.toObject());
      },
      insertFunc: async (data) => {
        await AssetTag.insertOrUpdate({ data });
      },
      cacheFirst,
      skipCache: false,
    });
  },

  async fetchDefaultAssetTags(context, { filters }) {
    const metadata = {
      filters: JSON.stringify(filters),
    };
    const request = new socioGrpcClient.amos_back.tags.TagListRequest();
    const response =
      await socioGrpcClient.amos_back.tags.TagControllerPromiseClient.list(
        request,
        metadata
      );
    const resultsList = response.getResultsList().map((tag) => tag.toObject());
    return resultsList;
  },
};

const mutations = {
  SET_TAGS_SELECTED: (state, tags) => {
    state.tagsSelected = tags;
  },

  SET_PROJECT_PERIMETER_UUID: (state, projectPerimeterUuid) => {
    state.projectPerimeterUuid = projectPerimeterUuid;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
