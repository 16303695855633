import ComponentAsset from "@/models/ComponentAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import requestFactory from "../factory";
import { cloneDeep } from "lodash";
import { Struct } from "google-protobuf/google/protobuf/struct_pb";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";

const client = socioGrpcClient.amos_back.aos.ComponentControllerPromiseClient;
const aosStub = socioGrpcClient.amos_back.aos;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;
export class ComponentCharacteristics {
  constructor(data = {}, propertiesList = []) {
    this.uuid = data.uuid;
    this.typology = data.typology;
    this.label = data.label;
    this.quantity = data.quantity;
    this.theoricalLifespan = data.theoricalLifespan;
    this.installationRenewalDate = data.installationRenewalDate;
    this.length = data.length;
    this.width = data.width;
    this.height = data.height;
    this.surface = data.surface;
    this.volume = data.volume;
    this.weight = data.weight;
    this.volumicMass = data.volumicMass;
    this.properties = this.formatProperties(
      (propertiesList.length && propertiesList) || data.propertiesList
    );
  }
  formatProperties(properties = []) {
    if (!properties.length) return {};
    const formattedProperties = {};
    properties.forEach((i) => {
      if (i.value instanceof Struct) {
        formattedProperties[i.propertyDefinition] =
          i.value.toJavaScript().value;
      } else {
        formattedProperties[i.propertyDefinition] = i.value;
      }
    });
    return formattedProperties;
  }
}

const state = {
  componentData: new ComponentCharacteristics(),
  componentDataInitCopy: new ComponentCharacteristics(),
};

const getters = {
  projectAssetsCount: () => {
    return ComponentAsset.query().where("isProjectAsset", true).get().length;
  },
  getAosComponentsFromProjectAssets: () =>
    requestFactory.getters.paginateGetter(
      AosVuexOrmModel["AosComponent"].query().whereIdIn(
        ComponentAsset.query()
          .where("isProjectAsset", true)
          .get()
          .map((asset) => asset.aosItem)
      )
    ),
};

const actions = {
  fetchComponents: async (
    _,
    { metadata = {}, listAll = false, cacheFirst = false, skipCache = false }
  ) => {
    const request = new socioGrpcClient.amos_back.aos.ComponentListRequest();
    const data = await useCacheOrNetwork({
      modelClass: ComponentAsset,
      fetchFunc: async () => {
        const response = listAll
          ? await client.utils.listAllObjects(request, metadata)
          : await client.list(request, metadata);

        return listAll ? response : response.toObject().resultsList;
      },
      insertFunc: async (data) => {
        await ComponentAsset.insert({ data });
        return data;
      },
      cacheFirst,
      skipCache,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request = new aosStub.ComponentRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    ComponentAsset,
    aosStub.ComponentCharacteristicsPartialUpdateRequest,
    client,
    ["properties"]
  ),
  async retrieveComponentData(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.ComponentRetrieveCodeRequest();
    request.setUuid(assetUuid);

    const response = await client.retrieveCode(request, {});
    ComponentAsset.update({
      where: assetUuid,
      data: response.toObject(),
    });
  },

  updateCode: async (_, { uuid, code }) => {
    const request =
      new socioGrpcClient.amos_back.aos.ComponentUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    ComponentAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },

  updateObservationComment: async (_, { uuid, observationComment }) => {
    const request =
      new socioGrpcClient.amos_back.aos.ComponentCharacteristicsPartialUpdateRequest();
    request.setUuid(uuid);
    request.setObservationComment(observationComment);
    request.setPartialUpdateFieldsList(["observation_comment"]);
    ComponentAsset.update({
      where: uuid,
      data: { observationComment },
    });

    return (await client.partialUpdate(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "ComponentAsset"
  ),
  move: requestFactory.actions.moveFactory(
    client,
    socioGrpcClient.amos_back.aos.MoveRequest,
    "ComponentAsset"
  ),
};

const mutations = {
  SET_COMPONENT_DATA: (state, data) => {
    state.componentData = data;
  },
  SET_COMPONENT_DATA_COPY: (state) => {
    state.componentDataInitCopy = cloneDeep(state.componentData);
  },
  SET_COMPONENT_DATA_TO_SAVE: (state, data) => {
    state.componentData = Object.assign(
      new ComponentCharacteristics(),
      state.componentData,
      new ComponentCharacteristics(data)
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
