import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  Project,
  ProjectConfigAnalyticalAxis,
  AnalyticalAxis,
  ProjectConfig,
} from "@/models";
import { ANALYTICAL_AXIS_CODES } from "@/models/AnalyticalAxis";
import { Case, utils } from "@socotec.io/socio-vue-components";
import { APP_NAMES, MISSION_CODES } from "@/utils/const";

const state = {
  currentProjectUuid: null,
  bimdataProjectToken: null,
  isPicassoVisit: false,
  currentApp: null,
  appNames: { ...APP_NAMES },
};

const getters = {
  getCurrentProject: (state) => {
    return Project.query()
      .withAllRecursive(1)
      .where("uuid", state.currentProjectUuid)
      .first();
  },
  getProjectUuid: (state) => {
    return state.currentProjectUuid;
  },
  getIsPicassoVisit: (state) => {
    return state.isPicassoVisit;
  },
  getProjectOriginId: (state, getters) => {
    return getters.getCurrentProject?.originId;
  },
  getProjectAccessToken: (state) => {
    return state.bimdataProjectToken;
  },
  getProjectConfigAnalyticalAxes: (state, getters, rootState, rootGetters) => {
    const projectConfig = rootGetters["projectConfig/getCurrentProjectConfig"];
    return ProjectConfigAnalyticalAxis.query()
      .where("projectConfig", projectConfig.uuid)
      .get();
  },
  getProjectConfigAnalyticalAxesWithoutDocReview: (state, getters) => {
    const docReviewAnalyticalAxisUuid = AnalyticalAxis.query()
      .where("code", ANALYTICAL_AXIS_CODES.DOC_REVIEW)
      .first()?.uuid;
    return getters.getProjectConfigAnalyticalAxes.filter(
      (pCA) => pCA.analyticalAxis !== docReviewAnalyticalAxisUuid
    );
  },
  getProjectAxesIds: (state, getters) => {
    return getters.getProjectConfigAnalyticalAxesWithoutDocReview.map(
      ({ analyticalAxis }) => analyticalAxis
    );
  },
  getProjectAxesCodes: (state, getters) => {
    return AnalyticalAxis.findIn(
      getters.getProjectConfigAnalyticalAxes.map(
        ({ analyticalAxis }) => analyticalAxis
      )
    ).map(({ code }) => code);
  },
  getProjectAxesIdsWithDocReview: (state, getters) => {
    return getters.getProjectConfigAnalyticalAxes.map(
      ({ analyticalAxis }) => analyticalAxis
    );
  },
  getProjectEndDate: (state, getters, rootState, rootGetters) => {
    const projectConfig = rootGetters["projectConfig/getCurrentProjectConfig"];
    const projectDuration = projectConfig.duration;
    const projectStartDate = projectConfig.startDate;
    if (projectDuration && projectStartDate) {
      const startDate = new Date(projectStartDate);
      const endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + projectDuration);
      return endDate.toISOString().split("T")[0];
    }
    return null;
  },
  getProjectStartDate: (state, getters, rootState, rootGetters) => {
    const projectConfig = rootGetters["projectConfig/getCurrentProjectConfig"];
    return projectConfig.startDate;
  },
  getProjectPerimeter: (state, getters) => getters.getCurrentProject?.perimeter,
  excludeSubmissionAndUnder:
    (state, getters, rootState, rootGetters) => (missionsOrSubmissionCodes) => {
      // check if project mission in param
      const project = getters.getCurrentProject;
      if (missionsOrSubmissionCodes.includes(project?.mission)) return true;

      // else check in submissions
      const config = rootGetters["projectConfig/getCurrentProjectConfig"];
      return !missionsOrSubmissionCodes.some((submissionCode) =>
        config?.submissions.includes(submissionCode)
      );
    },
};
const actions = {
  async retrieveProjectData({ commit }, { projectUuid }) {
    const request =
      new socioGrpcClient.amos_back.projects.ProjectRetrieveProjectDataRequest();

    request.setUuid(projectUuid);

    const response =
      await socioGrpcClient.amos_back.projects.ProjectControllerPromiseClient.retrieveProjectData(
        request,
        {}
      );

    const { project, projectConfig, pb_case: caseData } = response.toObject();
    try {
      await Project.insert({ data: project });
      await ProjectConfig.insert({
        data: utils.grpc.renameKeys(
          {
            projectConfigAnalyticalAxesList: "projectConfigAnalyticalAxes",
            submissionsList: "submissions",
          },
          projectConfig
        ),
      });
    } catch (error) {
      console.error("Error while inserting project data", error);
    }

    await Case.insert({ data: caseData });
    commit("case/SET_CURRENT_CASE_UUID", caseData.uuid, { root: true });
    commit("SET_IS_PICASSO_VISIT", project.mission === "HAIA");
    return Project.query().withAll().where("uuid", project.uuid).first();
  },

  async fetchBimDataAccessToken({ commit, rootGetters }) {
    try {
      const request =
        new socioGrpcClient.amos_back.projects.ProjectRetrieveRequest();
      request.setUuid(rootGetters["project/getProjectUuid"]);
      const response =
        await socioGrpcClient.amos_back.projects.ProjectControllerPromiseClient.projectBimdataTokenRetrieve(
          request,
          {}
        );

      commit(
        "SET_BIMDATA_PROJECT_TOKEN",
        response.toObject().bimdataProjectToken
      );
      return response.toObject().bimdataProjectToken;
    } catch (err) {
      throw new Error(err);
    }
  },

  async resetState({ commit, dispatch }) {
    // reset related module states
    commit("aos/component/SET_COMPONENT_PROPERTIES_LOADED", false, {
      root: true,
    });
    dispatch("aos/resetModulesState", null, { root: true });
    dispatch("asset/resetState", null, { root: true });
    dispatch("arbitration/resetState", null, { root: true });
    dispatch("observation/resetState", null, { root: true });
    dispatch("documentReview/resetState", null, { root: true });
    dispatch("ctNodeObservation/resetState", null, { root: true });
    dispatch("disposition/resetState", null, { root: true });
    dispatch("amosAos/resetState", null, { root: true });
    dispatch("projectConfig/resetState", null, { root: true });
    dispatch("mission/resetState", null, { root: true });

    // reset project state
    commit("SET_BIMDATA_PROJECT_TOKEN", null);
    commit("SET_CURRENT_PROJECT", null);
    commit("SET_CURRENT_APP", "");
    commit("SET_IS_PICASSO_VISIT", false);
  },

  async setProjectConfig({ rootGetters }, projectConfigData) {
    const projectUuid = rootGetters["project/getProjectUuid"];

    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.amos_back.projects.ProjectConfigRequest,
      projectConfigData
    );
    request.setUuid(projectUuid);

    const response =
      await socioGrpcClient.amos_back.projects.ProjectControllerPromiseClient.setProjectConfig(
        request,
        {}
      );

    const result = response.toObject();

    await Project.update({
      where: (project) => project.uuid === result.uuid,
      data: result,
    });
  },

  async retrieveSupersetGuestToken({ rootGetters }) {
    const projectUuid = rootGetters["project/getProjectUuid"];
    const perimeterData = rootGetters["amosAos/getUserPerimeterData"];

    let aosItemUuids = [];

    for (const value of Object.values(perimeterData)) {
      aosItemUuids = aosItemUuids.concat(value);
    }

    const request =
      new socioGrpcClient.amos_back.projects.SupersetFiltersRequest();
    request.setProject(projectUuid);

    if (aosItemUuids.length) {
      request.setAosItemsList(aosItemUuids);
    }

    const response =
      await socioGrpcClient.amos_back.projects.ProjectControllerPromiseClient.retrieveSupersetGuestToken(
        request,
        {}
      );
    const { token } = response.toObject();
    return token;
  },

  async retrieveLQADProject(_, extraFilters = {}) {
    const metadata = {
      filters: JSON.stringify({
        model: "case",
        project_offer_item_codes: [MISSION_CODES.LQAD],
        ...extraFilters,
      }),
    };
    const request =
      new socioGrpcClient.production_unit_lister.lister.FullProjectListRequest();

    try {
      const response =
        await socioGrpcClient.production_unit_lister.lister.FullProjectControllerPromiseClient.list(
          request,
          metadata
        );
      return response.toObject().resultsList[0]?.projectUuid;
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  SET_CURRENT_PROJECT: (state, projectUuid) => {
    state.currentProjectUuid = projectUuid;
  },
  SET_BIMDATA_PROJECT_TOKEN: (state, token) => {
    state.bimdataProjectToken = token;
  },
  SET_IS_PICASSO_VISIT: (state, isPicasso) => {
    state.isPicassoVisit = isPicasso;
  },
  SET_CURRENT_APP: (state, appName) => {
    state.currentApp = appName;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
