import { AmosComponentType, AmosAosObjectType } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { forIn, values } from "lodash";
import store from "..";
import requestFactory from "../factory";
import { UserPerimeter } from "@/models/UserPerimeter";
import { v4 as uuidv4 } from "uuid";
import { getResultsFromCache } from "@socotec.io/vuex-orm-rxdb-bridge";
import { PerimeterFactory } from "@/utils/aos";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";

const client = socioGrpcClient.amos_back.aos;

const state = {
  userPerimeter: null,
};

const getters = {
  getUserPerimeter: (state) => state.userPerimeter,
  getUserPerimeterData: (state) => {
    return state.userPerimeter ? state.userPerimeter.data : {};
  },
  getUserPerimeterUuid: (state) => state.userPerimeter?.uuid,
  getProjectPerimeterUuid: () =>
    store.getters["project/getCurrentProject"]?.perimeter,
};

const actions = {
  fetchAmosComponentTypes: requestFactory.actions.listFactory({
    ModelClass: AmosComponentType,
    client: client.AosComponentTypeControllerPromiseClient,
    grpcListRequest: client.AosComponentTypeListRequest,
    skipCache: true,
  }),
  fetchAmosAosObjectTypes: requestFactory.actions.listFactory({
    ModelClass: AmosAosObjectType,
    client: client.AosObjectTypeControllerPromiseClient,
    grpcListRequest: client.AosObjectTypeListRequest,
    skipCache: true,
  }),

  async loadUserPerimetersFromCache() {
    const cacheResult = await getResultsFromCache(UserPerimeter);
    const response = cacheResult[UserPerimeter.name];
    if (response.length) {
      await UserPerimeter.insertOrUpdate({
        data: response,
      });
    }
  },

  async createPerimeterForCurrentUser({ rootGetters, dispatch }, perimeter) {
    const currentProject = rootGetters["project/getProjectUuid"];
    const currentUser = rootGetters["user/getCurrentUser"];
    const aosItemUuids = values(perimeter).flat(1);
    const currentDate = new Date().toISOString();

    // We need to generate a new ID each time as the ID is used to trigger reactivity everywhere
    // So we create a brand new user perimeter when modification occurs
    await UserPerimeter.delete((p) => {
      return p.projectId === currentProject && p.userId === currentUser.uuid;
    });

    if (Object.keys(perimeter).length > 0) {
      await UserPerimeter.insert({
        data: {
          uuid: uuidv4(),
          projectId: currentProject,
          userId: currentUser.uuid,
          aosItemUuids: aosItemUuids,
          data: perimeter,
          updatedAt: currentDate,
          createdAt: currentDate,
        },
      });
    }

    dispatch("initPerimeterForCurrentUser");
  },

  initPerimeterForCurrentUser({ commit, rootGetters }) {
    const currentProject = rootGetters["project/getProjectUuid"];
    const currentUser = rootGetters["user/getCurrentUser"];

    const userPerimeter = UserPerimeter.query()
      .where((perimeter) => {
        return (
          perimeter.projectId === currentProject &&
          perimeter.userId === currentUser.uuid
        );
      })
      .first();

    commit("SET_USER_PERIMETER", { userPerimeter });
  },

  async updatePerimeterForCurrentUser(
    { dispatch, getters },
    { observableIds }
  ) {
    const currentPerimeter = UserPerimeter.find(getters.getUserPerimeterUuid);

    if (currentPerimeter) {
      const factory = new PerimeterFactory({}, observableIds);
      const newPerimeter = factory.toAosRepresentation();
      forIn(currentPerimeter.data, (aosIds, structureName) => {
        newPerimeter[structureName] = [
          ...aosIds,
          ...(newPerimeter[structureName] || []),
        ];
      });
      await dispatch("createPerimeterForCurrentUser", newPerimeter);
    }
  },

  async fetchAosBimDataAccessToken(_, { siteOriginId }) {
    const request =
      new socioGrpcClient.aos_back.structure.SiteProjectRetrieveRequest();
    request.setOriginId(siteOriginId);

    const response =
      await socioGrpcClient.aos_back.structure.SiteControllerPromiseClient.siteBimdataTokenRetrieve(
        request,
        {}
      );
    return response.toObject().bimdataSiteProjectToken;
  },
  // We need to do that in backend, List and not a Retrieve will be better
  async fetchBimDataOriginIdsAccessToken() {
    try {
      const aosSites = AosVuexOrmModel["AosSite"].query().get();
      const originIds = aosSites?.map((site) => site.originId) || [];
      if (!originIds.length) return;
      return await Promise.all(
        originIds.map(async (siteOriginId) => {
          const request =
            new socioGrpcClient.aos_back.structure.SiteProjectRetrieveRequest();
          request.setOriginId(siteOriginId);

          const response =
            await socioGrpcClient.aos_back.structure.SiteControllerPromiseClient.siteBimdataTokenRetrieve(
              request,
              {}
            );
          const token = response.toObject().bimdataSiteProjectToken;
          return {
            originId: siteOriginId,
            token,
          };
        })
      );
    } catch (error) {
      console.error("Error while fetching bimdata access token:", error);
    }
  },

  resetState({ commit }) {
    commit("RESET_USER_PERIMETER");
  },
};

const mutations = {
  SET_USER_PERIMETER: (state, { userPerimeter }) => {
    state.userPerimeter = userPerimeter;
  },
  RESET_USER_PERIMETER: (state) => {
    Object.assign(state, {
      userPerimeter: null,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
