import { socioGrpcClient } from "@/setup/socioGrpcClient";
import BuildingAsset from "@/models/BuildingAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";
import { setNestedRequest } from "@/utils/request";
import requestFactory from "../factory";
import { cloneDeep } from "lodash";
import BuildingCharacteristic from "@/models/BuildingCharacteristic";

const client = socioGrpcClient.amos_back.aos.BuildingControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;
export class InfrastructureCharacteristics {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.nbrOfDecks = data.nbrOfDecks;
    this.nbrOfBays = data.nbrOfBays;
    this.range = data.range;
    this.airDraft = data.airDraft;
    this.waterDraft = data.waterDraft;
    this.bias = data.bias;
    this.totalLength = data.totalLength;
    this.usefulLength = data.usefulLength;
    this.totalWidth = data.totalWidth;
    this.usefulWidth = data.usefulWidth;
    this.roadwayWidth = data.roadwayWidth;
    this.sidewalkWidth = data.sidewalkWidth;
    this.railingHeight = data.railingHeight;
  }
}

const state = {
  infrastructureData: new InfrastructureCharacteristics(),
  infrastructureDataInitCopy: new InfrastructureCharacteristics(),
};

const getters = {
  projectAssetsCount: () => {
    return BuildingAsset.query().where("isProjectAsset", true).get().length;
  },
  getAosBuildingsFromProjectAssets: () =>
    requestFactory.getters.paginateGetter(
      AosVuexOrmModel["AosBuilding"].query().whereIdIn(
        BuildingAsset.query()
          .where("isProjectAsset", true)
          .get()
          .map((asset) => asset.aosItem)
      )
    ),
  getInfrastructureData: () => {
    return state.infrastructureData;
  },
};

const actions = {
  fetchBuildings: async (
    _,
    { metadata = {}, listAll = false, cacheFirst = false, skipCache = false }
  ) => {
    const request = new socioGrpcClient.amos_back.aos.BuildingListRequest();

    const data = await useCacheOrNetwork({
      modelClass: BuildingAsset,
      fetchFunc: async () => {
        const response = listAll
          ? await client.utils.listAllObjects(request, metadata)
          : await client.list(request, metadata);

        let data = listAll ? response : response.toObject().resultsList;
        return data.map((s) => ({ ...s, tagsData: s.tagsList }));
      },
      insertFunc: async (data) => {
        await BuildingAsset.insert({ data });
        return data;
      },
      cacheFirst,
      skipCache,
    });
    return data;
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.BuildingRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    const characteristics = response.toObject();
    await BuildingCharacteristic.insertOrUpdate({ data: characteristics });
    return characteristics;
  },
  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    BuildingAsset,
    socioGrpcClient.amos_back.aos.BuildingCharacteristicsPartialUpdateRequest,
    client,
    ["code"],
    (data) => {
      const excludedFields = ["code"];
      setNestedRequest(
        data,
        "constructionList",
        socioGrpcClient.amos_back.aos.ConstructionRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "surfaceList",
        socioGrpcClient.amos_back.aos.SurfaceRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "labelCertificationList",
        socioGrpcClient.amos_back.aos.LabelCertificationRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "theoricalWorkforceList",
        socioGrpcClient.amos_back.aos.TheoricalWorkforceRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "clearanceList",
        socioGrpcClient.amos_back.aos.ClearanceRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "visitHistoryList",
        socioGrpcClient.amos_back.aos.VisitHistoryRequest,
        excludedFields
      );
      return data;
    }
  ),
  updateCode: async (_, { uuid, code }) => {
    const request =
      new socioGrpcClient.amos_back.aos.BuildingUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    BuildingAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "BuildingAsset"
  ),
  move: requestFactory.actions.moveFactory(
    client,
    socioGrpcClient.amos_back.aos.MoveRequest,
    "BuildingAsset"
  ),
};

const mutations = {
  SET_INFRASTRUCTURE_DATA: (state, data) => {
    state.infrastructureData = data;
  },
  SET_INFRASTRUCTURE_DATA_COPY: (state) => {
    state.infrastructureDataInitCopy = cloneDeep(state.infrastructureData);
  },
  SET_INFRASTRUCTURE_DATA_TO_SAVE: (state, data) => {
    state.infrastructureData = Object.assign(
      new InfrastructureCharacteristics(),
      state.infrastructureData,
      new InfrastructureCharacteristics(data)
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
