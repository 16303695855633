import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  ProjectConfig,
  ProjectConfigAnalyticalAxis,
  VentilationGroup,
  ReportTemplateType,
  ReportConfigGraphic,
  SubMission,
  RiskAssessmentGroup,
  RiskAssessment,
} from "@/models";

import factory from "../factory";
import { utils } from "@socotec.io/socio-vue-components";
import { EXPENSE_TYPE, riskGroupByMissionCodeMap } from "@/utils/const";

const client =
  socioGrpcClient.amos_back.projects.ProjectConfigControllerPromiseClient;

const state = {
  projectConfigEdited: {},
};

const getters = {
  getCurrentProjectConfig: (state, getters, rootState, rootGetters) => {
    return ProjectConfig.query()
      .where("project", rootGetters["project/getProjectUuid"])
      .withAllRecursive()
      .first();
  },
  getProjectConfigEdited: (state) => {
    return state.projectConfigEdited;
  },

  getProjectRisks: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters["project/getCurrentProject"].mission;
    const missionRiskGroup = riskGroupByMissionCodeMap[mission];
    const riskAssessmentGroup = RiskAssessmentGroup.query()
      .where("label", missionRiskGroup)
      .first();
    const riskLevelIds = riskAssessmentGroup?.riskAssessmentsList ?? [];
    return RiskAssessment.query().whereIdIn(riskLevelIds).get();
  },

  getProjectConfigReportTemplateType: (state, getters) => {
    const submission = SubMission.query()
      .where(
        (item) => getters.getCurrentProjectConfig.submissions[0] === item.code
      )
      .first();

    return ReportTemplateType.query()
      .whereIdIn(submission.reportTemplateTypesList)
      .get();
  },
  hasProjectConfigOpexCapexVentilation: (state, getters) => {
    const projectConfig = getters.getCurrentProjectConfig;
    const isVentilationGroupOpexCapex = [
      EXPENSE_TYPE.OPEX,
      EXPENSE_TYPE.CAPEX,
    ].every((code) =>
      VentilationGroup.find(projectConfig.ventilationGroup)?.label.includes(
        code
      )
    );
    return projectConfig.hasVentilation && isVentilationGroupOpexCapex;
  },
  getProjectConfigSubmissions: (state, getters) => {
    return getters.getCurrentProjectConfig.submissions;
  },
};

const actions = {
  async updateProjectConfig({ dispatch }, projectConfigData) {
    // Force delete ProjectConfigAnalyticalAxis data as insertOrUpdate
    // will not destroy related objects not in projectConfigData anymore
    await ProjectConfigAnalyticalAxis.deleteAll();
    return await dispatch("partialUpdateProjectConfig", projectConfigData);
  },
  partialUpdateProjectConfig: factory.actions.partialUpdateFactory(
    ProjectConfig,
    socioGrpcClient.amos_back.projects.ProjectConfigPartialUpdateRequest,
    client,
    ["project"],
    (projectConfig) => {
      projectConfig = {
        ...projectConfig,
        siteCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects.SiteCharacteristicsRequest,
          projectConfig.siteCharacteristics
        ),
        buildingCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects.BuildingCharacteristicsRequest,
          projectConfig.buildingCharacteristics
        ),
        infrastructureCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects
            .InfrastructureCharacteristicsRequest,
          projectConfig.infrastructureCharacteristics
        ),
        storeyCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects.StoreyCharacteristicsRequest,
          projectConfig.storeyCharacteristics
        ),
        zoneCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects.ZoneCharacteristicsRequest,
          projectConfig.zoneCharacteristics
        ),
        spaceCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects.SpaceCharacteristicsRequest,
          projectConfig.spaceCharacteristics
        ),
        componentCharacteristics: socioGrpcClient.javascriptToRequest(
          socioGrpcClient.amos_back.projects.ComponentCharacteristicsRequest,
          projectConfig.componentCharacteristics
        ),
        projectConfigAnalyticalAxes:
          projectConfig.projectConfigAnalyticalAxes.map((pCA) =>
            socioGrpcClient.javascriptToRequest(
              socioGrpcClient.amos_back.projects
                .ProjectConfigAnalyticalAxisRequest,
              pCA,
              [
                ...Object.keys(ProjectConfigAnalyticalAxis.fields()).filter(
                  (f) => f.endsWith("Data") || f.endsWith("Editable")
                ),
                ...factory.baseExcludedFields,
              ]
            )
          ),
        reportConfigGraphicsList: projectConfig.reportConfigGraphicsList,
      };
      return projectConfig;
    },
    (projectConfig) => {
      const keysMap = {
        projectConfigAnalyticalAxesList: "projectConfigAnalyticalAxes",
        submissionsList: "submissions",
      };
      projectConfig = utils.grpc.renameKeys(keysMap, projectConfig);
      return projectConfig;
    }
  ),

  /**
   * Fetch project Config Graphic list
   * @param [commit]
   * @param metadata
   * @returns {Promise<*>}
   */
  fetchProjectConfigGraphicList: factory.actions.listFactory({
    ModelClass: ReportConfigGraphic,
    client:
      socioGrpcClient.amos_back.projects
        .ReportConfigGraphicControllerPromiseClient,
    grpcListRequest:
      socioGrpcClient.amos_back.projects.ReportConfigGraphicListRequest,
  }),

  async lightListProjectConfigs(
    _,
    { filters = {}, pagination = {}, uuids = [] }
  ) {
    const metadata = {
      filters: JSON.stringify(filters),
      pagination: JSON.stringify(pagination),
    };
    const request =
      new socioGrpcClient.amos_back.projects.ProjectConfigLightListRequest();

    request.setUuidsList(uuids);
    const response =
      await socioGrpcClient.amos_back.projects.ProjectConfigControllerPromiseClient.lightList(
        request,
        metadata
      );
    return response.toObject().resultsList;
  },

  resetState({ commit }) {
    commit("SET_PROJECT_CONFIG_EDITED", {});
  },
};

const mutations = {
  SET_PROJECT_CONFIG_EDITED: (state, data) => {
    state.projectConfigEdited = data;
  },
  UPDATE_PROJECT_CONFIG_EDITED: (state, data) => {
    state.projectConfigEdited = Object.assign(
      {},
      state.projectConfigEdited,
      data
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
