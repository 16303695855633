const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");

import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { StandByReport } from "@/models";

const state = {
  standByReportsCount: 0,
  generatingStandbyReport: false,
};

const getters = {
  getStandByReportsCount: (state) => {
    return state.standByReportsCount;
  },
  isReportGenerating: (state) => {
    return state.generatingStandbyReport;
  },
};

const actions = {
  /**
   * Create a standby by report
   * @param [rootGetters]
   * @param reportTemplate
   * @param projectId
   * @param reportGenerationData
   * @returns {Promise<*>}
   */
  async createStandByReport(
    { rootGetters },
    { reportTemplate, projectId, perimeterId, reportGenerationData }
  ) {
    const request =
      new socioGrpcClient.amos_back.standbyreport.StandByReportRequest();
    if (typeof reportGenerationData === "undefined") {
      reportGenerationData = new google_protobuf_struct_pb.Struct();
    } else {
      reportGenerationData =
        new google_protobuf_struct_pb.Struct.fromJavaScript(
          // Necessary because the fromJavascript method only supports the object type
          // and not class instances (Like vuex ORM models)
          JSON.parse(JSON.stringify(reportGenerationData))
        );
    }
    request.setReportGenerationData(reportGenerationData);
    request.setUser(rootGetters["user/getCurrentUser"].uuid);
    request.setTemplate(reportTemplate.uuid);
    request.setProject(projectId);
    request.setPerimeter(perimeterId);
    const response =
      await socioGrpcClient.amos_back.standbyreport.StandByReportControllerPromiseClient.create(
        request,
        {}
      );

    const standByReport = await StandByReport.insert({
      data: response.toObject(),
    });
    return standByReport.standByReport[0];
  },

  async updateStandByReport(_, instance) {
    const request =
      new socioGrpcClient.amos_back.standbyreport.StandByReportPartialUpdateRequest();
    request.setPartialUpdateFieldsList(["report_generation_data"]);
    request.setUuid(instance.uuid);
    const reportGenerationData =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        // Necessary because the fromJavascript method only supports the object type
        // and not class instances (Like vuex ORM models)
        JSON.parse(JSON.stringify(instance.reportGenerationData))
      );
    request.setReportGenerationData(reportGenerationData);
    try {
      const response =
        await socioGrpcClient.amos_back.standbyreport.StandByReportControllerPromiseClient.partialUpdate(
          request,
          {}
        );
      return await StandByReport.update({
        where: instance.uuid,
        data: {
          ...response.toObject(),
        },
      });
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * Retrieve a standby report
   * @param [_]
   * @param standByReportUuid {string}
   * @returns {Promise<StandByReport>}
   */
  async retrieveStandByReport(_, standByReportUuid, metadata = {}) {
    const request =
      new socioGrpcClient.amos_back.standbyreport.StandByReportRequest();

    if (standByReportUuid) {
      request.setUuid(standByReportUuid);
    }

    const response =
      await socioGrpcClient.amos_back.standbyreport.StandByReportControllerPromiseClient.retrieve(
        request,
        metadata
      );
    const standByReportData = response.toObject();

    standByReportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    const standByReport = await StandByReport.insert({
      data: standByReportData,
    });

    return standByReport.standByReport[0];
  },

  async retrieveOrCreateStandByReport(
    _,
    { templateId, projectId, userId, form },
    metadata = {}
  ) {
    const request =
      new socioGrpcClient.amos_back.standbyreport.StandByReportRequest();
    const reportGenerationData =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        // Necessary because the fromJavascript method only supports the object type
        // and not class instances (Like vuex ORM models)
        JSON.parse(JSON.stringify(form))
      );
    request.setReportGenerationData(reportGenerationData);
    request.setUser(userId);
    request.setTemplate(templateId);
    request.setProject(projectId);

    const response =
      await socioGrpcClient.amos_back.standbyreport.StandByReportControllerPromiseClient.retrieveOrCreateByPerimeter(
        request,
        metadata
      );
    const standByReportData = response.toObject();

    standByReportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    const standByReport = await StandByReport.insert({
      data: standByReportData,
    });
    const reportObject = standByReport.standByReport[0];
    return reportObject;
  },

  /**
   * Request a report generation
   * @param [dispatch, commit]
   * @param standByReportUuid Report uuid
   * @returns {Promise<any>}
   */
  async generateStandByReport(
    { dispatch, commit, rootGetters },
    { standByReportUuid, metadata = {} }
  ) {
    const request =
      new socioGrpcClient.amos_back.standbyreport.StandByReportRequest();

    request.setUuid(standByReportUuid);

    commit("SET_GENERATING_STANDBYREPORT", true);
    try {
      const stream =
        await socioGrpcClient.amos_back.standbyreport.StandByReportControllerPromiseClient.generate(
          request,
          metadata
        );

      let reportUuid = "";

      stream.on("data", async function (response) {
        let action = {
          [StandByReport.STATUS_CODE.SUCCESS]: async () => {
            await StandByReport.delete(standByReportUuid);
            dispatch(
              "notifications/showSuccessNotification",
              `The report has been successfully generated`,
              { root: true }
            );
            await dispatch(
              "report/fetchReport",
              { reportUuid },
              { root: true }
            );
            commit(
              "report/UPDATE_REPORTS_COUNT",
              rootGetters["report/getReportsCount"] + 1,
              {
                root: true,
              }
            );
            commit("SET_GENERATING_STANDBYREPORT", false);
          },
          [StandByReport.STATUS_CODE.GENERATION_FAILED]: async () => {
            dispatch(
              "notifications/showErrorNotification",
              `An error occurred while generating the report, please try again.`,
              { root: true }
            );
          },
          [StandByReport.STATUS_CODE.UPLOAD_FAILED]: async () => {
            dispatch(
              "notifications/showErrorNotification",
              `An error occurred while generating the report, please try again.`,
              { root: true }
            );
          },
          [StandByReport.STATUS_CODE.ERROR]: async () => {
            dispatch(
              "notifications/showErrorNotification",
              `An error occurred while generating the report, please try again.`,
              { root: true }
            );
          },
          [StandByReport.STATUS_CODE.GENERATING]: async () => {},
        };

        await action[response.getCode()]();
        reportUuid = response.toObject()?.reportUuid;
      });
    } catch (err) {
      commit("SET_GENERATING_STANDBYREPORT", false);
      console.error(err);
    }
  },
};

const mutations = {
  UPDATE_STANDBY_REPORT_COUNT: function (state, newTotal) {
    state.standByReportsCount = newTotal;
  },
  SET_GENERATING_STANDBYREPORT: function (state, newValue) {
    state.generatingStandbyReport = newValue;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
