import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { Client } from "@socotec.io/socio-vue-components";
import { Project, Case } from "@/models";
import { getResultsFromCache } from "@socotec.io/vuex-orm-rxdb-bridge";

const state = {
  currentCaseUuid: "",
  casesCount: 0,
};

const getters = {
  getCurrentCase: (state) => {
    return Case.query()
      .where("uuid", state.currentCaseUuid)
      .with("projects")
      .with("manager")
      .first();
  },

  getCurrentCaseUuid: (state) => state.currentCaseUuid,
  getCasesCount: (state) => state.casesCount,
};

const actions = {
  async fetchCases({ commit }, { queryAsObject = {}, ordering = null }) {
    const metadata = {
      filters: JSON.stringify({
        model: "case",
        ...queryAsObject,
        case_ordering: ordering,
      }),
    };
    const request =
      new socioGrpcClient.production_unit_lister.lister.FullProjectListRequest();

    let response = [];
    let networkError = false;
    try {
      response =
        await socioGrpcClient.production_unit_lister.lister.FullProjectControllerPromiseClient.list(
          request,
          metadata
        );
    } catch (e) {
      networkError = true;
    }
    let count = 0;
    let resultsList = [];
    if (!networkError) {
      const responseObject = response.toObject();
      resultsList = responseObject.resultsList;
      count = responseObject.count;
    }
    let modelClass = Client;
    if (networkError) {
      let cacheResult = await getResultsFromCache(modelClass);
      resultsList = cacheResult[modelClass.name];
      modelClass.insertOrUpdate({ data: resultsList });
    } else {
      resultsList.forEach((data) => {
        if (!data.clientUuid) return;
        modelClass.createFromListerData(data);
      });
    }
    modelClass = Case;
    if (networkError) {
      let cacheResult = await getResultsFromCache(modelClass);
      resultsList = cacheResult[modelClass.name];
      count = new Set(resultsList.map((item) => item.caseNumber)).size;
      modelClass.insertOrUpdate({ data: resultsList });
    } else {
      resultsList.forEach((data) => {
        modelClass.insertOrUpdate({
          data: {
            uuid: data.caseUuid,
            caseNumber: data.caseNumber,
            label: data.rapsoCaseLabel || data.caseLabel,
            city: data.rapsoCaseCity || data.caseCity,
            address: data.caseAddress,
            status: data.caseStatus,
            clientUuid: data.clientUuid,
            updatedAt: data.caseUpdatedAt,
            // manager: data.caseManager,
            managerEmail: data.caseManager?.email,
            managerUsermanagementUuid: data.caseManager?.usermanagementUuid,
            caseAgencyCode: data.caseAgencyCode,
          },
          insert: ["clients"],
        });
      });
    }
    commit("SET_CASES_COUNT", count);
    modelClass = Project;
    if (networkError) {
      let cacheResult = await getResultsFromCache(modelClass);
      resultsList = cacheResult[modelClass.name];
      modelClass.insertOrUpdate({ data: resultsList });
    } else {
      resultsList.forEach((data) => {
        modelClass.insertOrUpdate({
          data: {
            uuid: data.projectUuid,
            projectName: data.rapsoProjectName || data.projectName,
            chronorapso: data.projectChronorapso,
            caseUuid: data.caseUuid,
            caseNumber: data.caseNumber,
            city: data.rapsoCaseCity || data.caseCity,
            updatedAt: data.projectUpdatedAt,
            projectPilot: data.projectPilot,
            mission: data.projectOfferItemCodesList[0],
            managerEmail: data.projectManager?.email,
            managerUsermanagementUuid: data.projectManager?.usermanagementUuid,
            isArchived: data.projectIsArchived,
            lastReportTemplateName:
              data.rapsoLastReportTemplateName || data.lastReportTemplateName,
            lastReportDate: data.rapsoLastReportDate || data.lastReportDate,
            alertCodesList: data.rapsoAlertCodesList || data.alertCodesList,
            phase: data.rapsoPhase || data.phase,
            client: data.clientLabel,
            isFavorite: data.isFavorite,
          },
          insert: ["cases"],
        });
      });
    }
  },

  async updateCaseMainRecipient(context, { caseUuid, contributorUuid }) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.amos_back.projects.CaseUpdateCaseMainRecipientRequest,
      {
        caseUuid: caseUuid,
        contributorUuid: contributorUuid,
      }
    );

    const response =
      await socioGrpcClient.amos_back.projects.CaseControllerPromiseClient.updateCaseMainRecipient(
        request,
        {}
      );

    await Case.insertOrUpdate({ data: response.toObject() });
  },
};

const mutations = {
  SET_CURRENT_CASE_UUID: (state, uuid) => {
    state.currentCaseUuid = uuid;
  },
  SET_CASES_COUNT: (state, count) => {
    state.casesCount = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
