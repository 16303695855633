import StoreyAsset from "@/models/StoreyAsset";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { setNestedRequest } from "@/utils/request";
import { useCacheOrNetwork } from "@socotec.io/vuex-orm-rxdb-bridge";
import requestFactory from "../factory";
import { cloneDeep } from "lodash";

const client = socioGrpcClient.amos_back.aos.StoreyControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;
export class StoreyCharacteristics {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.altimetryNgf = data.altimetryNgf;
    this.altimetryReference = data.altimetryReference;
    this.tonnage = data.tonnage;
  }
}

const state = {
  storeyData: new StoreyCharacteristics(),
  storeyDataInitCopy: new StoreyCharacteristics(),
};

const getters = {
  projectAssetsCount: () => {
    return StoreyAsset.query().where("isProjectAsset", true).get().length;
  },
  getAosStoreysFromProjectAssets: () =>
    requestFactory.getters.paginateGetter(
      AosVuexOrmModel["AosStorey"].query().whereIdIn(
        StoreyAsset.query()
          .where("isProjectAsset", true)
          .get()
          .map((asset) => asset.aosItem)
      )
    ),
};

const actions = {
  fetchStoreys: async (
    _,
    { metadata = {}, listAll = false, cacheFirst = false, skipCache = false }
  ) => {
    const request = new socioGrpcClient.amos_back.aos.StoreyListRequest();

    const data = await useCacheOrNetwork({
      modelClass: StoreyAsset,
      fetchFunc: async () => {
        const response = listAll
          ? await client.utils.listAllObjects(request, metadata)
          : await client.list(request, metadata);

        return listAll ? response : response.toObject().resultsList;
      },
      insertFunc: async (data) => {
        await StoreyAsset.insert({ data });
        return data;
      },
      cacheFirst,
      skipCache,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.StoreyRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    StoreyAsset,
    socioGrpcClient.amos_back.aos.StoreyCharacteristicsPartialUpdateRequest,
    client,
    ["code"],
    (data) =>
      setNestedRequest(
        data,
        "surfaceList",
        socioGrpcClient.amos_back.aos.SurfaceRequest,
        ["code"]
      )
  ),
  updateCode: async (_, { uuid, code }) => {
    const request = new socioGrpcClient.amos_back.aos.StoreyUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    StoreyAsset.update({
      where: uuid,
      data: { code },
    });
    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "StoreyAsset"
  ),
};

const mutations = {
  SET_STOREY_DATA: (state, data) => {
    state.storeyData = data;
  },
  SET_STOREY_DATA_COPY: (state) => {
    state.storeyDataInitCopy = cloneDeep(state.storeyData);
  },
  SET_STOREY_DATA_TO_SAVE: (state, data) => {
    state.storeyData = Object.assign(
      new StoreyCharacteristics(),
      state.storeyData,
      new StoreyCharacteristics(data)
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
