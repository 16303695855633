import { DocumentType, utils } from "@socotec.io/socio-vue-components";
import { Document, DocumentImageType } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { restDocumentClient } from "@/setup/restDocumentClient";
import { injectPerimeterRepresentation } from "@/utils/perimeterRepr";
import { Relation } from "@/models/Relation";
import { useDocumentRelationBuilder } from "@/composables/documents/useDocumentRelationBuilder";
import { useDocumentLinking } from "@/composables/document-linking/useDocumentLinking.js";
import requestFactory from "@/store/factory";
import db from "@/rxdb/utils";
import { v4 as uuidv4 } from "uuid";
import { setRequestFilters } from "@/utils/request";
import { picassoDocumentTypes, blueCastleDocumentTypes } from "@/utils/ged";

const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");
const documentConst = utils.documentsConstants;
const { blobToDataURL, dataURLtoBlob, blobToFile } = utils.bim.bim;
const { buildDocumentRelationsRequest } = useDocumentRelationBuilder();
const formatDocData = (obj) => {
  return {
    uuid: obj.uuid,
    servicesRelatedName: obj.servicesRelatedNameList,
    strongRelations: obj.strongRelationsList,
    tags: obj.tagsList,
    name: obj.name,
    docType: obj.docType?.length ? obj.docType : null,
    source: obj.source,
    ref: obj.ref,
    version: obj.version,
    datetime: obj.datetime,
    datetimeFormat: obj.datetimeFormat,
    isFromLegacySoftware: obj.isFromLegacySoftware,
    isActive: obj.isActive,
    oldOriginId: obj.oldOriginId,
    origin: obj.origin,
    originId: obj.originId?.toString(),
    gedProjectId: obj.gedProjectId.toString(),
  };
};

const formatDocGRPCData = (doc) => ({
  ...doc.toObject(),
  metaDatas: doc.getMetaDatas().toJavaScript(),
  isImported: !doc.getServicesRelatedNameList()?.includes("amos"),
});

const stub = socioGrpcClient.document.document;
const client = stub.DocumentCustomControllerPromiseClient;

const state = {
  documentSelected: [],
  documentTags: [],
  documentsCount: 0,
  documentTablePageSize: 10,
  documentType: [],
  documentImageTypes: [],
  documentUrls: {},
};

const getters = {
  getDocuments: (state) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .withAll()
      .limit(state.documentTablePageSize)
      .get();
  },
  getVisitDocuments: (state) => {
    return state.visitDocuments;
  },
  getDocumentsByType: () => (type) => {
    return Document.query()
      .orderBy("createdAt", "desc")
      .where("docType", type)
      .get();
  },
  getDocumentTags: (state) => {
    return state.documentTags;
  },
  getDocumentCount: (state) => {
    return state.documentsCount;
  },
  getDocumentTablePageSize: (state) => {
    return state.documentTablePageSize;
  },
  getDocumentTypes() {
    return DocumentType.query().orderBy("name").get();
  },
  getPicassoDocumentTypes() {
    return DocumentType.query()
      .where((docType) => picassoDocumentTypes.includes(docType.name))
      .orderBy("name")
      .get();
  },
  getBlueCastleDocumentTypes() {
    return DocumentType.query()
      .where((docType) => blueCastleDocumentTypes.includes(docType.name))
      .orderBy("name")
      .get();
  },
  getDocumentImageTypes() {
    return DocumentImageType.query().orderBy("label").get();
  },
};

const actions = {
  /**
   * @param {Object}
   * @param {{
   * gedProjectId: string;
   * originIds: string[]
   * }} param
   * @returns {Promise<{[string]:string}>} URLs of all originIds in the specified GED as a {[originId]: url} object
   */
  async getDocumentUrls({ state, dispatch }, { gedProjectId, originIds }) {
    originIds = originIds.map((x) => x.toString());
    gedProjectId = gedProjectId.toString();
    const targetOriginIdsSet = new Set(originIds);
    const storedIds = new Set(
      Object.keys(state.documentUrls[gedProjectId] || {})
    );
    // We fetch the missing urls
    await dispatch("listDocumentUrlsByOriginIds", {
      gedProjectId,
      originIds: [...targetOriginIdsSet].filter((id) => !storedIds.has(id)),
    });

    const urls = {};
    originIds.forEach((id) => {
      urls[id] = state.documentUrls[gedProjectId][id];
    });
    return urls;
  },

  simpleFetchDocuments: requestFactory.actions.listFactory({
    client,
    ModelClass: Document,
    grpcListRequest: stub.DocumentCustomListRequest,
    grpcListMethod: client.documentCustomExtendList,
    transformResponseData: (results) => results.map(formatDocGRPCData),
  }),

  /**
   * Fetch document list
   * @context [commit]
   * @param metadata {Object}
   * @param createData {Boolean} gives the possibility to just return the response array or to create it in the store
   * @param relationIds {Array} Allows you to filter documents, retrieving only those with a relationship to these ids.
   * @param elementType {String} Allows you to filter documents by retrieving only those with a relationship to a given model
   * @returns {Promise<void> || Array}
   */
  async fetchDocuments(
    { commit, dispatch },
    {
      filters,
      pagination,
      createData = true,
      asObject = true,
      relatedItemIds,
      hasRelationTo = null,
      projectUuid,
      relatedItemsInCache = false,
    }
  ) {
    // INFO - SAC - 14/05/2024 - if relatedItemsInCache is true, we will fetch the related items from the cache user perimeter
    if (relatedItemsInCache) {
      const data = await db.value.user_perimeter
        .find()
        .where("projectId")
        .eq(projectUuid)
        .exec();
      const aosItemUuids =
        data
          ?.map((doc) => doc.toJSON())
          ?.flatMap((userPerimeter) => userPerimeter.aosItemUuids) || [];

      relatedItemIds = aosItemUuids;
    }

    const documentIds = await dispatch(
      "relations/findEntitiesByTargetIds",
      {
        appServiceId: "document",
        entityName: "document",
        targetIds: relatedItemIds,
        targetEntityName: hasRelationTo,
      },
      { root: true }
    );
    if (documentIds.length === 0) {
      commit("UPDATE_DOCUMENT_COUNT", 0);
      return [];
    }

    filters.uuids = documentIds;
    const request = setRequestFilters({
      request: new stub.DocumentCustomListRequest(),
      filters,
    });

    const metadata = {
      pagination: JSON.stringify(pagination),
    };
    const response = await client.documentCustomExtendList(request, metadata);
    const resultsList = response.getResultsList();
    const formattedResultsList = resultsList.map(formatDocGRPCData);

    if (createData) {
      const docIds = formattedResultsList.map((d) => d.uuid);
      if (docIds.length > 0) {
        await dispatch(
          "relations/fetchEntityRelations",
          {
            filters: {
              app_service_id: "document",
              app_entity_name: "document",
              app_entity_id__in: docIds.join(","),
            },
            populateORM: true,
          },
          { root: true }
        );
      }

      commit("UPDATE_DOCUMENT_COUNT", response.getCount());
      await Document.create({
        data: formattedResultsList,
      });
      const documentsCreated = Document.query()
        .with("relations")
        .findIn(docIds);

      await injectPerimeterRepresentation(documentsCreated, Document);
    }

    return asObject ? formattedResultsList : response;
  },

  async listDocumentUrlsByOriginIds({ commit }, { originIds, gedProjectId }) {
    const request = new stub.DocumentListUrlsByOriginIdsRequest();

    request.setOriginIdsList(originIds);
    request.setGedProjectId(gedProjectId.toString());

    const response =
      await stub.DocumentControllerPromiseClient.listUrlsByOriginIds(
        request,
        {}
      );
    const urlsList = response.toObject().urlsList;
    const documentUrls = {};

    urlsList.forEach((url, idx) => {
      documentUrls[originIds[idx]] = url;
    });

    commit("INSERT_DOCUMENT_URLS", { gedProjectId, documentUrls });
  },

  async retrieveDocument(context, uuid) {
    const request = new stub.DocumentCustomRetrieveRequest();
    request.setUuid(uuid);
    const response = await client.documentCustomExtendRetrieve(request, {});

    const document = await Document.insertOrUpdate({
      data: formatDocGRPCData(response),
    });
    return document.documents[0];
  },

  async fetchDocumentTypes() {
    const request = new stub.DocumentTypeListRequest();
    const response = await stub.DocumentTypeControllerPromiseClient.list(
      request,
      {}
    );

    await DocumentType.create({ data: response.toObject().resultsList });
  },

  async retrieveDocumentType({ commit }, documentTypeUuid) {
    const request = new stub.DocumentTypeRetrieveRequest();
    request.setUuid(documentTypeUuid);

    const response = await stub.DocumentTypeControllerPromiseClient.retrieve(
      request,
      {}
    );

    const docType = response.toObject();

    commit("SET_DOCUMENT_TYPE", docType);
    await DocumentType.insert({ data: docType });

    return docType;
  },

  async fetchDocumentTags({ commit }, params) {
    const request = new stub.TagListRequest();
    try {
      const response = await stub.TagControllerPromiseClient.list(
        request,
        params.metadata
      );
      commit("SET_TAGS", response.toObject().resultsList);
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },

  async fetchDocumentImageTypes() {
    const request =
      new socioGrpcClient.aos_back.aos_admin.DocumentTypesListRequest();
    const response =
      await socioGrpcClient.aos_back.aos_admin.DocumentTypesControllerPromiseClient.utils.listAllObjects(
        request,
        {}
      );

    await DocumentImageType.insertOrUpdate({ data: response });
  },

  async storeDocument(_, { data, relatedItems }) {
    try {
      if (!data?.file) return;
      const blob = await blobToDataURL(data.file);
      const photoData = {
        uuid: uuidv4(),
        data: { ...data, file: blob },
        relatedItems,
        offlineCreated: true,
      };
      const { documents } = (await Document.insert({ data: photoData })) || {};
      return documents?.[0];
    } catch (error) {
      console.error("Error reading file:", error);
    }
  },

  async syncOfflineDocuments({ dispatch }) {
    try {
      const {
        data: { form, file },
        relatedItems,
        uuid,
      } = Document.query().where("offlineCreated", true).first() || {
        data: {},
        relatedItems: {},
      };
      const blob = dataURLtoBlob(file);
      const blobFile = blobToFile(blob, form.name);
      const payload = {
        data: {
          form,
          file: blobFile,
        },
        relatedItems,
      };
      const doc = await dispatch(`document/createDocument`, payload);
      if (doc) await Document.delete(uuid);
    } catch (error) {
      console.error("Error while syncing offline documents", error);
    }
  },

  async createDocument({ commit, getters, dispatch }, { data, relatedItems }) {
    let response;
    if (
      data.form.origin === documentConst.DocumentSource.BIMDATA_GED &&
      data.file !== null
    ) {
      response = await dispatch("createBimdataDocument", data);
    } else if (!data.documentUuid) {
      response = await dispatch("createManualDocument", data);
    }

    await dispatch("createAmosDocument", {
      document_data: response,
    });

    if (relatedItems) {
      await dispatch("insertOrUpdateRelatedItems", {
        docId: response.uuid,
        relatedItems,
      });
    }
    commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount + 1);
    return response;
  },

  async createAmosDocument(_, data) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.amos_back.document.AmosDocumentRequest,
      {}
    );

    request.setDocumentUuid(data.document_data.uuid);

    try {
      await socioGrpcClient.amos_back.document.AmosDocumentControllerPromiseClient.create(
        request,
        {}
      );
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },

  /**
   * Create a manual document
   * @param context
   * @param data
   * @returns {Promise<{documents: Document[]}>}
   */
  async createManualDocument(context, data) {
    data.form.servicesRelatedNameList = [process.env.VUE_APP_AMOS_SERVICE_ID];
    const docData = formatDocData(data.form);
    const request = socioGrpcClient.javascriptToRequest(
      stub.DocumentFlatRequest,
      docData
    );

    const metaDatas = new google_protobuf_struct_pb.Struct.fromJavaScript(
      data.form.metaDatas
    );
    request.setMetaDatas(metaDatas);

    try {
      const response = await client.createWithDocument(request, {});
      const doc = await Document.insert({ data: formatDocGRPCData(response) });
      return doc.documents[0];
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },

  async setModelDocumentUrlAndName(
    { dispatch },
    { ModelClass, itemUuid, docCustomUuid }
  ) {
    const { fileUrl, fileName } = await dispatch(
      "getDocumentUrlAndName",
      docCustomUuid
    );
    await ModelClass.update({
      where: itemUuid,
      data: {
        favoritePictureFileUrl: fileUrl,
        favoritePictureFileName: fileName,
      },
    });
  },

  async retrieveDocumentCustomUrl(_, documentUuid) {
    const request = new stub.DocumentCustomRetrieveRequest();
    request.setUuid(documentUuid);
    const response =
      await stub.DocumentCustomControllerPromiseClient.retrieveDocumentCustomUrl(
        request,
        {}
      );

    return response.toObject();
  },

  async getDocumentUrlAndName({ dispatch }, docCustomUuid) {
    try {
      const [{ documentUrl }, docData] = await Promise.all([
        dispatch("retrieveDocumentCustomUrl", docCustomUuid),
        dispatch("retrieveDocument", docCustomUuid),
      ]);
      return {
        documentUuid: docCustomUuid,
        fileUrl: documentUrl,
        fileName: docData.name,
      };
    } catch (error) {
      console.error(error);
      if (error.response?.status === 400) {
        const document = await dispatch("retrieveDocument", docCustomUuid);
        return {
          documentUuid: docCustomUuid,
          fileUrl: "",
          fileName: document.name,
        };
      }
      return {};
    }
  },

  async insertOrUpdateRelatedItems({ dispatch }, { docId, relatedItems }) {
    const { aosItems, typologyTypes, objectTypes, imageTypeId } = relatedItems;
    const relationRequests = buildDocumentRelationsRequest({
      documentId: docId,
      aosItems,
      typologyTypes,
      objectTypes,
      imageTypeId,
    });
    await Relation.delete((rel) => {
      return rel.appEntityId === docId;
    });

    await dispatch(
      "relations/bulkCreateOrUpdateRelations",
      {
        relations: relationRequests,
        clearExisting: true,
      },
      { root: true }
    );

    // G.P 14/01/2023 When aosItem is not a building,
    // i need a special relation model to be able to retrieve them for other app
    // without interfering with amos relations
    const { buildExternalDocumentRelation } = useDocumentLinking();

    const aosExternalRelations = await buildExternalDocumentRelation(
      aosItems,
      docId
    );
    if (aosExternalRelations.length) {
      await dispatch(
        "relations/bulkCreateOrUpdateRelations",
        {
          relations: aosExternalRelations,
          clearExisting: true,
        },
        { root: true }
      );
    }

    await dispatch(
      "relations/fetchEntityRelations",
      {
        filters: {
          app_service_id: "document",
          app_entity_name: "document",
          app_entity_id__in: [docId].join(","),
        },
        populateORM: true,
      },
      { root: true }
    );
    const updatedDocuments = await Document.query()
      .with("relations")
      .findIn([docId]);

    injectPerimeterRepresentation(updatedDocuments, Document);
    return updatedDocuments[0];
  },

  async updateDocument({ dispatch }, { data, relatedItems }) {
    if (
      data.form.origin === documentConst.DocumentSource.BIMDATA_GED &&
      data.file
    ) {
      // INFO - MS - 22/07/2021 - Update a single doc with file on Bimdata ged
      return await dispatch("updateDocumentOnBimdata", data);
    }
    // INFO - MS - 22/07/2021 - Update (without file) a single doc or multiple docs
    const docData = formatDocData(data.form);
    const request = socioGrpcClient.javascriptToRequest(
      stub.DocumentFlatRequest,
      docData
    );

    if (data.form.metaDatas) {
      const metaDatas = new google_protobuf_struct_pb.Struct.fromJavaScript(
        data.form.metaDatas
      );
      request.setMetaDatas(metaDatas);
    }

    try {
      const response = await client.updateDocumentCustomExtend(request, {});
      const docData = formatDocGRPCData(response);
      const docs = (await Document.insertOrUpdate({ data: docData })).documents;
      if (relatedItems) {
        await dispatch("insertOrUpdateRelatedItems", {
          docId: docs[0].uuid,
          relatedItems,
        });
      }
      return docs[0];
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },

  async updateDocumentOnBimdata(context, data) {
    const docFile = new FormData();
    docFile.append("file", data.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    data.form.servicesRelatedNameList = [process.env.VUE_APP_AMOS_SERVICE_ID];
    Object.entries(data.form).forEach(([key, value]) => {
      if (key === "metaDatas") {
        value = JSON.stringify(value);
      }
      docFile.append(key, value);
    });
    try {
      const bimDataDoc = await restDocumentClient.uploadBimDocument(
        docFile,
        "put"
      );
      let result = utils.grpc.snakeToCamel(bimDataDoc.data, "flatten");
      const keysMap = {
        servicesRelatedName: "servicesRelatedNameList",
        tags: "tagsList",
      };
      result = utils.grpc.renameKeys(keysMap, result);
      const doc = await Document.update({
        data: {
          ...result,
          metaDatas: bimDataDoc.data.document.meta_datas,
          isImported: false,
        },
      });
      return doc.documents[0];
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },

  async createBimdataDocument(context, data) {
    // INFO - MS - 22/07/2021 - REST call on microservice Document that call the REST api of Bimdata
    const docFile = new FormData();
    docFile.append("file", data.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    data.form.servicesRelatedNameList = [process.env.VUE_APP_AMOS_SERVICE_ID];

    Object.entries(data.form).forEach(([key, value]) => {
      if (!value) {
        return;
      }
      if (key === "metaDatas") {
        value = JSON.stringify(value);
      }
      docFile.append(key, value);
    });
    try {
      const bimDataDoc = await restDocumentClient.uploadBimDocument(
        docFile,
        "post"
      );
      let result = utils.grpc.snakeToCamel(bimDataDoc.data, "flatten");
      const keysMap = {
        servicesRelatedName: "servicesRelatedNameList",
        tags: "tagsList",
      };
      result = utils.grpc.renameKeys(keysMap, result);
      const doc = await Document.insert({
        data: {
          ...result,
          metaDatas: bimDataDoc.data.document.meta_datas,
          isImported: false,
        },
      });
      return doc.documents[0];
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  },
  async updateMultipleDocs(_, { data, documentIds }) {
    const request = new stub.DocumentCustomBulkUpdateDocumentsRequest();

    for (const documentId of documentIds) {
      const document = new stub.DocumentFlatRequest();

      if (data.metaDatas) {
        const metaDatas = new google_protobuf_struct_pb.Struct.fromJavaScript(
          data.metaDatas
        );
        document.setMetaDatas(metaDatas);
      }

      document.setUuid(documentId);
      document.setStrongRelationsList(data.strongRelations);
      document.setDatetime(data.datetime);
      document.setSource(data.source);
      document.setTagsList(data.tagsList || []);
      document.setDocStatus(data.docStatus);
      document.setDocType(data.docType);
      request.addDocuments(document);
    }

    const response = await client.bulkUpdateDocuments(request, {});

    const resultsList = response.toObject().resultsList;
    await Document.update({ data: resultsList });
  },
  /**
   * Delete a document
   * @param [context]
   * @param documentUuid Document uuid
   * @returns {object} { code, message}
   */
  async removeDocument({ commit, getters, dispatch }, documentUuid) {
    await dispatch(
      "relations/bulkRemoveRelations",
      {
        appServiceId: "document",
        entityName: "document",
        entityId: documentUuid,
      },
      { root: true }
    );

    // remove any external document link made on building childrens
    // this is used by socio-aos, external document feature to identify from non amos apps document candidates to import from amos.
    await dispatch(
      "relations/bulkRemoveRelations",
      {
        appServiceId: "document",
        entityName: "export-document",
        entityId: documentUuid,
      },
      { root: true }
    );

    const request = new stub.DocumentDestroyRequest();

    request.setUuid(documentUuid);

    const response = await client.destroy(request, {});
    const result = response.toObject();

    if (result.code === "SUCCESS") {
      commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount - 1);
      return await Document.delete(documentUuid);
    }

    throw new Error(result.message);
  },
  async insertImportedDocument({ state, dispatch, commit }, documentUuidList) {
    const filters = {
      uuid: documentUuidList.join(","),
    };
    const request = new stub.DocumentCustomListRequest();
    const response = await client.documentCustomExtendList(request, {
      filters: JSON.stringify(filters),
    });
    const resultsList = response.getResultsList();
    const formattedResultsList = resultsList.map(formatDocGRPCData);

    const docIds = formattedResultsList.map((d) => d.uuid);
    await dispatch(
      "relations/fetchEntityRelations",
      {
        filters: {
          app_service_id: "document",
          app_entity_name: "document",
          app_entity_id__in: docIds.join(","),
        },
        populateORM: true,
      },
      { root: true }
    );
    commit("UPDATE_DOCUMENT_COUNT", state.documentsCount + response.getCount());
    await Document.insert({
      data: formattedResultsList,
    });
    const documentsCreated = Document.query().with("relations").findIn(docIds);

    await injectPerimeterRepresentation(documentsCreated, Document);
  },

  async retrieveDocumentByOriginId(_, originId) {
    const request = new stub.DocumentCustomListRequest();
    const response = await client.documentCustomExtendList(request, {
      filters: JSON.stringify({ origin_id: originId }),
    });

    return response
      .getResultsList()
      .map((doc) => {
        return {
          ...doc.toObject(),
          metaDatas: doc.getMetaDatas().toJavaScript(),
        };
      })
      .find((d) => d.originId === originId);
  },

  exportAmosDocuments: requestFactory.actions.exportFactory(
    socioGrpcClient.amos_back.document.AmosDocumentControllerPromiseClient,
    socioGrpcClient.amos_back.document.ExportRequest,
    "amosDocument"
  ),
};

const mutations = {
  SET_TAGS: (state, tags) => {
    state.documentTags = tags.map((tag) => tag.name);
  },
  SET_DOCUMENT_TYPE: (state, docType) => {
    state.documentType = docType;
  },
  RESET_TAGS: (state) => {
    state.documentTags = [];
  },
  UPDATE_DOCUMENT_COUNT: (state, newTotal) => {
    state.documentsCount = newTotal;
  },
  RESET_DOCUMENTS: (state) => {
    Document.deleteAll();
    state.documentsCount = 0;
  },
  UPDATE_DOCUMENT_TABLE_PAGE_SIZE: (state, pageSize) => {
    state.documentTablePageSize = pageSize;
  },
  INSERT_DOCUMENT_URLS: (state, { gedProjectId, documentUrls }) => {
    if (!state.documentUrls[gedProjectId])
      state.documentUrls[gedProjectId] = {};
    Object.entries(documentUrls).map(([id, url]) => {
      state.documentUrls[gedProjectId][id] = url;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
