import {
  Action,
  Observation,
  CtNodeObservation,
  Disposition,
  DocumentReview,
  ObservationStatement,
  ObjectTypeStatement,
} from "@/models";

import socioGrpcClient from "@/setup/socioGrpcClient";
import { PerimeterFactory } from "@/utils/aos";
import {
  buildActionRequest,
  buildStatementRequest,
  buildMetadataRequest,
  observationOriginEnum as ORIGINS,
  checkStatementUniqueConstraint,
  picassoStatementFilterFn,
  getMinQuotedStatement,
  buildBIMDataPinRequest,
  PICASSO_STATUS_ENUM,
} from "@/utils/observation";
import { forIn, values } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { insertNestedPeriodicity } from "@/utils/periodicity";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

export const actions = {
  initDraftdisposition(
    { state, commit, dispatch },
    { analyticalAxis, perimeter, mode = "MULTI", origin, referencialNodeUuid }
  ) {
    dispatch("initDraftObservation", {
      analyticalAxis,
      perimeter,
      mode,
      origin,
    });
    const { aosObservablesList } = state.observation;
    commit("INIT_DRAFT_DISPOSITION", {
      referencialNodeUuid,
      aosObservablesList,
    });
  },

  initDraftdocumentReview(
    { state, commit, dispatch },
    { analyticalAxis, perimeter, mode = "MULTI", origin, referencialNodeUuid }
  ) {
    dispatch("initDraftObservation", {
      analyticalAxis,
      perimeter,
      mode,
      origin,
    });
    const { aosObservablesList } = state.observation;
    commit("INIT_DRAFT_DOCREVIEW", { referencialNodeUuid, aosObservablesList });
  },

  initDraftctNodeObservation(
    { state, commit, dispatch },
    { analyticalAxis, perimeter, mode = "MULTI", origin, referencialNodeUuid }
  ) {
    dispatch("initDraftObservation", {
      analyticalAxis,
      perimeter,
      mode,
      origin,
    });
    const { aosObservablesList } = state.observation;
    commit("INIT_DRAFT_CT_NODE_OBSERVATION", {
      referencialNodeUuid,
      aosObservablesList,
    });
  },

  initDraftObservation(
    { commit, rootGetters, dispatch },
    {
      analyticalAxis,
      perimeter,
      mode = "SINGLE",
      origin,
      metadata = {},
      amosDocumentsList,
      functionalRequirementsList,
      ctComment,
      refCode,
    }
  ) {
    commit("INIT_DRAFT_OBSERVATION", {
      amosDocumentsList,
      functionalRequirementsList,
      ctComment,
      refCode,
      analyticalAxis,
      origin,
      metadata,
      user: rootGetters["user/getCurrentUser"],
    });
    commit(`SET_${mode}_PERIMETER`, perimeter);
    dispatch("setInheritedActions");
  },

  async setDraftObservationFromInstance(
    { state, commit, rootGetters },
    observationUuid
  ) {
    commit("SET_DRAFT_OBSERVATION_FROM_INSTANCE", observationUuid);

    const instance = Observation.find(observationUuid);

    if (
      !rootGetters["user/getCurrentUser"].isCT() &&
      instance.metadata?.picassoStatus == PICASSO_STATUS_ENUM.OLD
    ) {
      instance.metadata.picassoStatus = PICASSO_STATUS_ENUM.OLD_UPDATED;
      instance.$save();
    }

    if (!rootGetters["user/getCurrentUser"].isCT()) return;
    // ct workflow
    if (instance.ctObservation) {
      state.draftCtObservationUuid = instance.ctObservation.uuid;
      return;
    }

    state.draftCtObservationUuid = uuidv4();
  },

  setDraftdispositionFromInstance({ commit, dispatch }, dispositionId) {
    const disposition = Disposition.find(dispositionId);
    commit("SET_DRAFT_DISPOSITION_FROM_INSTANCE", dispositionId);
    dispatch("setDraftObservationFromInstance", disposition.observation);
  },

  setDraftctNodeObservationFromInstance({ commit, dispatch }, ctObsId) {
    const ctObs = CtNodeObservation.find(ctObsId);
    commit("SET_DRAFT_CT_NODE_OBSERVATION_FROM_INSTANCE", ctObsId);
    dispatch("setDraftObservationFromInstance", ctObs.observation);
  },

  setDraftdocumentReviewFromInstance({ commit, dispatch }, docreviewId) {
    const docReview = DocumentReview.find(docreviewId);
    commit("SET_DRAFT_DOCREVIEW_FROM_INSTANCE", docreviewId);
    dispatch("setDraftObservationFromInstance", docReview.observation);
  },

  reset(
    { commit },
    { resetStatementData = false, resetObservationData = false }
  ) {
    commit("RESET_DRAFT_STATEMENTS", resetStatementData);
    commit("RESET_DRAFT_ACTIONS");
    commit("RESET_DRAFT_DISPOSITION");
    commit("RESET_DRAFT_DOCREVIEW");
    commit("RESET_DRAFT_OBSERVATION", resetObservationData);
    commit("RESET_INHERITED_ACTIONS");
    commit("RESET_CACHED_ITEMS");
    commit("RESET_DRAFT_PICASSO_CT_OBSERVATION");
  },

  createOrUpdateDraftStatements(
    { commit, dispatch, state, rootGetters },
    statements
  ) {
    const user = rootGetters["user/getCurrentUser"];
    dispatch("handleStatementDeletions", { user, statements });
    // create or update statements
    statements.forEach((statementData) => {
      const { objectTypeStatement: ots, actionTypesList } = statementData;
      if (statementData.uuid) commit("UPDATE_DRAFT_STATEMENT", statementData);
      // in amos database we have a unique-together constraint on observation and objectTypeStatement
      if (checkStatementUniqueConstraint(state.observation.uuid, ots)) return;
      commit("CREATE_DRAFT_STATEMENT", { user, ...statementData });
      dispatch("handleSuggestedOrInheritedActions", actionTypesList);
    });

    dispatch("updateSmallestQuotationValue");
  },

  handleStatementDeletions({ state, rootGetters }, { user, statements }) {
    // delete statements that are not in the incoming list
    const incomingStatementsOts = statements.map((s) => s.objectTypeStatement);
    forIn(state.statements, (statement, uuid) => {
      if (!incomingStatementsOts.includes(statement.objectTypeStatement)) {
        // if the statement is not in the incoming list, delete it (in memory only)
        delete state.statements[uuid];
        // if the user is a CT, hard delete the statement
        if (user.isCT() && rootGetters["project/getIsPicassoVisit"]) {
          state.statementIdsToDestroy.push(uuid);
        }
      }
    });
  },

  handleSuggestedOrInheritedActions(
    { dispatch, state, rootGetters },
    actionRefs
  ) {
    if (!rootGetters["projectConfig/getCurrentProjectConfig"].hasAction) return;
    if (!actionRefs.length) return;

    actionRefs.forEach((actionRef) => {
      const existing = values(state.inheritedActions).map((a) => a.actionRef);
      if (!existing.includes(actionRef)) {
        dispatch("createOrUpdateDraftAction", {
          observationsList: [state.observation.uuid],
          actionRef,
          isSuggested: true,
          status: "TODO",
        });
      }
    });
  },

  removeInheritedAction({ commit }, actionUuid) {
    commit("POP_INHERITED_ACTION", actionUuid);
  },

  removeDraftAction({ commit }, actionUuid) {
    commit("POP_DRAFT_ACTION", actionUuid);
  },

  async createOrUpdateDraftAction(
    { commit },
    { isSuggested = false, ...actionData }
  ) {
    if (actionData.uuid) {
      const instance = await Action.update({
        where: actionData.uuid,
        data: { ...actionData, isSuggested },
      });
      commit("UPDATE_DRAFT_ACTION", instance);
      await insertNestedPeriodicity(instance);
      return;
    }
    await insertNestedPeriodicity([{ ...actionData, isSuggested }]);
    commit("CREATE_DRAFT_ACTION", { ...actionData, isSuggested });
  },
  undraftWorkInProgress({ state }) {
    if (!state.observation) return;
    state.observation.isDraft = false;
    state.observation.$save();

    ObservationStatement.delete(
      (s) =>
        s.observation === state.observation.uuid &&
        !(s.uuid in state.statements)
    );
    ObservationStatement.update({
      where: (s) => {
        return (
          s.uuid in state.statements ||
          s.observation === state.draftCtObservationUuid
        );
      },
      data: { isDraft: false },
    });
    Action.update({
      where: (a) => a.observationsList.includes(state.observation.uuid),
      data: { isDraft: false },
    });
    Disposition.update({
      where: (d) => d.observation === state.observation.uuid,
      data: { isDraft: false },
    });
    DocumentReview.update({
      where: (d) => d.observation === state.observation.uuid,
      data: { isDraft: false },
    });
    CtNodeObservation.update({
      where: (d) => d.observation === state.observation.uuid,
      data: { isDraft: false },
    });
  },

  async save({ dispatch }) {
    await dispatch("undraftWorkInProgress");
    await dispatch("saveToAmos");
  },

  async saveToAmos({ state, dispatch, rootGetters }) {
    const currentUser = rootGetters["user/getCurrentUser"];
    const {
      uuid,
      analyticalAxis,
      aosObservablesList,
      riskAssessment,
      amosDocumentsList,
      dispositionTypesList,
      functionalRequirementsList,
      origin,
      ctComment,
      refCode,
      metadata,
      isValidated: isValidatedByCt,
    } = state.observation;

    const picassoCtReview =
      currentUser.isCT() && rootGetters["project/getIsPicassoVisit"];

    // if the Technical Controller is reviewing the observation, it should be marked as validated
    // even if he didnt click on the validate button. Validate button is used when he doesn't have any modification to do
    const isObservationValidated = isValidatedByCt || picassoCtReview;
    const statementsData = values(state.statements).filter((statement) => {
      // if the user is a CT, we only want to send statements that have not already been added by CT
      // this will need to be updated when offline mode is implemented
      if (currentUser.isCT()) {
        return !values(state._cached_statements)
          .map((st) => st.objectTypeStatement)
          .includes(statement.objectTypeStatement);
      }
      return true;
    });

    const statementReq = statementsData.map(buildStatementRequest);

    const actionsReq = values({
      ...state.actions,
      ...state.inheritedActions,
    }).map(buildActionRequest);
    const observationReq = socioGrpcClient.javascriptToRequest(
      observationApi.ObservationRequest,
      {
        uuid: state.draftCtObservationUuid ?? uuid,
        analyticalAxis,
        riskAssessment,
        amosDocumentsList,
        origin,
        ctComment,
        refCode,
        dispositionTypesList,
        functionalRequirementsList,
        isValidated: isObservationValidated,
      }
    );

    // Y.V: TODO: Need to update this condition
    if (metadata?.picassoStatus) {
      const metadataReq = buildMetadataRequest(metadata);
      observationReq.setMetadata(metadataReq);
    }

    let request = new observationApi.ObservationStatementAssignInputRequest();

    const draftPin = rootGetters["pin/getDraftPin"];
    if (draftPin) {
      const pinReq = buildBIMDataPinRequest(draftPin, state.observation.uuid);
      request.setBimdataPin(pinReq);
    }

    request.setAosObservablesList(aosObservablesList);
    request.setObservation(observationReq);
    request.setActionsList(actionsReq);
    request.setObservationStatementsList(statementReq);
    request.setUserIsCt(currentUser.isCT());
    request = await dispatch("handleNodeRelatedRequest", request);
    const response =
      await observationApi.ObservationStatementControllerPromiseClient.assign(
        request,
        {}
      );
    const {
      observation,
      actionsList,
      disposition,
      documentReview,
      ctNodeObservation,
    } = response.toObject();
    if (picassoCtReview) {
      await dispatch("handlePicassoCtReview", {
        uuid,
        response,
        isObservationValidated,
        analyticalAxis,
        refCode,
        origin,
        ctComment,
        functionalRequirementsList,
        amosDocumentsList,
      });
    } else {
      Observation.update({
        where: uuid,
        data: observation,
      });
      await insertNestedPeriodicity(actionsList);
      Action.insertOrUpdate({
        data: actionsList,
      });
    }
    if (disposition) await Disposition.insertOrUpdate({ data: disposition });
    if (documentReview)
      await DocumentReview.insertOrUpdate({ data: documentReview });
    if (ctNodeObservation)
      await CtNodeObservation.insertOrUpdate({ data: ctNodeObservation });
    await dispatch(
      "amosAos/updatePerimeterForCurrentUser",
      { observableIds: aosObservablesList },
      {
        root: true,
      }
    );
  },

  async handlePicassoCtReview(
    { state, dispatch },
    {
      uuid,
      response,
      isObservationValidated,
      analyticalAxis,
      refCode,
      origin,
      ctComment,
      functionalRequirementsList,
      amosDocumentsList,
    }
  ) {
    await dispatch(
      "observation/partialUpdateObservation",
      {
        uuid,
        ctObservation: response.toObject().observation.uuid,
        isValidated: isObservationValidated,
        analyticalAxis,
        refCode,
        origin,
        ctComment,
        functionalRequirementsList,
        amosDocumentsList,
      },
      { root: true }
    );

    dispatch(
      "observationStatement/destroyObservationStatements",
      state.statementIdsToDestroy,
      { root: true }
    );

    const instance = Observation.find(uuid);
    instance.ctObservation = response.toObject().observation;
    instance.isValidated = isObservationValidated;
    instance.$save();
    state.statementIdsToDestroy = [];
  },

  handleNodeRelatedRequest({ state }, request) {
    if (state.disposition && state.observation.origin === ORIGINS.DISPOSITION) {
      const dispositionReq = socioGrpcClient.javascriptToRequest(
        observationApi.DispositionRequest,
        {
          uuid: state.disposition.uuid,
          observation: state.observation.uuid,
          referencialNodeUuid: state.disposition.referencialNodeUuid,
        }
      );
      request.setDisposition(dispositionReq);
    }

    if (
      state.ctNodeObservation &&
      state.observation.origin === ORIGINS.RAPSOTEC_CT
    ) {
      const ctNodeObsReq = socioGrpcClient.javascriptToRequest(
        observationApi.CtNodeObservationRequest,
        {
          uuid: state.ctNodeObservation.uuid,
          observation: state.observation.uuid,
          referencialNodeUuid: state.ctNodeObservation.referencialNodeUuid,
        }
      );
      request.setCtNodeObservation(ctNodeObsReq);
    }

    if (
      state.documentReview &&
      state.observation.origin === ORIGINS.DOCUMENT_REVIEW
    ) {
      const documentReviewReq = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.amos_back.observation.DocumentReviewRequest,
        {
          uuid: state.documentReview.uuid,
          observation: state.observation.uuid,
          referencialNodeUuid: state.documentReview.referencialNodeUuid,
        }
      );
      request.setDocumentReview(documentReviewReq);
    }
    return request;
  },

  partialUpdateDraftObservation({ commit }, property) {
    const [key, value] = Object.entries(property)[0] || [];
    if (key) {
      commit("PARTIAL_UPDATE_DRAFT_OBSERVATION", { [key]: value });
    }
  },

  updateDispositions({ state, rootGetters, dispatch }, dispositionTypesList) {
    if (!state.observation) return;
    state.observation.dispositionTypesList = dispositionTypesList;
    state.observation.$save();

    if (!rootGetters["project/getIsPicassoVisit"]) return;
    const statements = ObjectTypeStatement.query()
      .withAll()
      .where((objectTypeStatement) => {
        return picassoStatementFilterFn({
          objectTypeStatement,
          observationUuid: state.observation.uuid,
          objectIds: dispositionTypesList,
          analyticalAxis: state.observation.analyticalAxis,
        });
      })
      .get()
      .map((item) => ({
        objectTypeStatement: item.uuid,
        statementType: item.statementType,
        statementTypeData: item.statementTypeData,
        quotationValueData: item.quotationValueData,
        quotationValue: item.quotationValue,
        justification: "",
        documentsList: [],
        actionTypesList: [],
      }));
    dispatch("createOrUpdateDraftStatements", statements);
  },

  updateSmallestQuotationValue({ state, commit }) {
    if (!state.observation) return;
    const smallestQuotationValue = getMinQuotedStatement({
      observationUuids: [state.observation.uuid],
      filterClause: (s) => s.uuid in state.statements,
    });
    commit("PARTIAL_UPDATE_DRAFT_OBSERVATION", {
      smallestQuotationValue,
    });
  },

  validateCtObservation({ state, commit, rootGetters }) {
    if (!state.observation) return;
    const currentUser = rootGetters["user/getCurrentUser"];
    const isValidated = true;
    const modifiedBy = currentUser.uuid;
    const modifiedByFirstname = currentUser.firstName;
    const modifiedByLastname = currentUser.lastName;
    commit("PARTIAL_UPDATE_DRAFT_OBSERVATION", {
      isValidated,
      modifiedBy,
      modifiedByFirstname,
      modifiedByLastname,
    });
  },

  updatePerimeter({ commit, dispatch }, perimeter) {
    const factory = new PerimeterFactory(perimeter);
    const aosObservablesList = factory.toAmosMulti().getUuids();
    commit("PARTIAL_UPDATE_DRAFT_OBSERVATION", { aosObservablesList });

    // if perimeter is updated, we need to update inherited actions to match current selction of aos items
    dispatch("setInheritedActions", true);
  },

  setInheritedActions(
    { getters, rootGetters, state, commit },
    resetCurrent = false
  ) {
    if (!getters.isInCreateMode) return;
    if (!rootGetters["projectConfig/getCurrentProjectConfig"].hasAction) return;
    if (resetCurrent) {
      commit("RESET_INHERITED_ACTIONS");
    }
    const observables = state.observation?.aosObservablesList;
    if (!observables) return;
    const actions = rootGetters["action/getInheritableActions"](observables);
    commit("SET_INHERITED_ACTIONS", actions);
  },
};
