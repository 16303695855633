import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  ObservationStatement,
  Observation,
  ObservationHistory,
  QuotationValue,
  Quotation,
  Action,
} from "@/models/";
import requestFactory from "../factory";
import { setRequestFilters } from "@/utils/request";
import {
  insertNestedActions,
  insertNestedDispositions,
  insertNestedDocumentReviews,
  insertNestedObservationStatements,
  handleNestedDocuments,
  insertNestedCtNodeObservations,
} from "@/utils/observation";
import {
  ANALYTICAL_AXES_TO_DISPOSITION_PATHES as DISPOSITIONS,
  DIAG_MISSION_CODES_TO_ANALYTICAL_AXES as DIAG_AXES,
  ANALYTICAL_AXES_TO_RAPSOTEC_CT_NODE_PATHES as SECTIONS,
} from "@/utils/const";
import AnalyticalAxis, {
  ANALYTICAL_AXIS_CODES as AXES,
} from "@/models/AnalyticalAxis";
import { keys, uniqBy, values } from "lodash";
import SyncOrAsyncQuery from "@/utils/vuexOrmAsyncQuery";
import db from "@/rxdb/utils";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const state = {
  loading: true,
  dispositionLoading: true,
  documentReviewLoading: true,
  ctNodeObservationLoading: true,
  activeObservationPerimeter: null,
  documentIdsToUrls: {},
};

const getters = {
  getObservations: (state, getters, rootState, rootGetters) => {
    return Observation.query()
      .orderBy("createdAt", "desc")
      .where((obs) => {
        return rootGetters["project/getProjectAxesIdsWithDocReview"].includes(
          obs.analyticalAxis
        );
      })
      .withAllRecursive(2)
      .get();
  },
  getRelatedObservations: () => (observableUuids) => {
    return Observation.query()
      .where((obs) => {
        return obs.aosObservablesList.some((aosObservable) => {
          return observableUuids.includes(aosObservable);
        });
      })
      .get();
  },
  getActiveObservationPerimeter: (state) => {
    return state.activeObservationPerimeter;
  },
  getInfoPerimeterTitleText: () => (observationIds) => {
    if (observationIds.length === 1) {
      const observation = Observation.find(observationIds[0]);
      const perimeterItems = observation?.makePerimeter()?.items;
      if (perimeterItems?.length < 2) {
        return perimeterItems[0]?.items[0]?.designation ?? "";
      }
      return perimeterItems?.length ? `Multi - ${perimeterItems.length}` : "";
    }

    const observations = Observation.findIn(observationIds);
    const distinctPerimeterItems = uniqBy(
      observations.flatMap((obs) => obs.makePerimeter()?.items),
      (item) => item.uuid
    );

    if (distinctPerimeterItems.length === 1) {
      return distinctPerimeterItems[0]?.items[0]?.designation ?? "";
    }
    return distinctPerimeterItems?.length
      ? `Multi - ${distinctPerimeterItems.length}`
      : "";
  },
  getLastObservationId: (state, getters) => getters.getObservations[0]?.uuid,
};

const actions = {
  async fetchObservations(
    _,
    { filters = {}, pagination = {}, projectUuid, relatedItemsInCache }
  ) {
    // INFO - SAC - 14/05/2024 - if relatedItemsInCache is true, we will fetch the related items from the cache user perimeter
    if (relatedItemsInCache) {
      const data = await db.value.user_perimeter
        .find()
        .where("projectId")
        .eq(projectUuid)
        .exec();
      const aosItemUuids =
        data
          ?.map((doc) => doc.toJSON())
          ?.flatMap((userPerimeter) => userPerimeter.aosItemUuids) || [];

      filters["aos_item_uuid_in"] = aosItemUuids;
    }
    const metadata = {
      pagination: JSON.stringify(pagination),
    };

    const request = setRequestFilters({
      request: new observationApi.ObservationListRequest(),
      filters,
    });

    const response =
      await observationApi.ObservationControllerPromiseClient.list(
        request,
        metadata
      );

    const items = response.getResultsList().map((observation) => {
      return {
        ...observation.toObject(),
      };
    });
    await Observation.insertOrUpdate({ data: items });
    return items;
  },
  async fetchVisitObservations({ commit, rootGetters }, projectUuid) {
    const request = new observationApi.VisitObservationListRequest();
    request.setProjectUuid(projectUuid);

    const response =
      await observationApi.ObservationControllerPromiseClient.listVisitObservations(
        request,
        {}
      );

    const { resultsList } = response.toObject();

    await Observation.insert({ data: resultsList });

    await SyncOrAsyncQuery.handleQuery(QuotationValue.query(), "all");
    await SyncOrAsyncQuery.handleQuery(Quotation.query(), "all");
    const analyticalAxes = await SyncOrAsyncQuery.handleQuery(
      AnalyticalAxis.query(),
      "findIn",
      rootGetters["project/getProjectConfigAnalyticalAxes"].map(
        (pcA) => pcA.analyticalAxis
      )
    );

    const axesCodes = analyticalAxes.map(({ code }) => code);
    const docReviewInProject = axesCodes.includes(AXES.DOC_REVIEW);
    const ctAxesInProject = axesCodes.some((c) =>
      values(DIAG_AXES).includes(c)
    );
    const dispositionsInProject = axesCodes.some((c) =>
      keys(DISPOSITIONS).includes(c)
    );
    commit("SET_LOADING", false);
    setTimeout(async () => {
      if (dispositionsInProject) {
        const pathes = axesCodes
          .filter((code) => code in DISPOSITIONS)
          .map((code) => DISPOSITIONS[code]);
        await insertNestedDispositions(resultsList, pathes);
        commit("SET_DISPOSITION_LOADING", false);
      }

      if (docReviewInProject) {
        await insertNestedDocumentReviews(resultsList);
        commit("SET_DOC_REVIEW_LOADING", false);
      }

      if (ctAxesInProject) {
        const pathes = axesCodes
          .filter((code) => code in SECTIONS)
          .map((code) => SECTIONS[code]);
        await insertNestedCtNodeObservations(resultsList, pathes);
        commit("SET_CT_NODE_OBSERVATION_LOADING", false);
      }

      await insertNestedObservationStatements(resultsList);
      await insertNestedActions(resultsList);
      await handleNestedDocuments(resultsList);
    }, 0.1);
  },

  partialUpdateObservation: requestFactory.actions.partialUpdateFactory(
    Observation,
    observationApi.ObservationPartialUpdateRequest,
    observationApi.ObservationControllerPromiseClient,
    ["documents"]
  ),

  async deleteObservation({ dispatch }, observationUuid) {
    await Observation.delete(observationUuid);
    dispatch("doDeleteObservation", observationUuid);
    const ctObservation =
      Observation.find(observationUuid)?.ctObservation?.uuid;
    if (ctObservation) {
      dispatch("deleteObservation", ctObservation);
      await Observation.delete(ctObservation);
    }
  },

  specificPartialUpdateFactory:
    requestFactory.actions.specificPartialUpdateFactory(
      Observation,
      observationApi.ObservationPartialUpdateRequest,
      observationApi.ObservationControllerPromiseClient
    ),

  async archiveObservation({ dispatch }, uuid) {
    await dispatch("specificPartialUpdateFactory", {
      uuid: uuid,
      isArchived: true,
    });

    const ctObservation = Observation.find(uuid)?.ctObservation?.uuid;
    if (ctObservation) {
      dispatch("archiveObservation", ctObservation);
      await Observation.delete(ctObservation);
    }

    await Observation.delete(uuid);
  },

  async doDeleteObservation(_, observationUuid) {
    const request = new observationApi.ObservationDestroyRequest();
    request.setUuid(observationUuid);
    await observationApi.ObservationControllerPromiseClient.destroy(
      request,
      {}
    );
  },

  async setObservationRisk(_, { observationUuid, riskLevel }) {
    const request = new observationApi.ObservationSetRiskLevelRequest();
    request.setUuid(observationUuid);
    request.setRiskAssessment(riskLevel);

    const response =
      await observationApi.ObservationControllerPromiseClient.setRiskLevel(
        request,
        {}
      );

    const { riskAssessment } = response.toObject();
    await Observation.update({
      where: (record) => record.uuid === observationUuid,
      data: { riskAssessment },
    });
  },

  async retrieveObservationHistory(_, observationUuid) {
    const request = new observationApi.ObservationRetrieveHistoryRequest();
    request.setUuid(observationUuid);

    try {
      const response =
        await observationApi.ObservationControllerPromiseClient.retrieveHistory(
          request,
          {}
        );
      const items = response.toObject();

      const results = items.resultsList.map((item) => {
        return {
          historyDate: item.historyDate,
          reportDate: item.reportDate,
          report: item.report,
          documentUuid: item.documentUuid,
          reportName: item.reportName,
          observation: item.uuid,
          modifiedBy: item.modifiedBy,
        };
      });

      await ObservationHistory.create({ data: results });
    } catch (error) {
      return;
    }
  },

  async resetState() {
    Observation.deleteAll(true);
    ObservationStatement.deleteAll();
    Action.deleteAll();
  },

  exportObservations: requestFactory.actions.exportFactory(
    observationApi.ObservationControllerPromiseClient,
    observationApi.ExportRequest,
    "observation"
  ),
};

const mutations = {
  SET_LOADING: (state, value) => (state.loading = value),
  SET_DISPOSITION_LOADING: (state, value) => (state.dispositionLoading = value),
  SET_DOC_REVIEW_LOADING: (state, value) =>
    (state.documentReviewLoading = value),
  SET_CT_NODE_OBSERVATION_LOADING: (state, value) =>
    (state.ctNodeObservationLoading = value),
  SET_ACTIVE_OBSERVATION_PERIMETER: (state, value) =>
    (state.activeObservationPerimeter = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
