import { utils } from "@socotec.io/socio-aos-component";
import {
  SiteAsset,
  BuildingAsset,
  StoreyAsset,
  ZoneAsset,
  SpaceAsset,
  SubcomponentAsset,
  ComponentAsset,
} from "@/models";
import { ANALYTICAL_AXIS_CODES } from "@/models/AnalyticalAxis";

export const ASSET_ITEMS = {
  site: {
    ...utils.aosConst.AOS_ITEMS.site,
    modelClass: () => SiteAsset,
    identityCard: "SiteIdentityCard",
  },

  building: {
    ...utils.aosConst.AOS_ITEMS.building,
    modelClass: () => BuildingAsset,
    identityCard: "SiteIdentityCard",
  },

  storey: {
    ...utils.aosConst.AOS_ITEMS.storey,
    modelClass: () => StoreyAsset,
    identityCard: "StoreyIdentityCard",
  },

  zone: {
    ...utils.aosConst.AOS_ITEMS.zone,
    modelClass: () => ZoneAsset,
    identityCard: "",
  },

  space: {
    ...utils.aosConst.AOS_ITEMS.space,
    modelClass: () => SpaceAsset,
    identityCard: "",
  },

  component: {
    ...utils.aosConst.AOS_ITEMS.component,
    modelClass: () => ComponentAsset,
    identityCard: "ComponentIdentityCard",
  },

  subcomponent: {
    ...utils.aosConst.AOS_ITEMS.subcomponent,
    modelClass: () => SubcomponentAsset,
    identityCard: "ComponentIdentityCard",
  },
};

export const EXPENSE_TYPE = {
  CAPEX: "CAPEX",
  OPEX: "OPEX",
};

export const bimReferencials = [
  "IFC 2x3",
  "IFC4",
  "Uniformat II",
  "FIDJI",
  "ICMS/IPMS",
  "UNTEC",
  "Omnicalss",
  "Uniclass",
];

export const ROLES = {
  PILOT: "PILOT",
  PROJECT_MANAGER: "PROJECT_MANAGER",
  TECHNICAL_CONTROLLER: "TECHNICAL_CONTROLLER",
};

export const reportTemplateFileTypeEnum = {
  PDF: "PDF",
  EXCEL: "EXCEL",
};

export const reportLanguageChoiceEnum = {
  EN: "EN",
  FR: "FR",
};

export const countries = {
  France: "France",
};

export const RAPSOTEC_CT_REFERENCIAL_PARAMS = {
  versionName: "CURRENT",
  versionNumber: 1,
  applicationName: "CT_RAPSOTEC_FRANCE",
  referencialType: "NATIONAL",
  referencialName: "DEFAULT",
};

export const RAPSOTEC_CT_PATHES = {
  FIRE_SAFETY: "20",
  ACCESSIBILITY: "33",
};

export const DISPOSITION_PATHES = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  18: "18",
  19: "19",
  20: "20",
  21: "21",
};

export const ANALYTICAL_AXES_TO_DISPOSITION_PATHES = {
  // each analytical axis is linked to a disposition path
  // for example, a node with a path like so: "1.1.1.1" will be linked to the disposition path "1"
  [ANALYTICAL_AXIS_CODES.DILAPIDATION]: DISPOSITION_PATHES[1],
  [ANALYTICAL_AXIS_CODES.ENERGY]: DISPOSITION_PATHES[2],
  [ANALYTICAL_AXIS_CODES.FIRE_SAFETY]: DISPOSITION_PATHES[3],
  [ANALYTICAL_AXIS_CODES.ACCESSIBILITY]: DISPOSITION_PATHES[4],
  // no more disposition pathes between 5 and 17 for now (07/11/2023)
  [ANALYTICAL_AXIS_CODES.CONSERVATION]: DISPOSITION_PATHES[18],
  [ANALYTICAL_AXIS_CODES.RUSTING]: DISPOSITION_PATHES[19],
  [ANALYTICAL_AXIS_CODES.TECHNICAL_ADVICE]: DISPOSITION_PATHES[20],
  [ANALYTICAL_AXIS_CODES.FUNCTIONAL_REQUIREMENTS]: DISPOSITION_PATHES[21],
};

export const ANALYTICAL_AXES_TO_RAPSOTEC_CT_NODE_PATHES = {
  [ANALYTICAL_AXIS_CODES.AH_DIAG]: RAPSOTEC_CT_PATHES.ACCESSIBILITY,
  [ANALYTICAL_AXIS_CODES.SI_DIAG]: RAPSOTEC_CT_PATHES.FIRE_SAFETY,
};

export const DISPOSITION_PATHES_TO_ANALYTICAL_AXES = {
  // inversely, each disposition path is linked to an analytical axis
  [DISPOSITION_PATHES[1]]: ANALYTICAL_AXIS_CODES.DILAPIDATION,
  [DISPOSITION_PATHES[2]]: ANALYTICAL_AXIS_CODES.ENERGY,
  [DISPOSITION_PATHES[3]]: ANALYTICAL_AXIS_CODES.FIRE_SAFETY,
  [DISPOSITION_PATHES[4]]: ANALYTICAL_AXIS_CODES.ACCESSIBILITY,
  // no more disposition pathes between 5 and 17 for now (04/13/2024)
  [DISPOSITION_PATHES[18]]: ANALYTICAL_AXIS_CODES.CONSERVATION,
  [DISPOSITION_PATHES[19]]: ANALYTICAL_AXIS_CODES.RUSTING,
  [DISPOSITION_PATHES[20]]: ANALYTICAL_AXIS_CODES.TECHNICAL_ADVICE,
  [DISPOSITION_PATHES[21]]: ANALYTICAL_AXIS_CODES.FUNCTIONAL_REQUIREMENTS,
};

export const DIAG_MISSION_CODES_TO_ANALYTICAL_AXES = {
  "HZAA-1": ANALYTICAL_AXIS_CODES.AH_DIAG,
  "HBAG-1": ANALYTICAL_AXIS_CODES.SI_DIAG,
};

export const RAPSOTEC_CT_PATHES_TO_AXES_CODES = {
  [RAPSOTEC_CT_PATHES.FIRE_SAFETY]: ANALYTICAL_AXIS_CODES.SI_DIAG,
  [RAPSOTEC_CT_PATHES.ACCESSIBILITY]: ANALYTICAL_AXIS_CODES.AH_DIAG,
};

export const assetTypesByRoute = {
  "document-review": ["site"],
  dispositions: ["site", "building"],
};

export const missionCodesStructureMap = {
  building: ["JFAL", "JFAB", "JFAF"],
  infrastructure: ["HIBA", "HAAD", "JFAD"],
};

export const riskGroupsEnum = {
  SEVERITY: "Gravité",
  INVESTOR: "Risque investisseur",
};

export const riskGroupByMissionCodeMap = {
  // INFRA Mission Codes
  HAAD: riskGroupsEnum.SEVERITY,
  HIBA: riskGroupsEnum.SEVERITY,
  JFAD: riskGroupsEnum.SEVERITY,

  // DIAG Mission Codes
  HBAG: riskGroupsEnum.INVESTOR,
  HZAA: riskGroupsEnum.INVESTOR,

  // REAL_ESTATE_QI Mission Codes
  JFAB: riskGroupsEnum.INVESTOR,
  JFAF: riskGroupsEnum.INVESTOR,
  JFAL: riskGroupsEnum.INVESTOR,

  // PICASSO Mission Code (WIP)
  HAIA: riskGroupsEnum.INVESTOR,
};

export const PICASSO_ANALYTICAL_AXES = ["FUNCTIONAL_REQUIREMENTS"];

export const VIEWER_PLUGINS = {
  GED: "GedPlugin",
  ZOOM_IN: "ZoomInPlugin",
  ZOOM_OUT: "ZoomOutPlugin",
  EXPORT_FILE: "ExportFilePlugin",
};

export const APP_NAMES = {
  PICASSO: "PICASSO",
  AMOS_INFRA: "AMOS_INFRA",
  AMOS_REAL_ESTATE: "AMOS",
  AMOS_DIAG: "RAPSODIAG",
  BLUE_TRUST_IMMOBILIER: "BLUETRUST IMMOBILIER",
};

export const MISSION_CODES_TO_APP = {
  HAIA: APP_NAMES.PICASSO,
  HAAD: APP_NAMES.AMOS_INFRA,
  HIBA: APP_NAMES.AMOS_INFRA,
  JFAD: APP_NAMES.AMOS_INFRA,
  HBAG: APP_NAMES.AMOS_DIAG,
  HZAA: APP_NAMES.AMOS_DIAG,
  JFAB: APP_NAMES.AMOS_REAL_ESTATE,
  JFAF: APP_NAMES.AMOS_REAL_ESTATE,
  JFAL: APP_NAMES.AMOS_REAL_ESTATE,
  LQAD: APP_NAMES.BLUE_TRUST_IMMOBILIER,
};

export const DOMAIN_TO_APP_NAME = {
  amos: APP_NAMES.AMOS_REAL_ESTATE,
  bluetrustimmobilier: APP_NAMES.BLUE_TRUST_IMMOBILIER,
  // picasso: APP_NAMES.PICASSO,
  // etc.
};

export const TASK_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  CANCELED: "CANCELED",
  TIMEOUT: "TIMEOUT",
};

export const MISSION_CODES = {
  HAIA: "HAIA",
  HAAD: "HAAD",
  HIBA: "HIBA",
  JFAD: "JFAD",
  HBAG: "HBAG",
  HZAA: "HZAA",
  JFAB: "JFAB",
  JFAF: "JFAF",
  JFAL: "JFAL",
  LQAD: "LQAD",
};

export const ANALYTICAL_AXIS_GROUPS = {
  DISPOSITION: [
    ANALYTICAL_AXIS_CODES.DOC_REVIEW,
    ANALYTICAL_AXIS_CODES.FIRE_SAFETY,
    ANALYTICAL_AXIS_CODES.ACCESSIBILITY,
    ANALYTICAL_AXIS_CODES.DILAPIDATION,
    ANALYTICAL_AXIS_CODES.ENERGY,
    ANALYTICAL_AXIS_CODES.TAXONOMY_CONSTRUCTION,
    ANALYTICAL_AXIS_CODES.TAXONOMY_EXPLOITATION,
    ANALYTICAL_AXIS_CODES.TAXONOMY_RENOVATION,
    ANALYTICAL_AXIS_CODES.ADAPTABILITY,
    ANALYTICAL_AXIS_CODES.FLEXIBILITY,
    ANALYTICAL_AXIS_CODES.DURABILITY,
    ANALYTICAL_AXIS_CODES.RECYCLE,
    ANALYTICAL_AXIS_CODES.LOW_CARBONE,
    ANALYTICAL_AXIS_CODES.WIRED_CONSTRUCTION,
    ANALYTICAL_AXIS_CODES.WIRED_EXPLOITATION,
    ANALYTICAL_AXIS_CODES.IRVE,
    ANALYTICAL_AXIS_CODES.TECHNICAL_ADVICE,
    ANALYTICAL_AXIS_CODES.FUNCTIONAL_REQUIREMENTS,
  ],
  TECHNICAL_INSPECTION: [
    ANALYTICAL_AXIS_CODES.AH_DIAG,
    ANALYTICAL_AXIS_CODES.SI_DIAG,
  ],
};
