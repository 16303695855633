import { ObservationStatement } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { setRequestFilters } from "@/utils/request";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const state = {};

const getters = {};

const actions = {
  async fetchObservationStatements(_, observationIds) {
    if (!observationIds.length) return;
    const request = new observationApi.ObservationStatementListRequest();
    setRequestFilters({
      request,
      filters: { observation__in: observationIds },
    });
    const response =
      await observationApi.ObservationStatementControllerPromiseClient.flatList(
        request,
        {}
      );

    const { resultsList } = response.toObject();
    await ObservationStatement.insert({ data: resultsList });
  },

  async destroyObservationStatements(_, observationStatementIds) {
    const destroyPromises = [];
    observationStatementIds.forEach((id) => {
      const request = new observationApi.ObservationStatementDestroyRequest();
      request.setUuid(id);
      destroyPromises.push(
        observationApi.ObservationStatementControllerPromiseClient.destroy(
          request,
          {}
        )
      );
    });

    await ObservationStatement.delete((os) =>
      observationStatementIds.includes(os.uuid)
    );

    await Promise.all(destroyPromises);
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
