import { remove } from "lodash";

export const projectConfigGetter = "projectConfig/getCurrentProjectConfig";

export const getUuid = (item) => item.uuid;

export const initInheritance = (action, observationUuid) => {
  if (!action.observationsList.includes(observationUuid)) {
    action.observationsList.push(observationUuid);
  }
  // need to differentiaite suggested actions (created by related statements)
  // and inherited actions (proposed by similar observation)
  action.isSuggested = false;
  action.isInherited = true;
  action.$save();
  return action;
};

export const undoInheritance = (action, observationUuid) => {
  if (action.observationsList.includes(observationUuid)) {
    remove(action.observationsList, (uuid) => uuid === observationUuid);
  }
  action.isInherited = false;
  action.$save();
  return action;
};
